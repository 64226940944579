import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { InputComponent } from "components/CustomInput/InputList";
import { getActionDefines, getActionCatalogOptions } from "contract_define";

const i18n = {
  en: {
    title: "Modify Step ",
    action: "Action",
    value: "Parameter Value",
    cancel: "Cancel",
    confirm: "Confirm",
  },
  cn: {
    title: "修改步骤 ",
    action: "动作",
    value: "参数值",
    cancel: "取消",
    confirm: "确定",
  },
};

export default function ModifyStepDialog(props) {
  const { lang, open, index, current, onSuccess, onCancel } = props;
  const { action, params } = current;
  const defaultValues = {
    action: action,
    params: params,
  };
  const [data, setData] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [operatable, setOperatable] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const actionDefines = getActionDefines(lang);
  const texts = i18n[lang];
  const title = texts.title + (index + 1).toString();
  const onOperateFail = (msg) => {
    if (!mounted) {
      return;
    }

    setOperatable(true);
    setPrompt(msg);
  };

  const resetDialog = () => {
    setPrompt("");
  };

  const closeDialog = () => {
    resetDialog();
    onCancel();
  };

  const onOperateSuccess = () => {
    if (!mounted) {
      return;
    }
    setOperatable(true);
    resetDialog();
    onSuccess(index, data);
  };

  const handleConfirm = () => {
    setOperatable(false);
    //check parameters
    if (data.params && 0 !== data.params.length) {
      if (
        !data.params.every((value, index) => {
          if (!value || 0 === value.length) {
            onOperateFail("param " + index.toString() + " is empty");
            return false;
          }
          return true;
        })
      ) {
        return;
      }
    }
    onOperateSuccess();
  };

  const handleActionChanged = (e) => {
    if (!mounted) {
      return;
    }
    let value = e.target.value;
    if (value !== data.action) {
      if (!Object.prototype.hasOwnProperty.call(actionDefines, value)) {
        onOperateFail("undefined action: " + value);
        return;
      }
      let paramCount = 0;
      if (actionDefines[value].parameters) {
        paramCount = actionDefines[value].parameters.length;
      }
      let params = new Array(paramCount);
      params.fill("");
      setData({
        action: value,
        params: params,
      });
    }
  };

  const handleParameterChanged = (index) => {
    return (e) => {
      if (!mounted) {
        return;
      }

      let value = e.target.value;
      let params = data.params;
      if (index >= params.length) {
        onOperateFail("parameter index overflow: " + index.toString());
        return;
      }
      params[index] = value;
      setData((previous) => ({
        ...previous,
        params: params,
      }));
    };
  };

  React.useEffect(() => {
    if (!open || !current || !current.action) {
      return;
    }
    if (!Object.prototype.hasOwnProperty.call(actionDefines, data.action)) {
      onOperateFail("undefined action: " + data.action);
      return;
    }
    setMounted(true);
    setInitialized(true);
    return () => {
      setMounted(false);
    };
  }, [current, open, data]);

  //begin render
  let content;
  let buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: closeDialog,
    },
  ];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let inputs = [
      React.createElement(InputComponent, {
        type: "select",
        label: texts.action,
        onChange: handleActionChanged,
        value: data.action,
        options: getActionCatalogOptions(lang),
        oneRow: true,
        xs: 4,
        key: "action",
      }),
    ];
    const define = actionDefines[data.action];
    if (define.helper) {
      let tooltip = define.helper;
      if (define.parameters && 0 !== define.parameters.length) {
        define.parameters.forEach(({ label }, index) => {
          if (data.params && data.params[index] && "" !== data.params[index]) {
            tooltip = tooltip.replaceAll(
              "{" + index.toString() + "}",
              data.params[index]
            );
          } else {
            tooltip = tooltip.replaceAll(
              "{" + index.toString() + "}",
              "{" + label + "}"
            );
          }
        });
      }
      inputs.push(
        <Box m={2} key="tooltip" xs={8}>
          <Typography variant="body1">{tooltip}</Typography>
        </Box>
      );
    }
    if (define.parameters) {
      define.parameters.forEach(({ label, tooltip }, index) => {
        inputs.push(
          React.createElement(InputComponent, {
            key: index,
            type: "text",
            label: label,
            onChange: handleParameterChanged(index),
            value: data.params[index],
            oneRow: true,
            xs: 6,
            helper: tooltip,
          })
        );
      });
    }
    content = <Grid container>{inputs}</Grid>;
    buttons.push({
      color: "info",
      label: texts.confirm,
      onClick: handleConfirm,
    });
  }

  return (
    <CustomDialog
      size="md"
      promptPosition="top"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={operatable}
      disableEnforceFocus
    />
  );
}

ModifyStepDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  current: PropTypes.shape({
    action: PropTypes.string.isRequired,
    params: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
