import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { getAccessToken } from "backend_api";

const i18n = {
  en: {
    title: "Access Token Detail",
    version: "Format Version",
    id: "Access ID",
    name: "Access Name",
    notBefore: "Valid After",
    notAfter: "Valid Before",
    issuer: "Issuer",
    publicKey: "Public Key",
    signature: "Digital Signature",
    close: "Close",
  },
  cn: {
    title: "令牌详情",
    version: "格式版本",
    id: "令牌ID",
    name: "访问名称",
    notBefore: "生效时间",
    notAfter: "失效时间",
    issuer: "签发者",
    publicKey: "令牌公钥",
    signature: "数字签名",
    close: "关闭",
  },
};

export default function ViewTokenDialog(props) {
  const { lang, open, appID, accessID, onCancel } = props;
  const texts = i18n[lang];
  const title = texts.title;
  const [data, setData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [prompt, setPrompt] = React.useState("");

  const closeDialog = () => {
    if (!mounted) {
      return;
    }
    setPrompt("");
    onCancel();
  };

  const onOperateFail = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      setPrompt(msg);
    },
    [mounted]
  );

  React.useEffect(() => {
    if (!accessID) {
      return;
    }
    setMounted(true);
    const onLoadDataSuccess = (data) => {
      setData(data);
      setInitialized(true);
    };

    getAccessToken(appID, accessID, onLoadDataSuccess, onOperateFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [appID, accessID, onOperateFail]);

  //rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    const {
      version,
      id,
      name,
      validity_period_not_before,
      validity_period_not_after,
      public_key,
      public_key_algorithm,
      issuer,
      signature_algorithm,
      signature,
    } = data;

    const keyChip = (
      <Tooltip
        title={public_key_algorithm + ": " + public_key}
        key="public_key"
      >
        <Chip label={public_key_algorithm} color="info" />
      </Tooltip>
    );
    const signatureChip = (
      <Tooltip title={signature_algorithm + ": " + signature} key="signature">
        <Chip label={signature_algorithm} color="success" />
      </Tooltip>
    );
    let tableData = [
      {
        title: texts.version,
        value: version,
      },
      {
        title: texts.id,
        value: id,
      },
      {
        title: texts.name,
        value: name,
      },
      {
        title: texts.notBefore,
        value: new Date(validity_period_not_before).toLocaleString(),
      },
      {
        title: texts.notAfter,
        value: new Date(validity_period_not_after).toLocaleString(),
      },
      {
        title: texts.issuer,
        value: issuer,
      },
      {
        title: texts.publicKey,
        value: keyChip,
      },
      {
        title: texts.signature,
        value: signatureChip,
      },
    ];
    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }

  const buttons = [
    {
      color: "transparent",
      label: texts.close,
      onClick: closeDialog,
    },
  ];

  return (
    <CustomDialog
      size="md"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={true}
    />
  );
}

ViewTokenDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  appID: PropTypes.string.isRequired,
  accessID: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
