import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import { getSchema } from "backend_api.js";
import { propertyTypeName } from "utils.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Properties of Schema: ",
    back: "Back",
    name: "Schema Name",
    propertyName: "Property Name",
    propertyType: "Value Type",
    propertyIndexed: "Is Indexed",
    tagIndexed: "Indexed",
    operates: "Operates",
    editButton: "Enter Modify",
    submitButton: "Submit",
    addButton: "Add Property",
    removeButton: "Remove Property",
    moveUpButton: "Move Up",
    moveDownButton: "Move Down",
  },
  cn: {
    title: "数据类别属性列表： ",
    back: "返回",
    name: "类别名称",
    propertyName: "属性名",
    propertyType: "属性值类型",
    propertyIndexed: "是否索引",
    tagIndexed: "已索引",
    operates: "操作",
    editButton: "进入编辑模式",
    submitButton: "提交变更",
    addButton: "添加属性",
    removeButton: "删除属性",
    moveUpButton: "上移",
    moveDownButton: "下移",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function SchemaDetail(props) {
  const schemaName = props.match.params.schema;
  const [detailData, setDetailData] = React.useState(null);

  const showErrorMessage = React.useCallback((msg) => {
    toast.error(msg);
  }, []);

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const reloadDetail = React.useCallback(() => {
    const onGetDetailSuccess = (data) => {
      setDetailData(data.properties);
    };
    getSchema(
      defaultChainName,
      defaultDomainName,
      schemaName,
      onGetDetailSuccess,
      onFail
    );
  }, [onFail]);

  React.useEffect(() => {
    reloadDetail();
  }, [reloadDetail]);

  const dataToNodes = (data /*, buttons*/) => {
    const { name, type, indexed } = data;
    let valueTag = propertyTypeName(lang, type);
    return [name, valueTag, indexed ? texts.tagIndexed : ""];
  };

  const { lang } = props;
  const texts = i18n[lang];
  let content, title;
  let buttons = [];
  if (null === detailData) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let rows = [];
    detailData.forEach((property) => {
      const buttons = [
        // {
        //   icon: VisibilityIcon,
        //   label: texts.detail,
        //   href: "/admin/assets/" + name,
        // },
        // {
        //   icon: LibraryBooksIcon,
        //   label: texts.docs,
        //   href: "/admin/assets/" + name + "/docs/",
        // },
        // {
        //   icon: HistoryIcon,
        //   label: texts.history,
        //   href: "/admin/assets/" + name + "/logs/",
        // },
        // {
        //   onClick: () => showRemoveDialog(name),
        //   icon: DeleteIcon,
        //   label: texts.remove,
        // },
      ];
      rows.push(dataToNodes(property, buttons));
    });
    content = (
      <Table
        color="primary"
        headers={[
          texts.propertyName,
          texts.propertyType,
          texts.propertyIndexed,
        ]}
        rows={rows}
      />
    );

    title = texts.title + schemaName;
    buttons = [
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={() => {
          const listURL = "/admin/assets/";
          window.location.assign(listURL);
          // props.history.goBack();
        }}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

SchemaDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
