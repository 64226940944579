import React from "react";
import PropTypes from "prop-types";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { deleteGroup } from "backend_api";

const i18n = {
  en: {
    title: "Remove Group",
    content: "Are you sure to remove group ",
    cancel: "Cancel",
    confirm: "Confirm",
  },
  cn: {
    title: "删除用户组",
    content: "是否删除用户组 ",
    cancel: "取消",
    confirm: "确定",
  },
};

export default function DeleteGroupDialog(props) {
  const { lang, open, groupName, onSuccess, onCancel } = props;
  const [operatable, setOperatable] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const texts = i18n[lang];
  const title = texts.title;
  const onRemoveFail = (msg) => {
    setOperatable(true);
    setPrompt(msg);
  };

  const closeDialog = () => {
    setPrompt("");
    onCancel();
  };

  const onRemoveSuccess = () => {
    setOperatable(true);
    setPrompt("");
    onSuccess(groupName);
  };

  const handleRemove = () => {
    setOperatable(false);
    deleteGroup(groupName, onRemoveSuccess, onRemoveFail);
  };

  const content = texts.content + groupName;
  const buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: closeDialog,
    },
    {
      color: "info",
      label: texts.confirm,
      onClick: handleRemove,
    },
  ];

  return (
    <CustomDialog
      size="xs"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={operatable}
    />
  );
}

DeleteGroupDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
