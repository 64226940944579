import React from "react";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "components/Backdrop/Backdrop";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Alert from "@mui/material/Alert";

export default function CustomDialog(props) {
  const {
    open,
    size,
    operatable,
    promptPosition,
    prompt,
    title,
    content,
    buttons,
    hideBackdrop,
    disableEnforceFocus,
  } = props;
  let promptElement;
  if (prompt) {
    promptElement = (
      <GridItem xs={12}>
        <Alert variant="filled" severity="warning">
          {prompt}
        </Alert>
      </GridItem>
    );
  } else {
    promptElement = <GridItem xs={12} />;
  }
  let contentElement;
  if ("top" === promptPosition) {
    contentElement = (
      <Grid container>
        {promptElement}
        <GridItem xs={12}>{content}</GridItem>
      </Grid>
    );
  } else {
    contentElement = (
      <Grid container>
        <GridItem xs={12}>{content}</GridItem>
        {promptElement}
      </Grid>
    );
  }
  let operates = [];
  if (operatable) {
    buttons.forEach((button, key) => {
      operates.push(
        <Button onClick={button.onClick} color={button.color} key={key}>
          {button.label}
        </Button>
      );
    });
  }
  return (
    <Dialog open={open} maxWidth={size} fullWidth disableEnforceFocus={disableEnforceFocus}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Backdrop open={!hideBackdrop && !operatable}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {contentElement}
      </DialogContent>
      <DialogActions>
        <Box display="block" displayPrint="none">
          {operates}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  operatable: PropTypes.bool,
  promptPosition: PropTypes.string,
  prompt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  hideBackdrop: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
};
