import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import Applications from "views/Applications/Applications";
import ApplicationDetail from "views/Applications/ApplicationDetail";
import ModifyApplication from "views/Applications/ModifyApplication";
import CreateApplication from "views/Applications/CreateApplication";
import TokenList from "views/Applications/TokenList";

export default function ApplicationPanel(props) {
  const DEFAULT_MODE = "list";
  const MODE_ADD = "add";
  const MODE_VIEW = "view";
  const MODE_MODIFY = "modify";
  const MODE_TOKENS = "tokens";
  const [mode, setMode] = React.useState(DEFAULT_MODE);
  const [target, setTarget] = React.useState("");

  const handleAdd = () => {
    setMode(MODE_ADD);
  };


  const handleListTokens = (appID) => {
    setTarget(appID);
    setMode(MODE_TOKENS);
  };

  const handleModify = (appID) => {
    setTarget(appID);
    setMode(MODE_MODIFY);
  };

  const handleView = (appID) => {
    setTarget(appID);
    setMode(MODE_VIEW);
  };

  const list = () => {
    setMode(DEFAULT_MODE);
  };

  let panel;
  switch (mode) {
    case MODE_ADD:
      panel = <CreateApplication onList={list} {...props} />;
      break;
    case MODE_TOKENS:
      panel = <TokenList onList={list} appID={target} {...props} />;
      break;
    case MODE_VIEW:
      panel = <ApplicationDetail onList={list} appID={target} {...props} />;
      break;
    case MODE_MODIFY:
      panel = <ModifyApplication onList={list} appID={target} {...props} />;
      break;
    default:
      panel = (
        <Applications
          onAdd={handleAdd}
          onModify={handleModify}
          onView={handleView}
          onTokens={handleListTokens}
          {...props}
        />
      );
  }
  return (
    <Box>
      {panel}
      <ToastContainer autoClose={3500} draggable={false} />
    </Box>
  )
}

ApplicationPanel.prototype = {
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
