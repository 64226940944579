import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import Table from "components/Table/ObjectTable.js";
import DeleteTokenDialog from "views/Applications/DeleteTokenDialog.js";
import ViewTokenDialog from "views/Applications/ViewTokenDialog.js";
import AddTokenDialog from "views/Applications/AddTokenDialog";
import { queryAccessTokens, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionReadApplication,
  RoleActionReadIdentity,
  RoleActionDeleteIdentity,
  RoleActionCreateIdentity,
 } from "privileges";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    createButton: "Create New Token",
    renewButton: "Renew Token",
    id: "ID",
    name: "Name",
    app: "Application",
    notBefore: "Valid After",
    notAfter: "Valid Before",
    issuer: "Issuer",
    noRecord: "No access token available",
    operates: "Operates",
    detail: "Detail",
    remove: "Delete",
  },
  cn: {
    back: "返回",
    createButton: "创建新令牌",
    renewButton: "更新令牌",
    id: "令牌ID",
    name: "令牌名称",
    app: "所属应用",
    notBefore: "生效时间",
    notAfter: "失效时间",
    issuer: "签发者",
    noRecord: "暂无应用令牌",
    operates: "操作",
    detail: "详情",
    remove: "删除",
  },
};

export default function TokenList(props) {
  const { lang, appID, onList } = props;
  const recordPerPage = 10;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  //for dialog
  const [addDialogVisible, setAddDialogVisible] = React.useState(false);
  const [viewDialogVisible, setViewDialogVisible] = React.useState(false);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showViewDialog = (accessID) => {
    setCurrentItem(accessID);
    setViewDialogVisible(true);
  };

  const closeViewDialog = () => {
    setViewDialogVisible(false);
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { tokens, offset, total } = result;
      setDataList(tokens);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryAccessTokens(appID, offset, recordPerPage, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, currentPage, mounted]);

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.success(msg);
  };

  //remove
  const showRemoveDialog = (accessID) => {
    setCurrentItem(accessID);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (accessID) => {
    closeRemoveDialog();
    showNotifyMessage("token " + accessID + " deleted");
    writeLog("deleted access token " + accessID);
    reloadDataList();
  };

  const showAddDialog = () => {
    setAddDialogVisible(true);
  };

  const closeAddDialog = () => {
    setAddDialogVisible(false);
  };

  const onAddSuccess = (accessID) => {
    closeAddDialog();
    reloadDataList();
    showNotifyMessage("new access token " + accessID + " added");
    writeLog("added new access token " + accessID);
  };

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const {
      id,
      name,
      application,
      validity_period_not_before,
      validity_period_not_after,
      issuer,
    } = record;
    return [
      id,
      name,
      application,
      new Date(validity_period_not_before).toLocaleString(),
      new Date(validity_period_not_after).toLocaleString(),
      issuer,
      operates,
    ];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            onClick: () => showViewDialog(record.id),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication, RoleActionReadIdentity]),
          },
          {
            onClick: () => showRemoveDialog(record.id),
            icon: DeleteIcon,
            label: texts.remove,
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication, RoleActionDeleteIdentity]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.id,
            texts.name,
            texts.app,
            texts.notBefore,
            texts.notAfter,
            texts.issuer,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    let title;
    if ("cn" === lang) {
      title = "应用" + appID + "所属令牌";
    } else {
      title = "Access Tokens of Application " + appID;
    }
    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{title}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: onList,
      label: texts.back,
      icon: NavigateBeforeIcon,
    },
    {
      onClick: () => showAddDialog(),
      label: texts.createButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication, RoleActionCreateIdentity]),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ViewTokenDialog
        lang={lang}
        open={viewDialogVisible}
        appID={appID}
        accessID={currentItem}
        onCancel={closeViewDialog}
      />
      <DeleteTokenDialog
        lang={lang}
        open={removeDialogVisible}
        appID={appID}
        accessID={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
      <AddTokenDialog
        lang={lang}
        open={addDialogVisible}
        appID={appID}
        onSuccess={onAddSuccess}
        onCancel={closeAddDialog}
      />
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

TokenList.propTypes = {
  appID: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
