import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "components/CustomButtons/Button.js";
import InputList from "components/CustomInput/InputList";
import CustomDialog from "components/Dialog/CustomDialog.js";
import Warning from "components/Typography/Warning";
import { createAccessToken } from "backend_api";

const i18n = {
  en: {
    title: "Create New Access Token",
    labelName: "Access Name",
    labelMonth: "Validity(in Month)",
    labelPrivate: "Token Data",
    copied: "Token data copied",
    promptSave: "Save your access data carefully",
    stepConfig: "Configure Access",
    stepGet: "Get New Token",
    cancel: "Cancel",
    submit: "Submit",
    copy: "Copy Token",
    close: "Close",
    invalidValidity: "The period of validity cannot be longer than three years",
  },
  cn: {
    title: "新增令牌",
    labelName: "标识名",
    labelMonth: "有效期（月）",
    labelPrivate: "令牌信息",
    copied: "令牌内容已复制",
    promptSave: "请妥善保管令牌信息，丢失将无法找回",
    stepConfig: "配置令牌信息",
    stepGet: "获取新令牌",
    cancel: "取消",
    submit: "提交",
    copy: "复制令牌信息",
    close: "关闭",
    invalidValidity: "有效期不得超过三年",
  },
};

export default function AddTokenDialog(props) {
  const defaultValues = {
    name: "",
    month: "",
  };
  const { lang, open, appID, onSuccess, onCancel } = props;
  const [created, setCreated] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [operatable, setOperatable] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const [request, setRequest] = React.useState(defaultValues);
  const texts = i18n[lang];
  const title = texts.title;
  const showNotifyMessage = (msg) => {
    toast.success(msg);
  };

  const onOperateFail = (msg) => {
    setOperatable(true);
    setPrompt(msg);
  };

  const resetDialog = () => {
    setPrompt("");
    setCreated(false);
    setData(null);
    setRequest(defaultValues);
  };

  const cancelDialog = () => {
    resetDialog();
    onCancel();
  };

  const closeDialog = () => {
    resetDialog();
    onSuccess(data.private_data.id);
  };

  const onOperateSuccess = (resp) => {
    setOperatable(true);
    setPrompt("");
    setCreated(true);
    setData(resp);
  };

  const handleConfirm = () => {
    setOperatable(false);
    if (!request.name || "" === request.name) {
      onOperateFail("access name required");
      return;
    }

    if (!request.month || "" === request.month) {
      onOperateFail("Validity month required");
      return;
    }
    let validityMonth = parseInt(request.month, 10);
    if (isNaN(validityMonth)) {
      onOperateFail("invalid month: " + request.month);
      return;
    }
    const MaxValidity = 12 * 3;

    if (validityMonth < 1) {
      onOperateFail("invalid month: " + request.month);
      return;
    }
    if (validityMonth > MaxValidity){
      onOperateFail(texts.invalidValidity);
      return;
    }
    const expire_days = validityMonth * 30;
    createAccessToken(
      appID,
      request.name,
      expire_days,
      onOperateSuccess,
      onOperateFail
    );
  };

  const handleRequestPropsChanged = (name) => (e) => {
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  //rendering
  let buttons, contentComponents;
  if (!created) {
    buttons = [
      {
        color: "transparent",
        label: texts.cancel,
        onClick: cancelDialog,
      },
      {
        color: "info",
        label: texts.submit,
        onClick: handleConfirm,
      },
    ];
    const inputs = [
      {
        type: "text",
        label: texts.labelName,
        onChange: handleRequestPropsChanged("name"),
        value: request.name,
        required: true,
        oneRow: true,
        xs: 12,
        md: 6,
      },
      {
        type: "text",
        label: texts.labelMonth,
        onChange: handleRequestPropsChanged("month"),
        value: request.month,
        required: true,
        oneRow: true,
        xs: 6,
        md: 4,
      },
    ];
    contentComponents = <InputList inputs={inputs} />;
  } else {
    buttons = [
      {
        color: "info",
        label: texts.close,
        onClick: closeDialog,
      },
    ];
    const inputs = [
      {
        type: "textarea",
        label: texts.labelPrivate,
        value: JSON.stringify(data, null, 2),
        disabled: true,
        oneRow: true,
        xs: 12,
      },
    ];
    contentComponents = (
      <Stack spacing={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            m: 0,
            pt: 3,
            pb: 0,
          }}
        >
          <Warning>
            <Typography variant="subtitle1">{texts.promptSave}</Typography>
          </Warning>
        </Box>
        <Box>
          <InputList inputs={inputs} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: 0,
            p: 0,
          }}
        >
          <CopyToClipboard
            key="copy"
            text={JSON.stringify(data, null, 2)}
            onCopy={() => showNotifyMessage(texts.copied)}
          >
            <Button size="sm" color="success" round>
              <ContentCopyIcon />
              {texts.copy}
            </Button>
          </CopyToClipboard>
        </Box>

        <ToastContainer autoClose={3500} draggable={false} />
      </Stack>
    );
  }

  const content = (
    <Box>
      <Stepper activeStep={created ? 1 : 0}>
        <Step key="config">
          <StepLabel completed={created} active={!created}>
            {texts.stepConfig}
          </StepLabel>
        </Step>
        <Step key="get">
          <StepLabel active={created}>{texts.stepGet}</StepLabel>
        </Step>
      </Stepper>
      {contentComponents}
    </Box>
  );

  return (
    <CustomDialog
      size="sm"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={operatable}
    />
  );
}

AddTokenDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  appID: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
