import React from "react";
import PropTypes from "prop-types";
import InputList from "components/CustomInput/InputList";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { addPeer } from "backend_api";

const i18n = {
  en: {
    title: "Add New Peer",
    labelName: "Peer Name",
    labelAddress: "Peer Address",
    labelMonth: "Validity(in Month)",
    cancel: "Cancel",
    confirm: "Confirm",
    invalidValidity: "The period of validity cannot be longer than ten years",
  },
  cn: {
    title: "新增链节点",
    labelName: "节点名",
    labelAddress: "节点地址",
    labelMonth: "有效期（月）",
    cancel: "取消",
    confirm: "确定",
    invalidValidity: "有效期不得超过十年",
  },
};

export default function AddPeerDialog(props) {
  const defaultValues = {
    name: "",
    address: "",
    month: "",
  };
  const { lang, open, chainName, onSuccess, onCancel } = props;
  const [operatable, setOperatable] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const [request, setRequest] = React.useState(defaultValues);
  const texts = i18n[lang];
  const title = texts.title;
  const onOperateFail = (msg) => {
    setOperatable(true);
    setPrompt(msg);
  };

  const resetDialog = () => {
    setPrompt("");
    setRequest(defaultValues);
  };

  const closeDialog = () => {
    resetDialog();
    onCancel();
  };

  const onOperateSuccess = (peerName) => {
    setOperatable(true);
    resetDialog();
    onSuccess(peerName);
  };

  const handleConfirm = () => {
    setOperatable(false);
    if (!request.name || "" === request.name) {
      onOperateFail("Peer name required");
      return;
    }
    if (!request.address || "" === request.address) {
      onOperateFail('Peer address required (format: "host:port")');
      return;
    }
    let splitHost = request.address.split(":");
    const splitCount = splitHost.length;
    if (2 !== splitCount) {
      onOperateFail("unexpected address element count: " + splitCount);
      return;
    }
    let hostname = splitHost[0];
    if ("" !== hostname) {
      const ipv4Pattern = new RegExp(
        "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
      );
      if (!ipv4Pattern.test(hostname)) {
        onOperateFail("unsupport hostname: " + hostname);
        return;
      }
    }
    let port = parseInt(splitHost[1], 10);
    if (isNaN(port)) {
      onOperateFail("invalid address port: " + port);
      return;
    }

    if (!request.month || "" === request.month) {
      onOperateFail("Validity month required");
      return;
    }
    let validityMonth = parseInt(request.month, 10);
    if (isNaN(validityMonth)) {
      onOperateFail("invalid month: " + request.month);
      return;
    }
    const MaxValidity = 12 * 10;
    if (validityMonth < 1) {
      onOperateFail("invalid month: " + request.month);
      return;
    }
    if (validityMonth > MaxValidity){
      onOperateFail(texts.invalidValidity);
      return;
    }
    addPeer(
      chainName,
      request.name,
      request.address,
      validityMonth,
      onOperateSuccess,
      onOperateFail
    );
  };

  const handleRequestPropsChanged = (name) => (e) => {
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const inputs = [
    {
      type: "text",
      label: texts.labelName,
      onChange: handleRequestPropsChanged("name"),
      value: request.name,
      required: true,
      oneRow: true,
      xs: 8,
    },
    {
      type: "text",
      label: texts.labelAddress,
      onChange: handleRequestPropsChanged("address"),
      value: request.address,
      required: true,
      oneRow: true,
      xs: 8,
    },
    {
      type: "text",
      label: texts.labelMonth,
      onChange: handleRequestPropsChanged("month"),
      value: request.month,
      required: true,
      oneRow: true,
      xs: 6,
    },
  ];
  const content = <InputList inputs={inputs} />;
  const buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: closeDialog,
    },
    {
      color: "info",
      label: texts.confirm,
      onClick: handleConfirm,
    },
  ];

  return (
    <CustomDialog
      size="xs"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={operatable}
    />
  );
}

AddPeerDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  chainName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
