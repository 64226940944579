import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
// core components
import styles from "assets/jss/material-kit-react/components/typographyStyle.js";
import { getActionDefines } from "contract_define";

const useStyles = makeStyles(styles);

export default function ActionDescription(properties) {
  const { action, parameters, inputs, lang } = properties;
  const classes = useStyles();
  const actionDefines = getActionDefines(lang);
  if (!Object.prototype.hasOwnProperty.call(actionDefines, action)) {
    return <Typography>{"unknown action: " + action}</Typography>;
  }
  const { helper } = actionDefines[action];
  if (!parameters || 0 === parameters.length) {
    return <Typography variant="span">{helper}</Typography>;
  }
  let elements = [
    {
      type: "text",
      value: helper,
    },
  ];
  parameters.forEach((tagLabel, tagIndex) => {
    const targetTag = "{" + tagIndex.toString() + "}";
    let variable;
    if (tagLabel.startsWith("@")) {
      //system variable
      let replacedLabel;
      if (!inputs || 0 === inputs.length) {
        replacedLabel = tagLabel;
      } else {
        let paramIndex = parseInt(tagLabel.slice(1), 10);
        if (isNaN(paramIndex)) {
          return (
            <Typography variant="span">
              {"invalid system tag: " + tagLabel}
            </Typography>
          );
        }
        if (paramIndex <= 0 || paramIndex > inputs.length) {
          return (
            <Typography variant="span">
              {"system tag index overflow: " + paramIndex}
            </Typography>
          );
        }
        replacedLabel = "@" + inputs[paramIndex - 1].name;
      }
      variable = {
        type: "system",
        value: replacedLabel,
      };
    } else if (tagLabel.startsWith("$")) {
      //custom variable
      variable = {
        type: "custom",
        value: tagLabel,
      };
    } else {
      //constant
      variable = {
        type: "constant",
        value: tagLabel,
      };
    }
    let translated = [];
    elements.forEach((element) => {
      if ("text" !== element.type) {
        translated.push(element);
        return;
      }
      if (!element.value.includes(targetTag)) {
        translated.push(element);
        return;
      }
      let segments = element.value.split(targetTag);
      segments.forEach((segment, i) => {
        if (0 === i) {
          //first
          translated.push({
            type: "text",
            value: segment,
          });
        } else {
          translated.push(variable, {
            type: "text",
            value: segment,
          });
        }
      });
    });
    elements = translated;
  });
  return elements.map(({ type, value }, index) => {
    switch (type) {
      case "system":
        return (
          <Typography
            className={classes.successText}
            key={index}
            variant="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            {" " + value + " "}
          </Typography>
        );
      case "custom":
        return (
          <Typography
            className={classes.warningText}
            key={index}
            variant="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            {" " + value + " "}
          </Typography>
        );
      case "constant":
        return (
          <Typography
            className={classes.infoText}
            key={index}
            variant="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            {" '" + value + "' "}
          </Typography>
        );
      default:
        //default 'text'
        return (
          <Typography variant="span" key={index}>
            {value}
          </Typography>
        );
    }
  });
}
