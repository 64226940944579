import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Button from "components/CustomButtons/Button.js";
import LogTimeline from "components/Timeline/LogTimeline";
import { getSchemaLogs } from "backend_api";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    tableTitle: "Version Logs: ",
    latestTitle: "Latest Version ",
    version: "Version",
    timestamp: "Operate Time",
    operate: "Operate",
    invoker: "Invoker",
    block: "Block ID",
    transaction: "Transaction ID",
    confirmed: "Persistent Stored",
    tagConfirmed: "Stored",
    tagUnconfirmed: "Committed",
    noRecord: "No log available",
  },
  cn: {
    back: "返回",
    tableTitle: "变更记录：",
    latestTitle: "最新版本 ",
    version: "版本号",
    timestamp: "操作时间",
    operate: "操作内容",
    invoker: "触发者",
    block: "区块ID",
    transaction: "交易ID",
    confirmed: "持久化存储",
    tagConfirmed: "已存储",
    tagUnconfirmed: "已提交",
    noRecord: "无版本记录",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function SchemaLogs(props) {
  const schemaName = props.match.params.schema;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [latestVersion, setLatestVersion] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { logs, latest_version } = result;
      setLatestVersion(latest_version);
      setDataList(logs);
    };

    getSchemaLogs(chainName, domainName, schemaName, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, chainName, mounted]);

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let logComponents;
    if (!dataList || 0 === dataList.length) {
      logComponents = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      logComponents = <LogTimeline logs={dataList} lang={lang} />;
    }

    let title =
      texts.tableTitle +
      schemaName +
      " (" +
      texts.latestTitle +
      latestVersion +
      ")";
    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{title}</h4>
        </CardHeader>
        <CardBody>{logComponents}</CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={() => {
        const listURL = "/admin/assets/";
        window.location.assign(listURL);
        // props.history.goBack();
      }}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} m={1}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

SchemaLogs.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
