import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import RemoveRoleDialog from "views/Access/RemoveRoleDialog";
import { queryRoles, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionReadRole,
  RoleActionUpdateRole,
  RoleActionDeleteRole,
  RoleActionCreateRole,
  RoleActionCreateIdentity,
 } from "privileges";

const i18n = {
  en: {
    name: "Role Name",
    description: "Description",
    noRecord: "No role available",
    operates: "Operates",
    addButton: "Add New Role",
    modifyButton: "Modify",
    viewButton: "View Detail",
    removeButton: "Remove",
  },
  cn: {
    name: "角色名",
    description: "描述",
    noRecord: "暂无角色",
    operates: "操作",
    addButton: "添加新角色",
    modifyButton: "修改",
    viewButton: "查看详情",
    removeButton: "删除",
  },
};

export default function RoleList(properties) {
  const { lang, onAdd, onView, onModify } = properties;
  const texts = i18n[lang];
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.success(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { roles } = result;
      setDataList(roles);
    };

    queryRoles(onLoadSuccess, onLoadFail);
  }, [showErrorMessage, mounted]);

  //remove
  const showRemoveDialog = (roleName) => {
    setCurrentItem(roleName);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (roleName) => {
    closeRemoveDialog();
    showNotifyMessage("role " + roleName + " removed");
    writeLog("removed role " + roleName);
    reloadDataList();
  };

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { name, description } = record;
    return [name, description, operates];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.viewButton,
            onClick: () => onView(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadRole]),
          },
          {
            icon: EditIcon,
            label: texts.modifyButton,
            onClick: () => onModify(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionUpdateRole]),
          },
          {
            onClick: () => showRemoveDialog(record.name),
            icon: DeleteIcon,
            label: texts.removeButton,
            privileges: new PrivilegeRequirements().roleActions([RoleActionDeleteRole]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.name, texts.description, texts.operates]}
          rows={rows}
        />
      );
    }

    content = <Container maxWidth="md">{tableContent}</Container>;
  }

  const buttons = [
    {
      onClick: onAdd,
      label: texts.addButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionCreateRole, RoleActionCreateIdentity]),
    },
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <RemoveRoleDialog
        lang={lang}
        open={removeDialogVisible}
        roleName={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
    </GridContainer>
  );
}

RoleList.prototype = {
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
