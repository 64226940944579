import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from "@mui/icons-material/Visibility";
import StyleIcon from "@mui/icons-material/Style";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import Table from "components/Table/ObjectTable.js";
import DeleteApplicationDialog from "views/Applications/DeleteApplicationDialog.js";
import { queryApplications, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionReadApplication,
  RoleActionUpdateApplication,
  RoleActionRemoveApplication,
  RoleActionAddApplication,
  RoleActionCreateIdentity,
  RoleActionListApplication,
 } from "privileges";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    createButton: "Create New Application",
    modifyButton: "Modify",
    groupButton: "Manage Groups",
    tableTitle: "Chain Applications",
    id: "ID",
    name: "Name",
    owner: "Owner",
    issuer: "Issuer",
    noRecord: "No application available",
    operates: "Operates",
    detail: "Detail",
    token: "Access Token",
    remove: "Delete",
  },
  cn: {
    createButton: "创建新应用",
    modifyButton: "修改",
    groupButton: "管理应用组",
    tableTitle: "链应用",
    id: "标识",
    name: "名称",
    owner: "拥有者",
    issuer: "签发者",
    noRecord: "暂无链应用",
    operates: "操作",
    detail: "详情",
    token: "访问令牌",
    remove: "删除",
  },
};

export default function Applications(props) {
  const { lang, onAdd, onModify, onView, onTokens } = props;
  const recordPerPage = 10;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  //for dialog
  // const [ addDialogVisible, setAddDialogVisible ] = React.useState(false);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { applications, offset, total } = result;
      setDataList(applications);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryApplications(offset, recordPerPage, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, currentPage, mounted]);

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.success(msg);
  };

  //remove
  const showRemoveDialog = (appID) => {
    setCurrentItem(appID);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (appID) => {
    closeRemoveDialog();
    showNotifyMessage("app " + appID + " deleted");
    writeLog("deleted app " + appID);
    reloadDataList();
  };

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { id, name, owner, issuer } = record;
    return [id, name, owner, issuer, operates];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            onClick: () => onView(record.id),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication]),
          },
          {
            icon: StyleIcon,
            label: texts.token,
            onClick: () => onTokens(record.id),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication]),
          },
          {
            icon: EditIcon,
            label: texts.modifyButton,
            onClick: () => onModify(record.id),
            privileges: new PrivilegeRequirements().roleActions([RoleActionUpdateApplication]),
          },
          {
            onClick: () => showRemoveDialog(record.id),
            icon: DeleteIcon,
            label: texts.remove,
            privileges: new PrivilegeRequirements().roleActions([RoleActionRemoveApplication]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.id,
            texts.name,
            texts.owner,
            texts.issuer,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: onAdd,
      label: texts.createButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionAddApplication, RoleActionCreateIdentity]),
    },
    {
      onClick: () => {
        window.location.assign("/admin/applications/groups/");
      },
      label: texts.groupButton,
      icon: MenuIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionListApplication, RoleActionReadApplication]),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <GridItem>
        <DeleteApplicationDialog
          lang={lang}
          open={removeDialogVisible}
          appID={currentItem}
          onSuccess={onRemoveSuccess}
          onCancel={closeRemoveDialog}
        />
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

Applications.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onTokens: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
