import React from "react";
import PropTypes from "prop-types";
import RoleList from "views/Access/RoleList";
import ModifyRole from "views/Access/ModifyRole";
import AddRole from "views/Access/AddRole";
import ViewRole from "views/Access/ViewRole";

export default function RoleTab(props) {
  const DEFAULT_MODE = "list";
  const MODE_ADD = "add";
  const MODE_VIEW = "view";
  const MODE_MODIFY = "modify";
  const [mode, setMode] = React.useState(DEFAULT_MODE);
  const [target, setTarget] = React.useState(null);

  const handleAdd = () => {
    setTarget("");
    setMode(MODE_ADD);
  };

  const handleView = (roleName) => {
    setTarget(roleName);
    setMode(MODE_VIEW);
  };

  const handleModify = (roleName) => {
    setTarget(roleName);
    setMode(MODE_MODIFY);
  };

  const list = () => {
    setTarget("");
    setMode(DEFAULT_MODE);
  };

  switch (mode) {
    case MODE_ADD:
      return <AddRole onList={list} {...props} />;
    case MODE_VIEW:
      return <ViewRole onList={list} roleName={target} {...props} />;
    case MODE_MODIFY:
      return <ModifyRole onList={list} roleName={target} {...props} />;
    default:
      return (
        <RoleList
          onAdd={handleAdd}
          onView={handleView}
          onModify={handleModify}
          {...props}
        />
      );
  }
}

RoleTab.prototype = {
  lang: PropTypes.string.isRequired,
};
