import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import ApplicationPanel from "views/Applications/ApplicationPanel";
import AppGroupPanel from "views/Applications/AppGroupPanel";
import MustInitialized from "views/Login/MustInitialized";

export default function ApplicationRoutes(properties) {

  let pathTargets = [
    {
      path: "/admin/applications",
      element: ApplicationPanel,
    },
    {
      path: "/admin/applications/groups/",
      element: AppGroupPanel,
    },
  ];
  const components = pathTargets.map(target => {
    const {path, element} = target;
    return (
      <Route
        key={path}
        path={path}
        exact
        render={(childrenProps) =>
          React.createElement(element, {
            ...childrenProps,
            ...properties,
          })
        }
      />
    );
  });
  return <MustInitialized lang={properties.lang} component={components} />;
}

ApplicationRoutes.prototype = {
  lang: PropTypes.string.isRequired,
};
