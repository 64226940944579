import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PublishIcon from "@mui/icons-material/Publish";
import RefreshIcon from "@mui/icons-material/Refresh";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import Table from "components/Table/ObjectTable.js";
import AddGatewayDialog from "views/Chains/AddGatewayDialog";
import RemoveGatewayDialog from "views/Chains/RemoveGatewayDialog";
import DeployGatewayCertificateDialog from "views/Chains/DeployGatewayCertificateDialog";
import UpdateGatewayCertificateDialog from "views/Chains/UpdateGatewayCertificateDialog";
import { queryGateways, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionReadNode,
  RoleActionUpdateNode,
  RoleActionRemoveNode,
  RoleActionAddNode,
 } from "privileges";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    addButton: "Add New Gateway",
    removeButton: "Remove Gateway",
    deployButton: "Deploy Certificate",
    updateButton: "Update Certificate",
    tableTitle: "Gateway List",
    tag: "Gateway ID",
    address: "Address",
    organization: "Organization",
    current: "Current ID",
    alive: "Alive",
    logs: "Gateway Log",
    tagOnline: "Online",
    tagOffline: "Offline",
    certificated: "Certificated",
    tagCertificated: "Certificated",
    tagUncertificated: "Uncertificated",
    noRecord: "No gateway available",
    operates: "Operates",
  },
  cn: {
    back: "返回",
    addButton: "添加新网关",
    removeButton: "删除网关",
    deployButton: "部署证书",
    updateButton: "更新证书",
    tableTitle: "网关列表",
    tag: "网关标识",
    address: "地址",
    organization: "所属组织",
    current: "当前标识",
    alive: "是否在线",
    logs: "网关日志",
    tagOnline: "在线",
    tagOffline: "离线",
    certificated: "是否认证",
    tagCertificated: "已认证",
    tagUncertificated: "未认证",
    noRecord: "无可用网关",
    operates: "操作",
  },
};

export default function GatewayList(props) {
  const chainName = props.match.params.chain;
  const recordPerPage = 20;
  const autoRefreshInterval = 3; //3 seconds
  const { lang } = props;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [addDialogVisible, setAddDialogVisible] = React.useState(false);
  const [updateDialogVisible, setUpdateDialogVisible] = React.useState(false);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [deployDialogVisible, setDeployDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.info(msg);
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };

    const onLoadSuccess = (result) => {
      const { gateways, offset, total } = result;
      setDataList(gateways);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryGateways(chainName, offset, recordPerPage, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, currentPage, chainName, mounted]);

  const dataToNodes = (data, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { name, address, organization, current_name, alive, certificated } =
      data;
    let aliveIcon;
    if (alive) {
      aliveIcon = (
        <Tooltip title={texts.tagOnline} placement="top">
          <WifiIcon color="success" />
        </Tooltip>
      );
    } else {
      aliveIcon = (
        <Tooltip title={texts.tagOffline} placement="top">
          <WifiOffIcon color="warning" />
        </Tooltip>
      );
    }

    let certificatedIcon;
    if (certificated) {
      certificatedIcon = (
        <Tooltip title={texts.tagCertificated} placement="top">
          <VerifiedIcon color="info" />
        </Tooltip>
      );
    } else {
      certificatedIcon = (
        <Tooltip title={texts.tagUncertificated} placement="top">
          <NewReleasesIcon color="warning" />
        </Tooltip>
      );
    }
    return [
      name,
      address,
      organization,
      current_name,
      aliveIcon,
      certificatedIcon,
      operates,
    ];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const showAddDialog = () => {
    setAddDialogVisible(true);
  };

  const closeAddDialog = () => {
    setAddDialogVisible(false);
  };

  const onAddSuccess = (peerName) => {
    closeAddDialog();
    reloadDataList();
    showNotifyMessage("new gateway " + peerName + " added");
    writeLog("added new gateway " + peerName);
  };

  const showDeployDialog = (peerName) => {
    setCurrentItem(peerName);
    setDeployDialogVisible(true);
  };

  const closeDeployDialog = () => {
    setDeployDialogVisible(false);
  };

  const onDeploySuccess = (peerName) => {
    closeDeployDialog();
    showNotifyMessage("new certificate deployed to gateway " + peerName);
    reloadDataList();
    writeLog("deployed new certificate to gateway " + peerName);
  };

  const showUpdateDialog = (peerName) => {
    setCurrentItem(peerName);
    setUpdateDialogVisible(true);
  };

  const closeUpdateDialog = () => {
    setUpdateDialogVisible(false);
  };

  const onUpdateSuccess = (peerName) => {
    closeUpdateDialog();
    reloadDataList();
    showNotifyMessage("certificate of gateway " + peerName + " updated");
    writeLog("updated certificate of gateway " + peerName);
  };

  const showRemoveDialog = (peerName) => {
    setCurrentItem(peerName);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (peerName) => {
    closeRemoveDialog();
    reloadDataList();
    showNotifyMessage("gateway " + peerName + " removed");
    writeLog("removed gateway " + peerName);
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    const updateInterval = autoRefreshInterval * 1000;
    let timerID = setInterval(() => {
      if (!mounted) {
        return;
      }
      reloadDataList();
    }, updateInterval);
    return () => {
      setMounted(false);
      if (timerID) {
        clearInterval(timerID);
      }
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        let buttons = [];
        if (record.alive) {
          if (record.certificated) {
            buttons.push(
              {
                privileges: new PrivilegeRequirements().roleActions([RoleActionReadNode, "read_log"]),
                icon: ListAltIcon,
                label: texts.logs,
                href:
                  "/admin/chains/" +
                  chainName +
                  "/gateways/" +
                  record.name +
                  "/logs/",
              },
              {
                onClick: () => showUpdateDialog(record.name),
                icon: RefreshIcon,
                label: texts.updateButton,
                privileges: new PrivilegeRequirements().roleActions([RoleActionUpdateNode]),
              }
            );
          } else {
            buttons.push({
              onClick: () => showDeployDialog(record.name),
              icon: PublishIcon,
              label: texts.deployButton,
              privileges: new PrivilegeRequirements().roleActions([RoleActionUpdateNode]),
            });
          }
        }
        buttons.push({
          onClick: () => showRemoveDialog(record.name),
          icon: DeleteIcon,
          label: texts.removeButton,
          privileges: new PrivilegeRequirements().roleActions([RoleActionRemoveNode]),
        });
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.tag,
            texts.address,
            texts.organization,
            texts.current,
            texts.alive,
            texts.certificated,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: () => {
        const listURL = "/admin/chains";
        window.location.assign(listURL);
      },
      label: texts.back,
      icon: NavigateBeforeIcon,
    },
    {
      onClick: () => showAddDialog(),
      label: texts.addButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionAddNode]),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <AddGatewayDialog
        lang={lang}
        open={addDialogVisible}
        chainName={chainName}
        onSuccess={onAddSuccess}
        onCancel={closeAddDialog}
      />
      <DeployGatewayCertificateDialog
        lang={lang}
        open={deployDialogVisible}
        chainName={chainName}
        peerName={currentItem}
        onSuccess={onDeploySuccess}
        onCancel={closeDeployDialog}
      />
      <UpdateGatewayCertificateDialog
        lang={lang}
        open={updateDialogVisible}
        chainName={chainName}
        peerName={currentItem}
        onSuccess={onUpdateSuccess}
        onCancel={closeUpdateDialog}
      />
      <RemoveGatewayDialog
        lang={lang}
        open={removeDialogVisible}
        chainName={chainName}
        peerName={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

GatewayList.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
