import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { getCertificate } from "backend_api";

const i18n = {
  en: {
    title: "Certificate Detail",
    version: "Format Version",
    id: "Certificate ID",
    serial: "Serial Number",
    notBefore: "Valid After",
    notAfter: "Valid Before",
    issuer: "Issuer",
    root: "Is Root Certificate",
    tagYes: "Yes",
    tagNo: "No",
    publicKey: "Public Key",
    signature: "Digital Signature",
    close: "Close",
    copied: "Public Key copied",
  },
  cn: {
    title: "证书详情",
    version: "证书格式版本",
    id: "证书ID",
    serial: "序列号",
    notBefore: "生效时间",
    notAfter: "失效时间",
    issuer: "签发机构",
    root: "是否根证书",
    tagYes: "是",
    tagNo: "否",
    publicKey: "证书公钥",
    signature: "数字签名",
    close: "关闭",
    copied: "公钥已复制",
  },
};

export default function ViewCertificateDialog(props) {
  const { lang, open, index, onCancel } = props;
  const texts = i18n[lang];
  const title = texts.title;
  const [data, setData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [prompt, setPrompt] = React.useState("");

  const closeDialog = () => {
    if (!mounted) {
      return;
    }
    setPrompt("");
    onCancel();
  };

  const onOperateFail = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      setPrompt(msg);
    },
    [mounted]
  );

  React.useEffect(() => {
    if (!index) {
      return;
    }
    setMounted(true);
    const onLoadDataSuccess = (data) => {
      setData(data);
      setInitialized(true);
    };

    getCertificate(index, onLoadDataSuccess, onOperateFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [index, onOperateFail]);

  //rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    const {
      version,
      id,
      serial_number,
      validity_period_not_before,
      validity_period_not_after,
      issuer,
      is_root,
      public_key_algorithm,
      public_key,
      signature_algorithm,
      signature,
    } = data;

    const keyChip = (
      <CopyToClipboard
        key="copy"
        text={public_key}
        onCopy={() => toast.info(texts.copied)}
      >
        <Tooltip
          title={public_key_algorithm + ": " + public_key}
          key="public_key"
        >
          <Chip label={public_key_algorithm} color="info" />
        </Tooltip>
      </CopyToClipboard>
    );
    const signatureChip = (
      <Tooltip title={signature_algorithm + ": " + signature} key="signature">
        <Chip label={signature_algorithm} color="success" />
      </Tooltip>
    );
    let tableData = [
      {
        title: texts.version,
        value: version,
      },
      {
        title: texts.id,
        value: id,
      },
      {
        title: texts.serial,
        value: serial_number,
      },
      {
        title: texts.notBefore,
        value: new Date(validity_period_not_before).toLocaleString(),
      },
      {
        title: texts.notAfter,
        value: new Date(validity_period_not_after).toLocaleString(),
      },
      {
        title: texts.issuer,
        value: issuer,
      },
      {
        title: texts.root,
        value: is_root ? texts.tagYes : texts.tagNo,
      },
      {
        title: texts.publicKey,
        value: keyChip,
      },
      {
        title: texts.signature,
        value: signatureChip,
      },
    ];
    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }

  const buttons = [
    {
      color: "transparent",
      label: texts.close,
      onClick: closeDialog,
    },
  ];

  return (
    <CustomDialog
      size="md"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={true}
    />
  );
}

ViewCertificateDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
