import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupIcon from "@mui/icons-material/Group";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from '@mui/icons-material/Menu';
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import DeleteGroupDialog from "views/Access/DeleteGroupDialog";
import { queryGroups, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionReadManager,
  RoleActionUpdateManager,
  RoleActionDeleteManager,
  RoleActionCreateManager,
  RoleActionCreateIdentity,
 } from "privileges";

const i18n = {
  en: {
    name: "Group Name",
    description: "Description",
    noRecord: "No group available",
    operates: "Operates",
    addButton: "Create New Group",
    modifyButton: "Modify",
    removeButton: "Remove",
    tagsButton: "Menus",
    rolesButton: "Roles",
    membersButton: "Members",
  },
  cn: {
    name: "用户组名",
    description: "描述",
    noRecord: "暂无用户组",
    operates: "操作",
    addButton: "创建用户组",
    modifyButton: "修改",
    removeButton: "删除",
    tagsButton: "菜单管理",
    rolesButton: "权限管理",
    membersButton: "所属成员",
  },
};

export default function GroupList(properties) {
  const { lang, onAdd, onModify, onRoles, onTags, onMembers } = properties;
  const texts = i18n[lang];
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.success(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { groups } = result;
      setDataList(groups);
    };

    queryGroups(onLoadSuccess, onLoadFail);
  }, [showErrorMessage, mounted]);

  //remove
  const showRemoveDialog = (groupName) => {
    setCurrentItem(groupName);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (groupName) => {
    closeRemoveDialog();
    showNotifyMessage("group " + groupName + " removed");
    writeLog("deleted group " + groupName);
    reloadDataList();
  };

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { name, description } = record;
    return [name, description, operates];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: GroupIcon,
            label: texts.membersButton,
            onClick: () => onMembers(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadManager]),
          },
          {
            icon: MenuIcon,
            label: texts.tagsButton,
            onClick: () => onTags(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadManager, RoleActionUpdateManager]),
          },
          {
            icon: AccountTreeIcon,
            label: texts.rolesButton,
            onClick: () => onRoles(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadManager, RoleActionUpdateManager]),
          },
          {
            icon: EditIcon,
            label: texts.modifyButton,
            onClick: () => onModify(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadManager, RoleActionUpdateManager]),
          },
          {
            onClick: () => showRemoveDialog(record.name),
            icon: DeleteIcon,
            label: texts.removeButton,
            privileges: new PrivilegeRequirements().roleActions([RoleActionDeleteManager]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.name, texts.description, texts.operates]}
          rows={rows}
        />
      );
    }

    content = <Container maxWidth="md">{tableContent}</Container>;
  }

  const buttons = [
    {
      onClick: onAdd,
      label: texts.addButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionCreateManager, RoleActionCreateIdentity]),
    },
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <DeleteGroupDialog
        lang={lang}
        open={removeDialogVisible}
        groupName={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
    </GridContainer>
  );
}

GroupList.prototype = {
  onAdd: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onRoles: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
