import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Alert from "@mui/material/Alert";
import { Redirect } from "react-router-dom";
import { dashboardStyle } from "assets/jss/custom-styles";
import LanguageSelector from "components/Language/Selector.js";

import bgImage from "assets/img/login_background.jpg";
import {
  getSystemStatus,
  initialSystem,
  getAllMenus,
} from "backend_api.js";
import { PROJECT_NAME } from "project.js";

const getClasses = makeStyles(() => ({
  ...dashboardStyle,
  background: {
    backgroundImage: "url(" + bgImage + ")",
    height: "100vh",
  },
}));

const i18n = {
  cn: {
    welcome: "欢迎使用" + PROJECT_NAME + "平台",
    description: "请设定管理员账号及密码，开始初始化系统",
    user: "默认管理员帐号",
    password: "密码",
    password2: "确认密码",
    initial: "初始化",
    confirm: "确认",
    success: "系统初始化成功，点击进入登录页面",
    promptEmptyName: "请输入管理员账号",
    promptInvalidName: "账号名只允许字符数字与下划线",
    promptEmptyNew: "请输入新密码",
    promptMismatch: "两个新密码必须一致",
    promptWeak: "密码长度至少8个字符，必须包含大小写字母、数字和特殊字符",
  },
  en: {
    welcome: "Welcome to " + PROJECT_NAME,
    description: "Please set up a new admin account",
    user: "Super Admin Name",
    password: "Password",
    password2: "Confirm Password",
    initial: "Initial System",
    confirm: "Confirm",
    success: "System initialed, click to login",
    promptEmptyName: "Input admin name",
    promptInvalidName: "Only words and '_' allowed",
    promptEmptyNew: "Input New Password",
    promptMismatch: "Two Password Mismatched",
    promptWeak:
      "Strong password required, A minimum of 8 characters, a combination of letters(both of uppercase and lowercase required), numbers, and symbols.",
  },
};

export default function InitialSystem(props) {
  const StageEnum = {
    uninitial: 0,
    input: 1,
    initialed: 2,
    redirect: 3,
  };
  const classes = getClasses();
  const { lang, setLang } = props;
  const texts = i18n[lang];
  const [request, setRequest] = React.useState({
    user: "",
    password: "",
    password2: "",
  });

  const [errorMessage, setError] = React.useState("");
  const [stage, setStage] = React.useState(StageEnum.uninitial);

  const notifyError = (message) => {
    const notifyElapsed = 5000;
    setError(message);
    setTimeout(() => {
      setError("");
    }, notifyElapsed);
  };

  const handleRequestPropsChanged = (name) => (e) => {
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const onInitialFail = React.useCallback((msg) => {
    notifyError(msg);
  }, []);

  const onInitialSuccess = () => {
    setError("");
    setStage(StageEnum.initialed);
  };

  const redirect = React.useCallback(() => {
    setStage(StageEnum.redirect);
  }, [StageEnum.redirect]);

  const onResultConfirm = () => {
    redirect();
  };

  const confirmInitial = () => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const namePattern = new RegExp("[^\\w]");
    if (!request.user) {
      onInitialFail(texts.promptEmptyName);
      return;
    } else if (namePattern.test(request.user)) {
      onInitialFail(texts.promptInvalidName);
      return;
    }

    if (!request.password) {
      onInitialFail(texts.promptEmptyNew);
      return;
    } else if (request.password2 !== request.password) {
      onInitialFail(texts.promptMismatch);
      return;
    }
    if (!strongRegex.test(request.password)) {
      onInitialFail(texts.promptWeak);
      return;
    }
    let menuList = [];
    getAllMenus(lang).forEach((menu) => {
      menuList.push(menu.value);
    });
    initialSystem(
      request.user,
      request.password,
      menuList,
      onInitialSuccess,
      onInitialFail
    );
  };

  React.useEffect(() => {
    const onQueryStatusSuccess = (result) => {
      if (result.initialized) {
        redirect();
        return;
      }
      //need initial
      setStage(StageEnum.input);
    };

    getSystemStatus(onQueryStatusSuccess, onInitialFail);
  }, [StageEnum.input, onInitialFail, redirect]);

  let errorBar;
  if (errorMessage && "" !== errorMessage) {
    errorBar = (
      <GridItem xs={12}>
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      </GridItem>
    );
  } else {
    errorBar = <GridItem xs={12} />;
  }

  let content, button;
  switch (stage) {
    case StageEnum.input:
      button = (
        <Button color="info" onClick={confirmInitial}>
          {texts.initial}
        </Button>
      );
      content = (
        <Grid container>
          <GridItem xs={12}>
            <Box justifyContent="center" display="flex">
              <Typography className={classes.cardTitle}>
                {texts.description}
              </Typography>
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <Box m={0} pt={2}>
              <TextField
                label={texts.user}
                onChange={handleRequestPropsChanged("user")}
                value={request.user}
                margin="normal"
                variant="standard"
                required
                fullWidth
              />
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <Box m={0} pt={2}>
              <TextField
                label={texts.password}
                onChange={handleRequestPropsChanged("password")}
                value={request.password}
                margin="normal"
                type="password"
                variant="standard"
                required
                fullWidth
              />
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <Box m={0} pt={2}>
              <TextField
                label={texts.password2}
                onChange={handleRequestPropsChanged("password2")}
                value={request.password2}
                margin="normal"
                type="password"
                variant="standard"
                required
                fullWidth
              />
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <Box alignItems="center" display="flex" m={1}>
              {button}
              <Box flexGrow={1} />
              <LanguageSelector lang={lang} setLang={setLang} />
            </Box>
          </GridItem>
          <GridItem xs={12}>{errorBar}</GridItem>
        </Grid>
      );
      break;
    case StageEnum.initialed:
      button = (
        <Button color="info" onClick={onResultConfirm}>
          {texts.confirm}
        </Button>
      );
      content = (
        <Grid container>
          <GridItem xs={12}>
            <Box justifyContent="center" display="flex">
              <Typography
                variant="body1"
                component="span"
                className={classes.cardTitle}
              >
                {texts.success}
              </Typography>
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <Box justifyContent="center" display="flex">
              {button}
            </Box>
          </GridItem>
        </Grid>
      );
      break;
    case StageEnum.redirect:
      return <Redirect to="/login" />;
    default:
      //uninitial
      content = (
        <div>
          <Skeleton variant="rectangular" style={{ height: "10rem" }} />
          {errorBar}
        </div>
      );
  }

  return (
    <Box component="div" className={classes.background}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={4}>
            <Box mt={20} p={0}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{texts.welcome}</h4>
                </CardHeader>
                <CardBody>{content}</CardBody>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

InitialSystem.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};
