import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";

import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import ViewCertificateDialog from "views/Identities/ViewCertificateDialog.js";
import { queryCertificates } from "backend_api";
import { PrivilegeRequirements, RoleActionReadIdentity } from "privileges";

const i18n = {
  en: {
    id: "Certificate ID",
    serial: "Serial Number",
    notBefore: "Valid After",
    notAfter: "Valid Before",
    issuer: "Issuer",
    root: "Is Root Certificate",
    tagYes: "Yes",
    tagNo: "No",
    noRecord: "No certificates available",
    operates: "Operates",
    detail: "Detail",
  },
  cn: {
    id: "证书ID",
    serial: "序列号",
    notBefore: "生效时间",
    notAfter: "失效时间",
    issuer: "签发机构",
    root: "是否根证书",
    tagYes: "是",
    tagNo: "否",
    noRecord: "系统尚无数字证书",
    operates: "操作",
    detail: "查看证书",
  },
};

export default function CertificateList(props) {
  const recordPerPage = 5;
  const { lang } = props;
  const texts = i18n[lang];
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentItem, setCurrentItem] = React.useState("");
  const [viewDialogVisible, setViewDialogVisible] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showViewDialog = (index) => {
    setCurrentItem(index);
    setViewDialogVisible(true);
  };

  const closeViewDialog = () => {
    setViewDialogVisible(false);
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { certificates, offset, total } = result;
      setDataList(certificates);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryCertificates(offset, recordPerPage, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, currentPage, mounted]);

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const {
      id,
      serial_number,
      validity_period_not_before,
      validity_period_not_after,
      issuer,
      is_root,
    } = record;
    return [
      id,
      serial_number,
      new Date(validity_period_not_before).toLocaleString(),
      new Date(validity_period_not_after).toLocaleString(),
      issuer,
      is_root ? texts.tagYes : texts.tagNo,
      operates,
    ];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            onClick: () => showViewDialog(record.index),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadIdentity]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.id,
            texts.serial,
            texts.notBefore,
            texts.notAfter,
            texts.issuer,
            texts.root,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }
    content = (
      <Box>
        {tableContent}
        {pagination}
        <ViewCertificateDialog
          lang={lang}
          open={viewDialogVisible}
          index={currentItem}
          onCancel={closeViewDialog}
        />
      </Box>
    );
  }
  return content;
}

CertificateList.prototype = {
  lang: PropTypes.string.isRequired,
};
