import React from "react";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// core components
import { tableStyle } from "assets/jss/custom-styles";

const useStyles = makeStyles(tableStyle);

export default function ObjectTable(props) {
  const classes = useStyles();
  const { color, headers, rows } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        <TableHead className={classes[color + "TableHeader"]}>
          <TableRow className={classes.tableHeadRow}>
            {headers.map((prop, key) => {
              return (
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={key}
                >
                  {prop}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowKey) => (
            <TableRow key={rowKey} className={classes.tableBodyRow}>
              {row.map((cell, cellKey) => (
                <TableCell className={classes.tableCell} key={cellKey}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

ObjectTable.defaultProps = {
  color: "gray",
};

ObjectTable.propTypes = {
  color: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  headers: PropTypes.arrayOf(PropTypes.node),
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
};
