import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { getIdentity } from "backend_api";

const i18n = {
  en: {
    title: "Identity Detail",
    version: "Format Version",
    id: "Identity ID",
    storage: "Storage Method",
    salt: "Salt",
    hash: "Secret Hash Algorithm",
    secret: "Stored Secret",
    encryptAlgorithm: "Encrypt Algorithm",
    privateKey: "Private Key",
    issuer: "Issuer",
    signature: "Digital Signature",
    close: "Close",
    copied: "Private Key copied",
  },
  cn: {
    title: "身份详情",
    version: "格式版本",
    id: "身份ID",
    storage: "存储模式",
    salt: "盐值",
    hash: "密码哈希方法",
    secret: "存储密文",
    encryptAlgorithm: "私钥加密算法",
    privateKey: "私钥",
    issuer: "签发者",
    signature: "数字签名",
    close: "关闭",
    copied: "私钥已复制",
  },
};

export default function ViewIdentityDialog(props) {
  const { lang, open, index, onCancel } = props;
  const texts = i18n[lang];
  const title = texts.title;
  const [data, setData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [prompt, setPrompt] = React.useState("");

  const closeDialog = () => {
    if (!mounted) {
      return;
    }
    setPrompt("");
    onCancel();
  };

  const onOperateFail = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      setPrompt(msg);
    },
    [mounted]
  );

  React.useEffect(() => {
    if (!index) {
      return;
    }
    setMounted(true);
    const onLoadDataSuccess = (data) => {
      setData(data);
      setInitialized(true);
    };

    getIdentity(index, onLoadDataSuccess, onOperateFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [index, onOperateFail]);

  //rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    const {
      version,
      id,
      storage_method,
      salt,
      secret_hash_algorithm,
      stored_secret,
      encrypt_algorithm,
      encrypt_private_key,
      issuer,
      signature_algorithm,
      signature,
    } = data;

    const keyChip = (
      <CopyToClipboard
        key="copy"
        text={encrypt_private_key}
        onCopy={() => toast.info(texts.copied)}
      >
        <Tooltip
          title={encrypt_algorithm + ": " + encrypt_private_key}
          key="prviate_key"
        >
          <Chip label={encrypt_algorithm} color="info" />
        </Tooltip>
      </CopyToClipboard>
    );
    const signatureChip = (
      <Tooltip title={signature_algorithm + ": " + signature} key="signature">
        <Chip label={signature_algorithm} color="success" />
      </Tooltip>
    );
    let tableData = [
      {
        title: texts.version,
        value: version,
      },
      {
        title: texts.id,
        value: id,
      },
      {
        title: texts.storage,
        value: storage_method,
      },
      {
        title: texts.salt,
        value: salt,
      },
      {
        title: texts.hash,
        value: secret_hash_algorithm,
      },
      {
        title: texts.secret,
        value: stored_secret,
      },
      {
        title: texts.issuer,
        value: issuer,
      },
      {
        title: texts.privateKey,
        value: keyChip,
      },
      {
        title: texts.signature,
        value: signatureChip,
      },
    ];
    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }

  const buttons = [
    {
      color: "transparent",
      label: texts.close,
      onClick: closeDialog,
    },
  ];

  return (
    <CustomDialog
      size="md"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={true}
    />
  );
}

ViewIdentityDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};
