import React from "react";
import PropTypes from "prop-types";
import ActorList from "views/Shared/ActorList";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";
import { 
    PrivilegeRequirements, 
    DomainActionUpdateDocument, 
    DomainActionUpdateSystemDocument,
 } from "privileges";
import { getDocumentActors, updateDocumentActors, isSystemResource } from "backend_api";

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function DocumentActorList(props) {
    const schemaName = props.match.params.schema;
    const docID = props.match.params.id;
    const { lang, history } = props;
    let title;
    if ('cn' === lang){
        title = `数字资产权限： ${schemaName}`;
    }else{
        title = `Document Privileges: ${schemaName}`;
    }
    let requiredPrivileges;
    if (isSystemResource(docID)){
        requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([DomainActionUpdateSystemDocument]);
    }else{
        requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([DomainActionUpdateDocument]);
    }

    const onUpdateSuccess = () =>{
        const listURL = `/admin/assets/schemas/${schemaName}/docs/`;
        window.location.assign(listURL);
    }
    const actorLoader = (onLoadSuccess, onLoadFail) => {
        getDocumentActors(
            defaultChainName, 
            defaultDomainName, 
            schemaName,
            docID,
            onLoadSuccess, 
            onLoadFail,
        );
    };
    const actorUpdater = (actors, onUpdateFail) => {
        updateDocumentActors(
            defaultChainName, 
            defaultDomainName, 
            schemaName,
            docID,
            actors,
            onUpdateSuccess, 
            onUpdateFail,
        );
    };
    return (
        <ActorList
            lang={lang}
            title={title}
            history={history}
            loader={actorLoader}
            updater={actorUpdater}
            updatePrivileges={requiredPrivileges}
            />
    )
}

DocumentActorList.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
    lang: PropTypes.string.isRequired,
};