import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import AppGroupList from "views/Applications/AppGroupList";
import ModifyAppGroup from "views/Applications/ModifyAppGroup";
import ModifyAppGroupRoles from "views/Applications/ModifyAppGroupRoles";
import CreateAppGroup from "views/Applications/CreateAppGroup";
import AppGroupMembers from "views/Applications/AppGroupMembers";

export default function AppGroupPanel(props) {
  const DEFAULT_MODE = "list";
  const MODE_ADD = "add";
  const MODE_MODIFY = "modify";
  const MODE_ROLES = "roles";
  const MODE_MEMBERS = "members";
  const [mode, setMode] = React.useState(DEFAULT_MODE);
  const [target, setTarget] = React.useState("");

  const handleAdd = () => {
    setMode(MODE_ADD);
  };

  const handleListMembers = (groupName) => {
    setTarget(groupName);
    setMode(MODE_MEMBERS);
  };

  const handleModify = (groupName) => {
    setTarget(groupName);
    setMode(MODE_MODIFY);
  };

  const handleRoles = (groupName) => {
    setTarget(groupName);
    setMode(MODE_ROLES);
  };

  const list = () => {
    setMode(DEFAULT_MODE);
  };

  let panel;
  switch (mode) {
    case MODE_ADD:
      panel = <CreateAppGroup onList={list} {...props} />;
      break;
    case MODE_MEMBERS:
      panel = <AppGroupMembers onList={list} groupName={target} {...props} />;
      break;
    case MODE_ROLES:
      panel = <ModifyAppGroupRoles onList={list} groupName={target} {...props} />;
      break;
    case MODE_MODIFY:
      panel = <ModifyAppGroup onList={list} groupName={target} {...props} />;
      break;
    default:
      panel = (
        <AppGroupList
          onAdd={handleAdd}
          onModify={handleModify}
          onRoles={handleRoles}
          onMembers={handleListMembers}
          {...props}
        />
      );
  }
  return (
    <Box>
      {panel}
      <ToastContainer autoClose={3500} draggable={false} />
    </Box>
  )
}

AppGroupPanel.prototype = {
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
