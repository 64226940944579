import React from "react";
import PropTypes from "prop-types";

import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import { queryGroupMembers } from "backend_api";

const i18n = {
  en: {
    back: "Back",
    member: "Group Member",
    noRecord: "No member available",
  },
  cn: {
    back: "返回",
    member: "用户组成员",
    noRecord: "暂无成员",
  },
};

export default function GroupMembers(props) {
  const recordPerPage = 5;
  const { lang, onError, onList, groupName } = props;
  const texts = i18n[lang];
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      onError(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { members, offset, total } = result;
      setDataList(members);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryGroupMembers(
      groupName,
      offset,
      recordPerPage,
      onLoadSuccess,
      onLoadFail
    );
  }, [showErrorMessage, currentPage, mounted]);

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  let buttons = [];
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = dataList.map((member) => [member]);
      tableContent = (
        <Table color="primary" headers={[texts.member]} rows={rows} />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }
    content = (
      <GridContainer>
        <GridItem xs={12}>
          <Box display="flex">
            {buttons.map((button, key) => (
              <Box key={key} pl={2} pr={2}>
                {button}
              </Box>
            ))}
          </Box>
          <GridItem xs={12}>
            <Box mt={3} mb={3}>
              <Divider />
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <Container maxWidth="md">
              {tableContent}
              {pagination}
            </Container>
          </GridItem>
        </GridItem>
      </GridContainer>
    );
  }
  return content;
}

GroupMembers.prototype = {
  onError: PropTypes.func.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
