import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

function SwitchablePassword(props) {
  const { label, onChange, value, required, disabled } = props;
  const [visiable, setVisible] = React.useState(false);
  const handleVisibleChanged = () => {
    setVisible(!visiable);
  };

  const adornment = (
    <InputAdornment position="end">
      <IconButton onClick={handleVisibleChanged} edge="end" size="large">
        {visiable ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
  return (
    <TextField
      label={label}
      onChange={onChange}
      value={value}
      type={visiable ? "text" : "password"}
      margin="normal"
      variant="standard"
      required={required}
      disabled={disabled}
      InputProps={{
        endAdornment: adornment,
      }}
      fullWidth
    />
  );
}

SwitchablePassword.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export function InputComponent(props) {
  let {
    type,
    label,
    value,
    onChange,
    required,
    oneRow,
    disabled,
    options,
    on,
    off,
    rows,
    step,
    maxStep,
    minStep,
    marks,
    helper,
    horizontal,
    ...rest
  } = props;
  let component;  
  let display = '';
  switch (type) {
    case "text":
      component = (
        <Box m={0} pt={2}>
          <TextField
            label={label}
            onChange={onChange}
            value={value}
            margin="normal"
            variant="standard"
            required={required}
            disabled={disabled}
            helperText={helper}
            fullWidth
          />
        </Box>
      );
      break;
    case "password":
      component = (
        <Box m={0} pt={2}>
          <SwitchablePassword
            label={label}
            onChange={onChange}
            value={value}
            required={required}
            disabled={disabled}
          />
        </Box>
      );
      break;
    case "file":
      component = (
        <Box m={0} pt={2}>
          <TextField
            label={label}
            onChange={onChange}
            type="file"
            margin="normal"
            required={required}
            disabled={disabled}
            fullWidth
          />
        </Box>
      );
      break;
    case "textarea":
      // let { rows } = props;
      if (rows < 2) {
        rows = 3;
      }
      component = (
        <Box m={0} pt={2}>
          <TextField
            label={label}
            onChange={onChange}
            value={value}
            margin="normal"
            required={required}
            disabled={disabled}
            maxRows={rows}
            multiline
            fullWidth
          />
        </Box>
      );
      break;
    case "select":
      component = (
        <Box m={0} pt={2}>
          <InputLabel>{label}</InputLabel>
          <Select
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            variant="standard"
            fullWidth
          >
            {options.map((option, key) => {
              const { value, disabled, label } = option
              return (
                <MenuItem
                  value={value}
                  key={key}
                  disabled={disabled}
                  >
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      );
      break;
    case "radio":
      if (horizontal){
        display = 'flex';
      }
      component = (
        <Box m={0} pt={2}>
          <FormControl component="fieldset" fullWidth disabled={disabled}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup name="type" value={value} onChange={onChange} row>
              <Box display={display} alignItems="center">
                {options.map((option, key) => (
                  <Box key={key}>
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      );
      break;
    case "switch":
      // const { on, off } = props;
      component = (
        <Grid item>
          <Typography variant="caption">
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              m: 0,
              p: 0,
            }}>
              <Box sx={{pr: 2,}}><InputLabel variant='standard'>{label}</InputLabel>
              </Box>
              <Box>{off}
              </Box>
              <Box><Switch checked={value} onChange={onChange} color="primary" />
              </Box>
              <Box>{on}
              </Box>
            </Box>
          </Typography>
        </Grid>
      );
      break;
    case "checkbox":
      component = (
        <Box m={0} pt={2}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup>
              <Grid container>
                {options.map((option, key) => {
                  const tagValue = option.value;
                  const tagLabel = option.label;
                  let checked;
                  if (value.has(tagValue)) {
                    checked = value.get(tagValue);
                  } else {
                    checked = false;
                  }
                  return (
                    <Grid item xs={6} sm={3} key={key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={onChange(tagValue)}
                            value={tagValue}
                          />
                        }
                        label={tagLabel}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
          </FormControl>
        </Box>
      );
      break;
    case "slider":
      // const { step, maxStep, minStep, marks } = props;
      component = (
        <Box m={0} pt={2}>
          <FormLabel component="legend">{label}</FormLabel>
          <Slider
            color="secondary"
            value={value}
            max={maxStep}
            min={minStep}
            step={step}
            valueLabelDisplay="auto"
            marks={marks}
            onChange={onChange}
          />
        </Box>
      );
      break;
    default:
      return <div />;
  }
  let container = (
    <Grid item {...rest}>
      {component}
    </Grid>
  );

  if (oneRow) {
    return (
      <Grid item xs={12}>
        {container}
      </Grid>
    );
  } else {
    return container;
  }
}

InputComponent.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "password",
    "textarea",
    "file",
    "select",
    "radio",
    "switch",
    "checkbox",
    "slider",
  ]).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  oneRow: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  on: PropTypes.string,
  off: PropTypes.string,
  rows: PropTypes.number,
  step: PropTypes.number,
  maxStep: PropTypes.number,
  minStep: PropTypes.number,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  helper: PropTypes.string,
  horizontal: PropTypes.bool,
};

export default function InputList(props) {
  const { inputs } = props;
  return (
    <Grid container>
      {inputs.map((input, key) => (
        <InputComponent key={key} {...input} />
      ))}
    </Grid>
  );
}

InputList.propTypes = {
  // inputs: PropTypes.arrayOf(PropTypes.shape(InputComponent)).isRequired,
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
