import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import BlockList from "views/Blocks/BlockList";
import BlockDetail from "views/Blocks/BlockDetail";
import TransactionList from "views/Blocks/TransactionList";
import TransactionDetail from "views/Blocks/TransactionDetail";
import MustInitialized from "views/Login/MustInitialized";

export default function Blocks(properties) {
  const component = (
    <div>
      <Route
        path="/admin/blocks/"
        exact
        render={() => React.createElement(BlockList, properties)}
      />
      <Route
        path="/admin/blocks/:block"
        exact
        render={(current) =>
          React.createElement(BlockDetail, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/blocks/:block/transactions/"
        exact
        render={(current) =>
          React.createElement(TransactionList, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/blocks/:block/transactions/:id"
        exact
        render={(current) =>
          React.createElement(TransactionDetail, {
            ...properties,
            ...current,
          })
        }
      />
    </div>
  );
  return <MustInitialized lang={properties.lang} component={component} />;
}

Blocks.prototype = {
  lang: PropTypes.string.isRequired,
};
