import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ListIcon from "@mui/icons-material/List";

// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "components/CustomButtons/IconButton";
import { getBlock } from "backend_api.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Detail of Block: ",
    back: "Back",
    listButton: "Block List",
    id: "Block ID",
    genesis: "Genesis Block",
    create: "Generate",
    status: "Block Status",
    tagGenerated: "Generated: ",
    signatured: "Signatured: ",
    endorsed: "Endorsed: ",
    previousBlock: "Previous Block",
    height: "Block Height",
    transaction: "Total Transaction",
    root: "Merkle Root",
    endorsement: "Endorse Peers",
    view: "View",
  },
  cn: {
    title: "区块详情： ",
    back: "返回",
    listButton: "区块列表",
    id: "区块ID",
    genesis: "创世区块",
    create: "生成",
    status: "区块状态",
    tagGenerated: "已生成: ",
    signatured: "已签名： ",
    endorsed: "已背书: ",
    previousBlock: "上一区块",
    height: "区块高度",
    transaction: "交易数量",
    root: "默克尔根",
    invoker: "生成节点",
    hashMethod: "哈希方法",
    signature: "数字签名",
    signatureMethod: "签名算法",
    endorsement: "背书节点",
    view: "查看",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function BlockDetail(props) {
  const blockID = props.match.params.block;
  const [detailData, setDetailData] = React.useState(null);

  const showErrorMessage = React.useCallback((msg) => {
    toast.error(msg);
  }, []);

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const reloadDetail = React.useCallback(() => {
    const onGetDetailSuccess = (data) => {
      setDetailData(data);
    };
    getBlock(
      defaultChainName,
      defaultDomainName,
      blockID,
      onGetDetailSuccess,
      onFail
    );
  }, [onFail]);

  React.useEffect(() => {
    reloadDetail();
  }, [reloadDetail]);

  const { lang } = props;
  const texts = i18n[lang];
  let content, title;
  let buttons = [];
  if (null === detailData) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let transData = JSON.parse(detailData.content);
    let tableData;
    if (detailData.previous_block) {
      tableData = [
        {
          title: texts.id,
          value: detailData.id,
        },
        {
          title: texts.previousBlock,
          value: (
            <Link href={"/admin/blocks/" + detailData.previous_block}>
              {detailData.previous_block}
            </Link>
          ),
        },
      ];
    } else {
      tableData = [
        {
          title: texts.genesis,
          value: detailData.id,
        },
      ];
    }

    let createdTime = new Date(detailData.timestamp).toLocaleString();
    let createValue;
    if ("cn" === lang) {
      createValue =
        "由节点 " + transData.invoker + " 在 " + createdTime + " 创建";
    } else {
      createValue = transData.invoker + " created at " + createdTime;
    }

    let statusChips = [
      <Tooltip title={texts.root + ": " + transData.root} key="validated">
        <Chip
          label={texts.tagGenerated + transData.hash_method}
          color="success"
        />
      </Tooltip>,
    ];

    if (transData.signature) {
      statusChips.push(
        <Tooltip
          title={transData.signature_method + ": " + transData.signature}
          key="signature"
        >
          <Chip
            label={texts.signatured + transData.signature_method}
            color="info"
          />
        </Tooltip>
      );
    }
    let transactions;
    if (detailData.transactions && 0 !== detailData.transactions) {
      transactions = (
        <Typography variant="body2">
          <Link
            href={"/admin/blocks/" + detailData.id + "/transactions/"}
            underline="always"
          >
            {detailData.transactions.toString()}
          </Link>
        </Typography>
      );
    } else {
      transactions = (
        <Typography variant="body2">
          {detailData.transactions.toString()}
        </Typography>
      );
    }
    tableData.push(
      {
        title: texts.create,
        value: createValue,
      },
      {
        title: texts.status,
        value: (
          <Stack direction="row" spacing={2}>
            {statusChips}
          </Stack>
        ),
      },
      {
        title: texts.height,
        value: detailData.height,
      },
      {
        title: texts.transaction,
        value: transactions,
      }
    );

    let endorsers = [];
    transData.endorsements.forEach(({ endorser, signature }) => {
      endorsers.push(
        <Tooltip
          title={transData.signature_method + ": " + signature}
          key={endorser}
        >
          <Chip label={texts.endorsed + endorser} color="info" />
        </Tooltip>
      );
    });

    tableData.push({
      title: texts.endorsement,
      value: (
        <Stack direction="row" spacing={2}>
          {endorsers}
        </Stack>
      ),
    });

    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>
                    {row.operators
                      ? row.operators.map(({ label, icon, onClick }, key) => (
                          <IconButton key={key} label={label} icon={icon} onClick={onClick} size="large" />
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );

    title = texts.title + detailData.id;
    buttons = [
      <Button
        key="list"
        size="sm"
        color="info"
        round
        onClick={() => {
          const listURL = "/admin/blocks/";
          window.location.assign(listURL);
        }}
      >
        <ListIcon />
        {texts.listButton}
      </Button>,
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={() => {
          props.history.goBack();
        }}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

BlockDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
