import React from "react";
import PropTypes from "prop-types";
import MainTabs from "views/Access/MainTabs";
import MustInitialized from "views/Login/MustInitialized";

export default function AccessRoutes(properties) {
  const component = <MainTabs lang={properties.lang} />;
  return <MustInitialized lang={properties.lang} component={component} />;
}

AccessRoutes.prototype = {
  lang: PropTypes.string.isRequired,
};
