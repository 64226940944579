import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import EditIcon from "@mui/icons-material/Edit";

// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import IconButton from "components/CustomButtons/IconButton";
import { InputComponent } from "components/CustomInput/InputList";
import {
  getSchema,
  getDocument,
  updateDocument,
  updateDocumentProperty,
  writeLog,
} from "backend_api.js";
import { convertToPropertyValue, propertyTypeName} from "utils.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Modify Digital Asset:",
    back: "Back",
    propertyName: "Property Name",
    propertyType: "Property Type",
    propertyValue: "Property Value",
    operates: "Operates",
    submitButton: "Submit",
    updateButton: "Update Property Value",
  },
  cn: {
    title: "编辑数字资产：",
    back: "返回",
    propertyName: "属性名",
    propertyType: "属性类型",
    propertyValue: "属性值",
    operates: "操作",
    submitButton: "提交变更",
    updateButton: "更新属性值",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function ModifyDocument(props) {
  const schemaName = props.match.params.schema;
  const docID = props.match.params.id;
  const { lang } = props;
  const texts = i18n[lang];
  const [schema, setSchema] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.info(msg);
  };

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const updateProperty = (index) => {
    if (!mounted) {
      return;
    }
    if (index >= data.values.length) {
      onFail(
        "value index overflow " +
          index.toString() +
          " > " +
          data.values.length.toString()
      );
      return;
    }
    let valueType = schema.properties[index].type;
    let propertyName = schema.properties[index].name;
    let value = convertToPropertyValue(data.values[index], valueType);
    if (null === value) {
      onFail(
        "invalid value of property " + propertyName + " : " + data.values[index]
      );
      return;
    }

    const onUpdateSuccess = () => {
      showNotifyMessage("property " + propertyName + " updated");
    };

    updateDocumentProperty(
      defaultChainName,
      defaultDomainName,
      schemaName,
      docID,
      propertyName,
      valueType,
      value,
      onUpdateSuccess,
      onFail
    );
  };

  const handlePropertyValueChanged = (index) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let value = e.target.value;
      if (index >= data.values.length) {
        onFail(
          "update value overflow " +
            index.toString() +
            " > " +
            data.values.length.toString()
        );
        return;
      }
      let newValues = data.values;
      newValues[index] = value;
      setData((previous) => ({
        ...previous,
        values: newValues,
      }));
    };
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified asset " + schemaName + "." + docID);
      const listURL = "/admin/assets/schemas/" + schemaName + "/docs/";
      window.location.assign(listURL);
    };

    let propertyCount = schema.properties.length;
    if (propertyCount !== data.values.length) {
      onFail(
        "unexpected value count: " +
          data.values.length.toString() +
          " => " +
          propertyCount.toString()
      );
      return;
    }
    let doc = new Object();
    for (let offset = 0; offset < propertyCount; offset++) {
      let valueType = schema.properties[offset].type;
      let propertyName = schema.properties[offset].name;
      let value = convertToPropertyValue(data.values[offset], valueType);
      if (null === value) {
        onFail(
          "invalid value of property " +
            propertyName +
            ":" +
            data.values[offset]
        );
        return;
      }
      doc[propertyName] = value;
    }
    let content = JSON.stringify(doc);
    updateDocument(
      defaultChainName,
      defaultDomainName,
      schemaName,
      docID,
      content,
      onOperateSuccess,
      onFail
    );
  };

  const dataToNodes = (index, buttons) => {
    const operates = buttons.map((button, key) =>
      React.createElement(IconButton, {
        ...button,
        color: "secondary",
        key: key,
      })
    );
    let valueType = schema.properties[index].type;
    let propertyName = schema.properties[index].name;
    let valueTag = propertyTypeName(lang, valueType);
    let inputName = (
      <InputComponent
        type="text"
        label={texts.propertyValue}
        value={data.values[index]}
        required={true}
        xs={6}
        onChange={handlePropertyValueChanged(index)}
      />
    );
    return [propertyName, valueTag, inputName, operates];
  };

  React.useEffect(() => {
    setMounted(true);

    let schema;

    const onGetDocSuccess = ({ content }) => {
      let doc = JSON.parse(content);
      let propertyCount = schema.properties.length;
      let initialValues = [];
      for (let offset = 0; offset < propertyCount; offset++) {
        let propertyName = schema.properties[offset].name;
        initialValues.push(doc[propertyName].toString());
      }
      setData({
        values: initialValues,
      });
      setSchema(schema);
      setInitialized(true);
    };

    const onGetSchemaSuccess = (data) => {
      if (!mounted) {
        return;
      }
      schema = data;
      getDocument(
        defaultChainName,
        defaultDomainName,
        schemaName,
        docID,
        onGetDocSuccess,
        onFail
      );
    };

    getSchema(
      defaultChainName,
      defaultDomainName,
      schemaName,
      onGetSchemaSuccess,
      onFail
    );

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, schemaName, docID, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let rows = [];
    const propertyCount = data.values.length;
    for (let offset = 0; offset < propertyCount; offset++) {
      const index = offset;
      const buttons = [
        {
          onClick: () => updateProperty(index),
          icon: EditIcon,
          label: texts.updateButton,
        },
      ];
      rows.push(dataToNodes(index, buttons));
    }

    content = (
      <div>
        <Table
          color="primary"
          headers={[
            texts.propertyName,
            texts.propertyType,
            texts.propertyValue,
            texts.operates,
          ]}
          rows={rows}
        />
      </div>
    );

    title = texts.title + " " + schemaName + "." + docID;
    buttons = [
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={() => {
          const listURL = "/admin/assets/schemas/" + schemaName + "/docs/";
          window.location.assign(listURL);
          // props.history.goBack();
        }}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}></Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ModifyDocument.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
