import React from "react";
import PropTypes from "prop-types";
import InputList from "components/CustomInput/InputList";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { changeManagerPassword, writeLog } from "backend_api.js";

const i18n = {
  en: {
    title: "Modify Password",
    current: "Current Password",
    new: "New Password",
    confirmNew: "Confirm New Password",
    cancel: "Cancel",
    confirm: "Confirm",
    promptEmptyCurrent: "Input Current Password",
    promptEmptyNew: "Input New Password",
    promptMismatch: "Two Password Mismatched",
    promptWeak:
      "Strong password required, A minimum of 8 characters, a combination of letters(both of uppercase and lowercase required), numbers, and symbols.",
  },
  cn: {
    title: "修改密码",
    current: "当前密码",
    new: "新密码",
    confirmNew: "确认新密码",
    cancel: "取消",
    confirm: "确认",
    promptEmptyCurrent: "请输入当前密码",
    promptEmptyNew: "请输入新密码",
    promptMismatch: "两个新密码必须一致",
    promptWeak: "密码长度至少8个字符，必须包含大小写字母、数字和特殊字符",
  },
};

export default function ModifyPasswordDialog(props) {
  const defaultValues = {
    old: "",
    new: "",
    new2: "",
  };
  const { lang, user, open, onSuccess, onCancel } = props;
  const [operatable, setOperatable] = React.useState(true);
  const [mounted, setMounted] = React.useState(false);
  const [prompt, setPrompt] = React.useState("");
  const [request, setRequest] = React.useState(defaultValues);

  const texts = i18n[lang];
  const title = texts.title;

  const resetDialog = () => {
    setPrompt("");
    setRequest(defaultValues);
  };

  const closeDialog = () => {
    resetDialog();
    onCancel();
  };

  const onModifyFail = (msg) => {
    if (!mounted) {
      return;
    }
    setOperatable(true);
    setPrompt(msg);
  };

  const onModifySuccess = () => {
    writeLog("changed password of " + user);
    if (!mounted) {
      return;
    }
    resetDialog();
    setOperatable(true);
    onSuccess();
  };

  const handleConfirm = () => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    setPrompt("");
    setOperatable(false);
    if ("" === request.old) {
      onModifyFail(texts.promptEmptyCurrent);
      return;
    }
    if ("" === request.new) {
      onModifyFail(texts.promptEmptyNew);
      return;
    }
    if (request.new2 !== request.new) {
      onModifyFail(texts.promptMismatch);
      return;
    }
    if (!strongRegex.test(request.new)) {
      onModifyFail(texts.promptWeak);
      return;
    }
    changeManagerPassword(
      user,
      request.old,
      request.new,
      onModifySuccess,
      onModifyFail
    );
  };

  const handleRequestPropsChanged = (name) => (e) => {
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  const inputs = [
    {
      type: "password",
      label: texts.current,
      onChange: handleRequestPropsChanged("old"),
      value: request.old,
      required: true,
      xs: 12,
    },
    {
      type: "password",
      label: texts.new,
      onChange: handleRequestPropsChanged("new"),
      value: request.new,
      required: true,
      xs: 12,
    },
    {
      type: "password",
      label: texts.confirmNew,
      onChange: handleRequestPropsChanged("new2"),
      value: request.new2,
      required: true,
      xs: 12,
    },
  ];
  const content = <InputList inputs={inputs} />;

  const buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: closeDialog,
    },
    {
      color: "info",
      label: texts.confirm,
      onClick: handleConfirm,
    },
  ];

  return (
    <CustomDialog
      size="sm"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={operatable}
    />
  );
}

ModifyPasswordDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
