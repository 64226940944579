import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import EditIcon from "@mui/icons-material/Edit";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/ObjectTable.js";
import IconButton from "components/CustomButtons/IconButton";
import ModifyStepDialog from "views/Contracts/ModifyStepDialog";
import AddStepDialog from "views/Contracts/AddStepDialog";
import ActionDescription from "views/Contracts/ActionDescription";
import { InputComponent } from "components/CustomInput/InputList";
import { getContract, deployContract, writeLog } from "backend_api";
import { getActionDefines } from "contract_define";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    tableTitle: "Modify Contract: ",
    segmentInput: "Input Parameters",
    name: "Parameter Name",
    description: "Description",
    addParameter: "Add",
    removeParameter: "Remove",
    promptNoParameters: "No parameter configured, click to add",
    promptNoStep: "Contract steps required",
    promptInvalidName: "Invalid name on parameter ",
    promptEmptyName: "Empty name on parameter ",
    promptDuplicateName: "Parameter duplicated:",
    segmentSteps: "Steps",
    step: "Step",
    action: "Action",
    parameters: "Parameters",
    noRecord: "No step available",
    operates: "Operates",
    rawMode: "Raw Mode",
    tagOn: "On",
    tagOff: "Off",
    submitButton: "Submit",
    addButton: "Add Step",
    removeButton: "Remove Step",
    modifyButton: "Modify Step",
    moveUpButton: "Move Up",
    moveDownButton: "Move Down",
  },
  cn: {
    back: "返回",
    tableTitle: "编辑合约: ",
    segmentInput: "调用参数",
    name: "参数名",
    description: "描述",
    addParameter: "添加",
    removeParameter: "删除",
    promptNoParameters: "未配置调用参数，点击添加",
    promptNoStep: "必须配置合约步骤",
    promptInvalidName: "无效名称: 参数",
    promptEmptyName: "空参数名：参数",
    promptDuplicateName: "参数已经存在：",
    segmentSteps: "合约步骤",
    step: "步骤",
    action: "动作",
    parameters: "参数",
    noRecord: "合约无步骤，请新增",
    operates: "操作",
    rawMode: "源码模式",
    tagOn: "打开",
    tagOff: "关闭",
    submitButton: "提交变更",
    addButton: "添加步骤",
    removeButton: "删除步骤",
    modifyButton: "修改步骤",
    moveUpButton: "上移",
    moveDownButton: "下移",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function ModifyContract(props) {
  const contractName = props.match.params.contract;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [request, setRequest] = React.useState(null);
  const [rawMode, setRawMode] = React.useState(false);
  const [dialog, setDialog] = React.useState(null);

  const actionDefines = getActionDefines(lang);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.success(msg);
  };

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleModeChanged = (e) => {
    let checked = e.target.checked;
    setRawMode(checked);
  };

  const moveUp = (index) => {
    if (!mounted) {
      return;
    }
    if (request.steps && request.steps[index - 1]) {
      let steps = request.steps;
      let previous = steps[index - 1];
      steps.splice(index - 1, 1);
      steps.splice(index, 0, previous); //insert
      setRequest((previous) => ({
        ...previous,
        steps: steps,
      }));
    }
  };

  const moveDown = (index) => {
    if (!mounted) {
      return;
    }

    if (request.steps && request.steps[index + 1]) {
      let steps = request.steps;
      let current = steps[index];
      steps.splice(index, 1);
      steps.splice(index + 1, 0, current); //insert
      setRequest((previous) => ({
        ...previous,
        steps: steps,
      }));
    }
  };

  const removeStep = (index) => {
    if (!mounted) {
      return;
    }

    if (request.steps && request.steps[index]) {
      let steps = request.steps;
      steps.splice(index, 1);
      setRequest((previous) => ({
        ...previous,
        steps: steps,
      }));
      showNotifyMessage("step " + (index + 1).toString() + " removed");
    }
  };

  const closeDialog = () => {
    setDialog(null);
  };

  const addStep = () => {
    let dialogInfo = {
      mode: "add",
    };
    setDialog(dialogInfo);
  };

  const onStepChanged = (index, step) => {
    if (!mounted) {
      return;
    }
    if (index >= request.steps.length) {
      onFail("index " + index.toString() + " overflow");
      return;
    }
    let steps = request.steps;
    steps[index] = step;
    setRequest((previous) => ({
      ...previous,
      steps: steps,
    }));
    closeDialog();
    showNotifyMessage(
      "step " + (index + 1).toString() + " changed to " + step.action
    );
  };

  const modifyStep = (index) => {
    if (index >= request.steps) {
      onFail("step index overflow: " + index.toString());
      return;
    }
    let dialogInfo = {
      mode: "modify",
      index: index,
    };
    setDialog(dialogInfo);
  };

  const onStepAdded = (step) => {
    if (!mounted) {
      return;
    }
    let steps = request.steps;
    steps.push(step);
    setRequest((previous) => ({
      ...previous,
      steps: steps,
    }));
    closeDialog();
    showNotifyMessage("new step appended with action " + step.action);
  };

  const addParameter = () => {
    let parameter = {
      name: "",
      description: "",
    };
    let parameters = request.parameters;
    parameters.push(parameter);
    setRequest((previous) => ({
      ...previous,
      parameters: parameters,
    }));
  };

  const removeParameter = (index) => {
    if (!mounted) {
      return;
    }
    if (!request.parameters) {
      onFail("no parameter available");
      return;
    }
    if (index >= request.parameters.length) {
      onFail("parameter overflow: " + index.toString());
      return;
    }
    let parameters = request.parameters;
    parameters.splice(index, 1);
    setRequest((previous) => ({
      ...previous,
      parameters: parameters,
    }));
  };

  const handleTextChanged = (index, label) => (e) => {
    if (!mounted) {
      return;
    }
    let value = e.target.value;
    if (!request.parameters) {
      onFail("no parameter available");
      return;
    }
    if (index >= request.parameters.length) {
      onFail("parameter overflow: " + index.toString());
      return;
    }
    let parameters = request.parameters;
    let current = parameters[index];
    current[label] = value;
    parameters[index] = current;
    setRequest((previous) => ({
      ...previous,
      parameters: parameters,
    }));
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { content } = result;
      let contentObject = JSON.parse(content);
      if (!contentObject.parameters) {
        contentObject.parameters = [];
      }
      setRequest(contentObject);
      setInitialized(true);
    };

    getContract(chainName, domainName, contractName, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, contractName, chainName, mounted]);

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified contract " + contractName);
      const listURL = "/admin/contracts/";
      window.location.assign(listURL);
    };

    if (request.parameters && 0 !== request.parameters.length) {
      let wordOnly = new RegExp("\\W");
      let names = new Set();
      if (
        !request.parameters.every(({ name }, index) => {
          if (!name) {
            onFail(texts.promptEmptyName + index.toString());
            return false;
          }
          if (wordOnly.test(name)) {
            onFail(texts.promptInvalidName + index.toString());
            return false;
          }
          if (names.has(name)) {
            onFail(texts.promptDuplicateName + name);
            return false;
          }
          names.add(name);
          return true;
        })
      ) {
        return;
      }
    } else {
      request.parameters = [];
    }

    if (!request.steps || 0 === request.steps.length) {
      onFail(texts.promptNoStep);
      return;
    }
    let content = JSON.stringify(request);

    deployContract(
      defaultChainName,
      defaultDomainName,
      contractName,
      content,
      onOperateSuccess,
      onFail
    );
  };

  const dataToNodes = (index, data, buttons) => {
    const operates = buttons.map((button, key) =>
      React.createElement(IconButton, {
        ...button,
        color: "secondary",
        key: key,
      })
    );
    const { action, params } = data;
    const step = (index + 1).toString();
    let actionName = "";
    let actionContent = "";
    if (!rawMode) {
      actionName = actionDefines[action].name;
      actionContent = (
        <ActionDescription
          lang={lang}
          parameters={params}
          inputs={request.parameters}
          action={action}
        />
      );
    } else {
      actionName = action;
      if (params && 0 !== params.length) {
        actionContent = "[ '" + params.join("', '") + "' ]";
      }
    }
    return [step, actionName, actionContent, operates];
  };

  const mapParameters = (parameter, index) => {
    const { name, description } = parameter;
    return [
      <GridItem key="name" xs={4} sm={3} md={2}>
        <TextField
          label={texts.name}
          onChange={handleTextChanged(index, "name")}
          value={name}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </GridItem>,
      <GridItem key="description" xs={6} sm={5} md={4}>
        <TextField
          label={texts.description}
          onChange={handleTextChanged(index, "description")}
          value={description}
          margin="normal"
          variant="outlined"
          fullWidth
        />
      </GridItem>,
      <GridItem
        key="remove"
        xs={2}
        sm={4}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box>
          <IconButton
            color="secondary"
            label={texts.removeParameter}
            icon={RemoveIcon}
            onClick={() => removeParameter(index)}
            size="large" />
        </Box>
      </GridItem>,
    ];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering

  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let parameterList;
    const addParamButton = (
      <IconButton
        label={texts.addParameter}
        icon={AddIcon}
        color="secondary"
        onClick={() => addParameter()}
        key="add"
        size="large" />
    );
    if (!request.parameters || 0 === request.parameters.length) {
      parameterList = (
        <Box sx={{ m: 2, pl: 1 }}>
          <Typography variant="body2">
            {texts.promptNoParameters}
            {addParamButton}
          </Typography>
        </Box>
      );
    } else {
      let parameterRows = request.parameters.map((parameter, index) =>
        mapParameters(parameter, index)
      );

      parameterList = (
        <Box sx={{ m: 2 }}>
          <GridContainer>{parameterRows}</GridContainer>
          {addParamButton}
        </Box>
      );
    }

    let tableContent;
    let rows = [];
    if (!request || !request.steps || 0 === request.steps.length) {
      rows.push([
        "",
        <Box display="flex" justifyContent="center" key="none">
          <Info>{texts.noRecord}</Info>
        </Box>,
        "",
        "",
      ]);
    } else {
      let recordCount = request.steps.length;
      request.steps.forEach((record, index) => {
        let buttons = [];
        if (recordCount > 1) {
          //2 properties at least
          if (0 !== index) {
            //up
            buttons.push({
              onClick: () => moveUp(index),
              icon: ArrowUpwardIcon,
              label: texts.moveUpButton,
            });
          }
          if (recordCount !== index + 1) {
            //down
            buttons.push({
              onClick: () => moveDown(index),
              icon: ArrowDownwardIcon,
              label: texts.moveDownButton,
            });
          }
        }
        buttons.push({
          onClick: () => modifyStep(index),
          icon: EditIcon,
          label: texts.modifyButton,
        });
        buttons.push({
          onClick: () => removeStep(index),
          icon: RemoveIcon,
          label: texts.removeButton,
        });
        rows.push(dataToNodes(index, record, buttons));
      });
    }
    let addButton = (
      <IconButton
        label={texts.addButton}
        icon={AddIcon}
        color="primary"
        onClick={addStep}
        key="add"
        size="large" />
    );
    tableContent = (
      <div>
        <Table
          color="primary"
          headers={[texts.step, texts.action, texts.parameters, texts.operates]}
          rows={rows}
        />
      </div>
    );
    const rawComponent = (
      <InputComponent
        key="rawMode"
        type="switch"
        label={texts.rawMode}
        value={rawMode}
        on={texts.tagOn}
        off={texts.tagOff}
        onChange={handleModeChanged}
      />
    );
    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {texts.tableTitle + contractName}
          </h4>
        </CardHeader>
        <CardBody>
          <Stack divider={<Divider />} spacing={2}>
            <Box>
              <Typography variant="subtitle1">{texts.segmentInput}</Typography>
              {parameterList}
            </Box>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    {texts.segmentSteps}
                  </Typography>
                </Box>
                <Box sx={{ pr: 10, mr: 1 }}>{rawComponent}</Box>
              </Box>
              <Box sx={{ m: 2 }}>
                {tableContent}
                {addButton}
              </Box>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={() => {
        props.history.goBack();
      }}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
  ];

  let dialogElement, step, index;
  if (!dialog) {
    dialogElement = <div />;
  } else {
    switch (dialog.mode) {
      case "modify":
        index = dialog.index;
        if (index >= request.steps) {
          onFail("step index overflow: " + index.toString());
          return;
        }
        step = request.steps[index];
        dialogElement = React.createElement(ModifyStepDialog, {
          lang: lang,
          open: true,
          index: index,
          current: step,
          onSuccess: onStepChanged,
          onCancel: closeDialog,
        });
        break;
      case "add":
        dialogElement = React.createElement(AddStepDialog, {
          lang: lang,
          open: true,
          onSuccess: onStepAdded,
          onCancel: closeDialog,
        });
        break;
      default:
        onFail("invalid dialog mode: " + dialog.mode);
        break;
    }
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} m={1}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
      {dialogElement}
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ModifyContract.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
