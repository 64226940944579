import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Skeleton from "@mui/material/Skeleton";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardContainer from "components/Card/CardContainer";
import { InputComponent } from "components/CustomInput/InputList";
import { createApplication, queryApplicationGroups, writeLog } from "backend_api.js";

const i18n = {
  en: {
    title: "Create Chain Application",
    back: "Back",
    name: "Application Name",
    group: "Application Group",
    submitButton: "Submit",
  },
  cn: {
    title: "创建链应用",
    back: "返回",
    name: "应用名称",
    group: "所属应用组",
    submitButton: "创建",
  },
};

export default function CreateApplication(props) {
  const { lang, onList } = props;
  const texts = i18n[lang];
  const defaultValues = {
    name: "",
    group: '',
    allGroups: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleNameChanged = (e) => {
    let name = e.target.value;
    setRequest((previous) => ({
      ...previous,
      name: name,
    }));
  };

  const handleGroupChanged = (e) => {
    let group = e.target.value;
    setRequest((previous) => ({
      ...previous,
      group: group,
    }));
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = ({ id }) => {
      writeLog("creates application " + request.name + "(id: " + id + ")");
      toast.success("application " + request.name + " created");
      onList();
    };
    let wordOnly = new RegExp("\\W");
    if (!request.name) {
      onFail("application name required");
      return;
    } else if (wordOnly.test(request.name)) {
      onFail("only words allow in application name: " + request.name);
      return;
    }
    //format privileges
    if (!request.group){
      onFail("invalid application group");
      return;
    }
    createApplication(request.name, request.group, onOperateSuccess, onFail);
  };

  React.useEffect(() => {
    setMounted(true);

    const onQuerySuccess = ({ groups }) => {
      if (!mounted) {
        return;
      }
      setRequest({
        name: "",
        group: '',
        allGroups: groups,
      });
      setInitialized(true);
    };

    queryApplicationGroups(onQuerySuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, onFail]);

  if (!initialized) {
    return <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  }
  //rendering
  const nameInput = (
    <InputComponent
      type="text"
      label={texts.name}
      value={request.name}
      onChange={handleNameChanged}
      xs={8}
      md={6}
      lg={4}
      xl={2}
    />
  );

  let groupOptions = request.allGroups.map(group => {
    const {name, description} = group;
    return ({
      label: name + ' - ' + description,
      value: name,
    })
  });

  const groupComponent = (
    <InputComponent
      key="group"
      type="radio"
      label={texts.group}
      value={request.group}
      options={groupOptions}
      onChange={handleGroupChanged}
    />
  )

  let content = (
    <Stack divider={<Divider />} spacing={2}>
      <Box>{nameInput}</Box>
      <Box>
        {groupComponent}
      </Box>
    </Stack>
  );

  let title = texts.title;
  let buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={onList}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}></Box>
      </GridItem>
      <GridItem xs={12}>
        <CardContainer
          title={title}
          components={content}
        />
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

CreateApplication.propTypes = {
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
