import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "components/CustomButtons/IconButton";
import { getRole } from "backend_api.js";
import { getPrivilegeLabels } from "privileges";

const i18n = {
  en: {
    title: "Role - ",
    back: "Back",
    name: "Name",
    description: "Description",
    rolePrivileges: "Role Privileges",
    domainPrivileges: "Domain Privileges - ",
  },
  cn: {
    title: "角色 - ",
    back: "返回",
    name: "名称",
    description: "描述",
    rolePrivileges: "角色权限",
    domainPrivileges: "数据域权限 - ",
  },
};

export default function ViewRole(props) {
  const { lang, roleName, onList } = props;
  const texts = i18n[lang];
  const [detailData, setDetailData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const reloadDetail = React.useCallback(() => {
    const onGetDetailSuccess = (data) => {
      if (!mounted) {
        return;
      }
      setDetailData(data);
      setInitialized(true);
    };
    getRole(roleName, onGetDetailSuccess, onFail);
  }, [onFail, mounted]);

  React.useEffect(() => {
    setMounted(true);
    reloadDetail();
    return () => {
      setMounted(false);
    };
  }, [reloadDetail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const { name, description, actions, domain_privileges } = detailData;
    let tableData = [
      {
        title: texts.name,
        value: name,
      },
      {
        title: texts.description,
        value: description,
      },
    ];
    if (actions){
      let availablePrivileges = getPrivilegeLabels(lang, actions);
      tableData.push({
        title: texts.rolePrivileges,
        value: (
          <Typography variant="body2">
            <ul>
              {availablePrivileges.map((label, i) => (
                <li key={i}>{label}</li>
              ))}
            </ul>
          </Typography>
        ),
      });
    }

    if (domain_privileges){
      domain_privileges.forEach((item, i) => {
        const {name, actions} = item;
        if (actions){
          let availablePrivileges = getPrivilegeLabels(lang, actions);
          tableData.push({
            title: texts.domainPrivileges + name,
            value: (
              <Typography variant="body2" key={i}>
                <ul>
                  {availablePrivileges.map((label, i) => (
                    <li key={i}>{label}</li>
                  ))}
                </ul>
              </Typography>
            ),
          });
        }
      });
    }

    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>
                    {row.operators
                      ? row.operators.map(({ label, icon, onClick }, key) => (
                          <IconButton key={key} label={label} icon={icon} onClick={onClick} size="large" />
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );

    title = texts.title + roleName;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ViewRole.propTypes = {
  roleName: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
