import React from "react";
import PropTypes from "prop-types";
import ManagerList from "views/Access/ManagerList";
import ChangeManagerGroup from "views/Access/ChangeManagerGroup";
// import ModifyGroupRoles from "views/Access/ModifyGroupRoles";
import CreateManager from "views/Access/CreateManager";
// import GroupMembers from "views/Access/GroupMembers";
// import ViewRole from "views/Access/ViewRole";

export default function ManagerTab(props) {
  const DEFAULT_MODE = "list";
  const MODE_ADD = "add";
  const MODE_MODIFY = "modify";
  const [mode, setMode] = React.useState(DEFAULT_MODE);
  const [target, setTarget] = React.useState(null);

  const handleAdd = () => {
    setTarget("");
    setMode(MODE_ADD);
  };

  const handleModify = (managerID) => {
    setTarget(managerID);
    setMode(MODE_MODIFY);
  };

  const list = () => {
    setTarget("");
    setMode(DEFAULT_MODE);
  };

  switch (mode) {
    case MODE_ADD:
      return <CreateManager onList={list} {...props} />;
    case MODE_MODIFY:
      return (
        <ChangeManagerGroup onList={list} managerID={target} {...props} />
      );
    default:
      return (
        <ManagerList onAdd={handleAdd} onModify={handleModify} {...props} />
      );
  }
}

ManagerTab.prototype = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
