const PROJECT_NAME = "Taiyi";
const DEFAULT_CHAIN_NAME = PROJECT_NAME.toLowerCase();
const DEFAULT_SYSTEM_NAME = "system";

const API_HOST = '';
// const API_HOST = 'http://192.168.25.223:8090';
// const API_HOST = 'http://192.168.3.47:8090';

const materialKitTheme = {
  palette: {
    primary: {
      main: '#9c27b0',
    },
    secondary: {
      main: '#00acc1',
    },
    error: {
      main: '#f44336',
    },
  },
};

const MAIN_THEME = materialKitTheme;

export { PROJECT_NAME, DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME, API_HOST, MAIN_THEME };
