import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import InputList from "components/CustomInput/InputList";
import { getContract, testContract } from "backend_api";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    tableTitle: "Test Contract: ",
    segmentInput: "Input Parameters",
    segmentPlan: "Execute Plan",
    segmentOutput: "Output",
    value: "Parameter Values",
    promptInput: "Input parameters, divide by ','",
    promptEmptyValue: "Parameter is empty: ",
    promptInvalidValue: "Parameters is invalid: ",
    promptGenerating: "Generating...",
    promptTesting: "Testing...",
    testButton: "Test",
  },
  cn: {
    back: "返回",
    tableTitle: "测试智能合约: ",
    segmentInput: "输入参数",
    segmentPlan: "执行计划",
    segmentOutput: "输出",
    value: "参数值",
    promptInput: "输入参数，以','间隔",
    promptEmptyValue: "参数值不可为空：",
    promptInvalidValue: "无效参数: ",
    promptGenerating: "生成中...",
    promptTesting: "执行中...",
    testButton: "测试",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function TestContract(props) {
  const STAGE_IDLE = "idle";
  const STAGE_TEST = "test";
  const STAGE_COMPLETE = "complete";
  const contractName = props.match.params.contract;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [request, setRequest] = React.useState(null);
  const [inputList, setInputList] = React.useState(null);
  const [stage, setStage] = React.useState(STAGE_IDLE);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.success(msg);
  };

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleInputChanged = (e) => {
    if (!mounted) {
      return;
    }
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      input: value,
    }));
  };

  const handleParameterChanged = (index) => (e) => {
    if (!mounted) {
      return;
    }
    let value = e.target.value;
    if (!request.parameters) {
      onFail("no parameter available");
      return;
    }
    if (index >= request.parameters.length) {
      onFail("parameter overflow: " + index.toString());
      return;
    }
    let parameters = request.parameters;
    parameters[index] = value;
    setRequest((previous) => ({
      ...previous,
      parameters: parameters,
    }));
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { content } = result;
      let request = {
        input: "",
        stages: [],
        output: [],
        timestamp: "",
      };
      let contentObject = JSON.parse(content);
      if (contentObject.parameters) {
        setInputList(contentObject.parameters);
        let parameters = new Array(contentObject.parameters.length);
        parameters.fill("");
        request.parameters = parameters;
      }
      setRequest(request);
      setInitialized(true);
    };

    getContract(chainName, domainName, contractName, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, contractName, chainName, mounted]);

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = ({ stages, output }) => {
      setRequest((previous) => ({
        ...previous,
        stages: stages,
        output: output,
        timestamp: new Date().toLocaleString(),
      }));
      setStage(STAGE_COMPLETE);
      showNotifyMessage("Test result returned");
    };

    let parameters = [];
    if (inputList) {
      const inputCount = request.parameters.length;
      if (
        !inputList.every(({ name }, index) => {
          if (index >= inputCount) {
            onFail("input value overflow: " + index.toString());
            return false;
          }
          let value = request.parameters[index];
          if (!value) {
            onFail(texts.promptEmptyValue + name);
            return false;
          }
          value = value.trim();
          if (!value) {
            onFail(texts.promptEmptyValue + name);
            return false;
          }
          parameters.push(value);
          return true;
        })
      ) {
        return;
      }
    } else {
      //by single input
      if (request.input) {
        let values = request.input.split(",");
        if (
          !values.every((value, index) => {
            let parameter = value.trim();
            if (!parameter || "" === parameter) {
              onFail(texts.promptEmptyValue + index.toString());
              return false;
            }
            parameters.push(parameter);
            return true;
          })
        ) {
          return;
        }
      }
    }

    testContract(
      defaultChainName,
      defaultDomainName,
      contractName,
      parameters,
      onOperateSuccess,
      onFail
    );
    setStage(STAGE_TEST);
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let inputComponents;
    const testButton = (
      <Button onClick={handleConfirm} color="info" key="test">
        {texts.testButton}
        <GolfCourseIcon />
      </Button>
    );
    let inputs;
    if (!inputList || 0 === inputList.length) {
      inputs = [
        {
          type: "text",
          value: request.input,
          label: texts.value,
          helper: texts.promptInput,
          onChange: handleInputChanged,
          oneRow: true,
          xs: 6,
          sm: 4,
          md: 3,
        },
      ];
    } else {
      inputs = inputList.map(({ name, description }, index) => ({
        type: "text",
        value: request.parameters[index],
        label: name,
        helper: description,
        onChange: handleParameterChanged(index),
        oneRow: true,
        xs: 6,
        sm: 4,
        md: 3,
      }));
    }
    inputComponents = <InputList inputs={inputs} />;

    let planComponents, outputCompnonents;
    let planTitle = texts.segmentPlan;
    let outputTitle = texts.segmentOutput;
    switch (stage) {
      case STAGE_COMPLETE:
        planComponents = request.stages.map((line, index) => (
          <Typography key={index}>{line}</Typography>
        ));
        outputCompnonents = request.output.map((line, index) => (
          <Typography key={index}>{line}</Typography>
        ));
        planTitle = texts.segmentPlan + " (" + request.timestamp + ")";
        outputTitle = texts.segmentOutput + " (" + request.timestamp + ")";
        break;
      case STAGE_TEST:
        planComponents = <Typography>{texts.promptGenerating}</Typography>;
        outputCompnonents = <Typography>{texts.promptTesting}</Typography>;
        break;
      default:
        planComponents = <span />;
        outputCompnonents = <span />;
        break;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {texts.tableTitle + contractName}
          </h4>
        </CardHeader>
        <CardBody>
          <Stack divider={<Divider />} spacing={2} sx={{ m: 1 }}>
            <Box>
              <Typography variant="subtitle1">{texts.segmentInput}</Typography>
              <Box
                sx={{
                  m: 1,
                  pl: 3,
                }}
              >
                {inputComponents}
              </Box>
              {testButton}
            </Box>
            <Box>
              <Typography variant="subtitle1">{planTitle}</Typography>
              <Box
                sx={{
                  m: 1,
                  pl: 3,
                }}
              >
                {planComponents}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">{outputTitle}</Typography>
              <Box
                sx={{
                  m: 1,
                  pl: 3,
                }}
              >
                {outputCompnonents}
              </Box>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={() => {
        props.history.goBack();
      }}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} m={1}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

TestContract.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
