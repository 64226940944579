import React from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
// dashboard components
import InputList from "components/CustomInput/InputList";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { InputComponent } from "components/CustomInput/InputList";
import { createManager, queryGroups, writeLog } from "backend_api.js";

const i18n = {
  en: {
    title: "Create Manager",
    back: "Back",
    id: "Manager ID",
    password: "Input Password",
    confirm: "Confirm Password",
    group: "Group",
    name: "Name",
    description: "Description",
    submitButton: "Create",
    promptEmptyNew: "Input New Password",
    promptMismatch: "Two Password Mismatched",
    promptWeak:
      "Strong password required, A minimum of 8 characters, a combination of letters(both of uppercase and lowercase required), numbers, and symbols.",
    promptNoGroup: "Choose at least one group",
    promptEmptyID: "manger ID required",
    promptInvalidID: "only words allow in manager ID:",
  },
  cn: {
    title: "新建管理员",
    back: "返回",
    id: "管理员ID",
    password: "请输入密码",
    confirm: "确认密码",
    group: "用户组",
    name: "名称",
    description: "描述",
    submitButton: "创建",
    promptEmptyNew: "请输入新密码",
    promptMismatch: "两个新密码必须一致",
    promptWeak: "密码长度至少8个字符，必须包含大小写字母、数字和特殊字符",
    promptNoGroup: "请至少选择一个用户组",
    promptEmptyID: "管理员ID为空",
    promptInvalidID: "管理员ID仅允许字符数字与下划线:",
  },
};

export default function CreateManager(props) {
  const { lang, onList, onError, onSuccess } = props;
  const texts = i18n[lang];
  const defaultValues = {
    name: "",
    password: "",
    password2: "",
    group: '',
    availables: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const handleGroupChanged = (e) => {
    if (!mounted) {
      return;
    }
    let group = e.target.value;
    setRequest((previous) => ({
      ...previous,
      group: group,
    }));
  };

  const handleTextChanged = (fieldName) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let value = e.target.value;
      setRequest((previous) => ({
        ...previous,
        [fieldName]: value,
      }));
    };
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }
    let managerID;
    const onOperateSuccess = () => {
      writeLog("created manager " + managerID);
      onSuccess("manager " + managerID + " created");
      onList();
    };

    let wordOnly = new RegExp("\\W");
    if (!request.name) {
      onError(texts.promptEmptyID);
      return;
    } else if (wordOnly.test(request.name)) {
      onError(texts.promptInvalidID + request.name);
      return;
    }
    managerID = request.name;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if ("" === request.password) {
      onError(texts.promptEmptyNew);
      return;
    }
    if (request.password2 !== request.password) {
      onError(texts.promptMismatch);
      return;
    }
    if (!strongRegex.test(request.password)) {
      onError(texts.promptWeak);
      return;
    }

    if (!request.group) {
      onError("invalid application group");
      return;
    }

    createManager(
      managerID,
      request.password,
      request.group,
      onOperateSuccess,
      onError
    );
  };

  React.useEffect(() => {
    setMounted(true);

    const onQuerySuccess = ({ groups }) => {
      if (!mounted) {
        return;
      }
      let request = {
        name: "",
        password: "",
        password2: "",
        group: '',
        availables: groups,
      };
      setRequest(request);
      setInitialized(true);
    };

    queryGroups(onQuerySuccess, onError);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, onError]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let groupOptions = request.availables.map(({ name, description }) => {
      return ({
        label: name + ' - ' + description,
        value: name,
      })
    });

    const groupComponent = (
      <InputComponent
        key="group"
        type="radio"
        label={texts.group}
        value={request.group}
        options={groupOptions}
        onChange={handleGroupChanged}
      />
    )

    const inputs = [
      {
        type: "text",
        label: texts.name,
        onChange: handleTextChanged("name"),
        value: request.name,
        required: true,
        oneRow: true,
        xs: 8,
        sm: 6,
        md: 4,
      },
      {
        type: "password",
        label: texts.password,
        onChange: handleTextChanged("password"),
        value: request.password,
        required: true,
        oneRow: true,
        xs: 6,
        sm: 4,
        md: 3,
      },
      {
        type: "password",
        label: texts.confirm,
        onChange: handleTextChanged("password2"),
        value: request.password2,
        required: true,
        oneRow: true,
        xs: 6,
        sm: 4,
        md: 3,
      },
    ];

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Box
          sx={{
            m: 0,
            p: 3,
          }}
        >
          <InputList inputs={inputs} />
        </Box>
        <Box
          sx={{
            m: 1,
            p: 3,
          }}
        >
          <Typography>{texts.groups}</Typography>
          {groupComponent}
        </Box>
      </Stack>
    );

    title = texts.title;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

CreateManager.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
