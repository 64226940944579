import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import SchemaList from "views/Assets/SchemaList";
import SchemaLogs from "views/Assets/SchemaLogs";
import SchemaDetail from "views/Assets/SchemaDetail";
import CreateSchema from "views/Assets/CreateSchema";
import ModifySchema from "views/Assets/ModifySchema";
import SchemaActorList from "./SchemaActorList";
import DocumentList from "views/Assets/DocumentList";
import CreateDocument from "views/Assets/CreateDocument";
import ModifyDocument from "views/Assets/ModifyDocument";
import DocumentDetail from "views/Assets/DocumentDetail";
import DocumentLogs from "views/Assets/DocumentLogs";
import DocumentActorList from "./DocumentActorList";
import MustInitialized from "views/Login/MustInitialized";

export default function Assets(properties) {
  const component = (
    <div>
      <Route
        path="/admin/assets"
        exact
        render={() => React.createElement(SchemaList, properties)}
      />
      <Route
        path="/admin/assets/schemas/:schema/"
        exact
        render={(current) =>
          React.createElement(SchemaDetail, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/edit/"
        exact
        render={(current) =>
          React.createElement(ModifySchema, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/create_schema/"
        exact
        render={(current) =>
          React.createElement(CreateSchema, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/logs/"
        exact
        render={(current) =>
          React.createElement(SchemaLogs, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/docs/"
        exact
        render={(current) =>
          React.createElement(DocumentList, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/actors/"
        exact
        render={(current) =>
          React.createElement(SchemaActorList, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/create_doc/"
        exact
        render={(current) =>
          React.createElement(CreateDocument, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/docs/:id/"
        exact
        render={(current) =>
          React.createElement(DocumentDetail, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/docs/:id/edit/"
        exact
        render={(current) =>
          React.createElement(ModifyDocument, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/docs/:id/logs/"
        exact
        render={(current) =>
          React.createElement(DocumentLogs, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/assets/schemas/:schema/docs/:id/actors/"
        exact
        render={(current) =>
          React.createElement(DocumentActorList, {
            ...properties,
            ...current,
          })
        }
      />

    </div>
  );
  return <MustInitialized lang={properties.lang} component={component} />;
}

Assets.prototype = {
  lang: PropTypes.string.isRequired,
};
