import React from "react";
import { Route } from "react-router-dom";
import ChainList from "views/Chains/ChainList";
import PeerList from "views/Chains/PeerList";
import GatewayList from "views/Chains/GatewayList";
import DomainStatus from "views/Chains/DomainStatus";
import PeerLogs from "views/Chains/PeerLogs";
import GatewayLogs from "views/Chains/GatewayLogs";

export default function Chains(props) {
  return (
    <div>
      <Route
        path="/admin/chains"
        exact
        render={() => React.createElement(ChainList, props)}
      />
      <Route
        path="/admin/chains/:chain/peers/"
        exact
        render={(current) =>
          React.createElement(PeerList, {
            ...props,
            ...current,
          })
        }
      />
      <Route
        path="/admin/chains/:chain/peers/:peer/logs/"
        exact
        render={(current) =>
          React.createElement(PeerLogs, {
            ...props,
            ...current,
          })
        }
      />
      <Route
        path="/admin/chains/:chain/gateways/"
        exact
        render={(current) =>
          React.createElement(GatewayList, {
            ...props,
            ...current,
          })
        }
      />
      <Route
        path="/admin/chains/:chain/domains/:domain/status"
        exact
        render={(current) =>
          React.createElement(DomainStatus, {
            ...props,
            ...current,
          })
        }
      />
      <Route
        path="/admin/chains/:chain/gateways/:gateway/logs/"
        exact
        render={(current) =>
          React.createElement(GatewayLogs, {
            ...props,
            ...current,
          })
        }
      />
    </div>
  );
}
