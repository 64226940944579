import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { InputComponent } from "components/CustomInput/InputList";
import { modifyRole, getRole, writeLog } from "backend_api.js";
import {
  getRolePrivileges,
  getDomainPrivileges,
  parseRolePrivileges,
  parseDomainPrivileges,
} from "privileges";

const i18n = {
  en: {
    title: "Modify Role - ",
    back: "Back",
    name: "Role Name",
    description: "Description",
    rolePrivileges: "Role Privileges",
    domainPrivileges: "Domain Privileges - ",
    submitButton: "Submit",
  },
  cn: {
    title: "修改角色 - ",
    back: "返回",
    name: "角色名",
    description: "描述",
    rolePrivileges: "角色权限",
    domainPrivileges: "数据域权限 - ",
    submitButton: "提交变更",
  },
};

export default function ModifyRole(props) {
  const DEFAULT_DOMAIN_NAME = 'system';
  const { lang, roleName, onList } = props;
  const texts = i18n[lang];
  const rolePrivileges = getRolePrivileges(lang);
  const domainPrivileges = getDomainPrivileges(lang);
  const defaultValues = {
    description: "",
    domain_privileges: new Map(),
    actions: {},
    domainNames: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleDescriptionChanged = (e) => {
    let description = e.target.value;
    setRequest((previous) => ({
      ...previous,
      description: description,
    }));
  };

  const handleDomainPrivilegeChanged = (domainName, privilegeValue) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let checked = e.target.checked;
      let domainPrivileges = request.domain_privileges;
      let currentDomain = domainPrivileges.get(domainName);
      let privileges = currentDomain.actions;
      privileges[privilegeValue] = checked;
      currentDomain.actions = privileges;
      domainPrivileges.set(domainName, currentDomain);
      setRequest((previous) => ({
        ...previous,
        domain_privileges: domainPrivileges,
      }));
    };
  };

  const handleRolePrivilegeChanged = (privilegeValue) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let checked = e.target.checked;
      let actions = request.actions;
      actions[privilegeValue] = checked;
      setRequest((previous) => ({
        ...previous,
        actions: actions,
      }));
    };
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified role " + roleName);
      toast.success("role " + roleName + " modified");
      onList();
    };

    //format privileges
    let checkedRolePrivileges = parseRolePrivileges(request.actions);
    let domainPrivileges = [];
    request.domainNames.forEach(domainName =>{
      let privilegeValues = request.domain_privileges.get(domainName).actions;
      let checkedActions = parseDomainPrivileges(privilegeValues);
      domainPrivileges.push({
        name: domainName,
        actions: checkedActions,
        resource_privileges: [],
      });
    });
    modifyRole(
      roleName,
      request.description,
      checkedRolePrivileges,
      domainPrivileges,
      onOperateSuccess,
      onFail
    );
  };

  const mapRolePrivileges = (groups) => {
    return groups.map(({ group, options }) => (
      <FormControl
        sx={{
          m: 3,
          display: "flex",
        }}
        component="fieldset"
        variant="standard"
        key={group}
      >
        <FormLabel component="legend">{group}</FormLabel>
        <FormGroup row>
          <Grid container>
            {options.map(({ value, label }) => (
              <Grid key={value} xs={6} sm={4} md={3} lg={3} xl={2} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleRolePrivilegeChanged(value)}
                      checked={request.actions[value]}
                    />
                  }
                  label={label}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    ));
  };


  const mapDomainPrivileges = (domainNames, groups) => {
    return domainNames.map(domainName => (
      <Box key={domainName}>
        <Typography>{texts.domainPrivileges + domainName}</Typography>
        {groups.map(({ group, options }) => (
          <FormControl
            sx={{
              m: 3,
              display: "flex",
            }}
            component="fieldset"
            variant="standard"
            key={group}
          >
            <FormLabel component="legend">{group}</FormLabel>
            <FormGroup row>
              <Grid container>
                {options.map(({ value, label }) => (
                  <Grid key={value} xs={6} sm={4} md={3} lg={3} xl={2} item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleDomainPrivilegeChanged(domainName, value)}
                          checked={request.domain_privileges.get(domainName).actions[value]}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
        ))}
      </Box>
    ));
  };


  React.useEffect(() => {
    setMounted(true);
    if (!roleName) {
      return;
    }
    const onGetSuccess = (data) => {
      if (!mounted) {
        return;
      }
      const { description, domain_privileges, actions } = data;
      let request = {
        description: description,
      };
      let initialActions = rolePrivileges.values;
      if (actions) {
        actions.forEach((privilege) => {
          initialActions[privilege] = true;
        });
      }
      let domains = new Map();
      let domainNames = [];
      if (domain_privileges){
        domain_privileges.forEach( domain => {
          const {name, actions} = domain;
          let domainActions = domainPrivileges.values;
          if (actions){
            actions.forEach((privilege) => {
              domainActions[privilege] = true;
            });
          }

          domains.set(name, {
            actions: domainActions,
          });
          domainNames.push(name);
        })
      }else{
        //default value
        domains.set(DEFAULT_DOMAIN_NAME, {
          actions: domainPrivileges.values,
        })
        domainNames.push(DEFAULT_DOMAIN_NAME);
      }
      request.actions = initialActions;
      request.domain_privileges = domains;
      request.domainNames = domainNames;
      setRequest(request);
      setInitialized(true);
    };

    getRole(roleName, onGetSuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, roleName, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const descriptionInput = (
      <InputComponent
        type="textarea"
        label={texts.description}
        value={request.description}
        onChange={handleDescriptionChanged}
        xs={8}
        md={6}
        lg={4}
      />
    );
    let roleComponent = mapRolePrivileges(rolePrivileges.groups);
    let domainComponents = mapDomainPrivileges(request.domainNames, domainPrivileges.groups);

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Box>{descriptionInput}</Box>
        <Box>
          <Typography>{texts.rolePrivileges}</Typography>
          {roleComponent}
        </Box>
        {domainComponents}
      </Stack>
    );

    title = texts.title + roleName;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

ModifyRole.propTypes = {
  roleName: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
