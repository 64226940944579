import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import { InputComponent } from "components/CustomInput/InputList";
import { getSchema, createDocument, writeLog } from "backend_api.js";
import { convertToPropertyValue, propertyTypeName} from "utils.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Create New Digital Asset ",
    back: "Back",
    name: "Asset ID",
    nameHelper: "Leave blank to generate an ID",
    propertyName: "Property Name",
    propertyType: "Property Type",
    propertyValue: "Property Value",
    submitButton: "Submit",
  },
  cn: {
    title: "新建数字资产 ",
    back: "返回",
    name: "资产ID",
    nameHelper: "留空则自动生成",
    propertyName: "属性名",
    propertyType: "属性类型",
    propertyValue: "属性值",
    submitButton: "提交",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function CreateDocument(props) {
  const schemaName = props.match.params.schema;
  const { lang } = props;
  const texts = i18n[lang];
  const defaultValues = {
    id: "",
    values: [],
  };
  const [schema, setSchema] = React.useState(null);
  const [data, setData] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback((msg) => {
    toast.error(msg);
  }, []);

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handlePropertyValueChanged = (index) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let value = e.target.value;
      if (index >= data.values.length) {
        onFail(
          "update value overflow " +
            index.toString() +
            " > " +
            data.values.length.toString()
        );
        return;
      }
      let newValues = data.values;
      newValues[index] = value;
      setData((previous) => ({
        ...previous,
        values: newValues,
      }));
    };
  };

  const handleConfirm = () => {
    const onOperateSuccess = () => {
      writeLog("created new asset " + schemaName + "." + docID);
      const listURL = "/admin/assets/schemas/" + schemaName + "/docs/";
      window.location.assign(listURL);
    };
    let docID = data.id;
    if (docID) {
      let wordOnly = new RegExp("\\W");
      if (wordOnly.test(docID)) {
        onFail("only words allow in document ID: " + docID);
        return;
      }
    }

    let propertyCount = schema.properties.length;
    if (propertyCount !== data.values.length) {
      onFail(
        "unexpected value count: " +
          data.values.length.toString() +
          " => " +
          propertyCount.toString()
      );
      return;
    }
    let doc = new Object();
    for (let offset = 0; offset < propertyCount; offset++) {
      let valueType = schema.properties[offset].type;
      let propertyName = schema.properties[offset].name;
      let value = convertToPropertyValue(data.values[offset], valueType);
      if (null === value) {
        onFail(
          "invalid value of property " +
            propertyName +
            ":" +
            data.values[offset]
        );
        return;
      }
      doc[propertyName] = value;
    }
    let content = JSON.stringify(doc);
    createDocument(
      defaultChainName,
      defaultDomainName,
      schemaName,
      docID,
      content,
      onOperateSuccess,
      onFail
    );
  };

  const handleDocIDChanged = (e) => {
    let value = e.target.value;
    if (value !== data.id) {
      setData((previous) => ({
        ...previous,
        id: value,
      }));
    }
  };

  const dataToNodes = (index) => {
    let valueType = schema.properties[index].type;
    let propertyName = schema.properties[index].name;
    let valueTag = propertyTypeName(lang, valueType);
    let inputName = (
      <InputComponent
        type="text"
        label={texts.propertyValue}
        value={data.values[index]}
        required={true}
        xs={6}
        onChange={handlePropertyValueChanged(index)}
      />
    );
    return [propertyName, valueTag, inputName];
  };

  React.useEffect(() => {
    setMounted(true);
    const onGetSchemaSuccess = (schema) => {
      if (!mounted) {
        return;
      }
      let propertyCount = schema.properties.length;
      let initialValues = [];
      for (let offset = 0; offset < propertyCount; offset++) {
        initialValues.push("");
      }
      setData({
        id: "",
        values: initialValues,
      });
      setSchema(schema);
      setInitialized(true);
    };

    getSchema(
      defaultChainName,
      defaultDomainName,
      schemaName,
      onGetSchemaSuccess,
      onFail
    );

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, schemaName, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const nameInput = (
      <InputComponent
        type="text"
        label={texts.name}
        value={data.id}
        helper={texts.nameHelper}
        required={true}
        xs={8}
        md={6}
        lg={4}
        xl={2}
        onChange={handleDocIDChanged}
      />
    );
    let rows = [];
    const propertyCount = data.values.length;
    for (let offset = 0; offset < propertyCount; offset++) {
      const index = offset;
      rows.push(dataToNodes(index));
    }

    content = (
      <Box>
        {nameInput}
        <Table
          color="primary"
          headers={[
            texts.propertyName,
            texts.propertyType,
            texts.propertyValue,
          ]}
          rows={rows}
        />
      </Box>
    );

    title = texts.title + schemaName;
    buttons = [
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={() => {
          const listURL = "/admin/assets/schemas/" + schemaName + "/docs/";
          window.location.assign(listURL);
        }}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}></Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

CreateDocument.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
