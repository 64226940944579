import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import {
  modifyApplicationGroupRoles,
  getApplicationGroupRoles,
  queryRoles,
  writeLog,
} from "backend_api.js";

const i18n = {
  en: {
    title: "Modify Application Group Roles - ",
    back: "Back",
    roles: "Roles",
    name: "Role Name",
    description: "Description",
    submitButton: "Submit",
  },
  cn: {
    title: "修改应用组权限 - ",
    back: "返回",
    roles: "关联角色",
    name: "角色名",
    description: "描述",
    submitButton: "提交变更",
  },
};

export default function ModifyAppGroupRoles(props) {
  const { lang, groupName, onList } = props;
  const texts = i18n[lang];
  const defaultValues = {
    roles: {},
    availables: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleRoleChanged = (roleName) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let checked = e.target.checked;
      let roles = request.roles;
      roles[roleName] = checked;
      setRequest((previous) => ({
        ...previous,
        roles: roles,
      }));
    };
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified roles of group " + groupName);
      toast.success("roles of group " + groupName + " modified");
      onList();
    };

    //format roles
    let roles = [];
    for (const roleName in request.roles) {
      if (request.roles[roleName]) {
        roles.push(roleName);
      }
    }

    if (0 === roles.length) {
      showErrorMessage("Choose at least one role");
      return;
    }

    modifyApplicationGroupRoles(groupName, roles, onOperateSuccess, onFail);
  };

  React.useEffect(() => {
    setMounted(true);
    if (!groupName) {
      return;
    }
    let allRoles = [];

    const onGetSuccess = (roles) => {
      if (!mounted) {
        return;
      }
      let roleValues = {};
      if (allRoles) {
        allRoles.forEach(({ name }) => {
          roleValues[name] = false;
        });
      }
      if (roles) {
        roles.forEach((name) => {
          roleValues[name] = true;
        });
      }
      let request = {
        roles: roleValues,
        availables: allRoles,
      };
      setRequest(request);
      setInitialized(true);
    };

    const onQuerySuccess = ({ roles }) => {
      if (!mounted) {
        return;
      }
      allRoles = roles;
      getApplicationGroupRoles(groupName, onGetSuccess, onFail);
    };

    queryRoles(onQuerySuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, groupName, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const rows = request.availables.map(({ name, description }) => [
      <GridItem key={name} xs={4} sm={3} md={2}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleRoleChanged(name)}
              checked={request.roles[name]}
            />
          }
          label={name}
        />
      </GridItem>,
      <GridItem key={name} xs={8} sm={9} md={10}>
        {description}
      </GridItem>,
    ]);

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Typography>{texts.roles}</Typography>
        <Table
          color="primary"
          headers={[texts.name, texts.description]}
          rows={rows}
        />
      </Stack>
    );

    title = texts.title + groupName;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

ModifyAppGroupRoles.propTypes = {
  groupName: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
