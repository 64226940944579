import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardContainer from "components/Card/CardContainer";
import { InputComponent } from "components/CustomInput/InputList";
import {
  changeApplicationGroup,
  getGroupOfApplication,
  queryApplicationGroups,
  writeLog,
} from "backend_api.js";

const i18n = {
  en: {
    title: "Change Application Group",
    back: "Back",
    group: "Application Group",
    submitButton: "Submit",
  },
  cn: {
    title: "修改应用组",
    back: "返回",
    group: "所属应用组",
    submitButton: "提交变更",
  },
};

export default function ModifyApplication(props) {
  const { lang, appID, onList } = props;
  const texts = i18n[lang];
  const defaultValues = {
    group: '',
    allGroups: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleGroupChanged = (e) => {
    if (!mounted) {
      return;
    }
    let group = e.target.value;
    setRequest((previous) => ({
      ...previous,
      group: group,
    }));
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("group of application " + appID + " changed to " + request.group);
      toast.success("application " + appID + " modified");
      onList();
    };

    if (!request.group) {
      onFail("invalid application group");
      return;
    }

    changeApplicationGroup(
      appID,
      request.group,
      onOperateSuccess,
      onFail
    );
  };

  React.useEffect(() => {
    setMounted(true);

    let currentGroup = '';
    const onQuerySuccess = ({ groups }) => {
      if (!mounted) {
        return;
      }
      setRequest({
        group: currentGroup,
        allGroups: groups,
      });
      setInitialized(true);
    };

    const onGetSuccess = ({group}) => {
      if (!mounted) {
        return;
      }
      currentGroup = group;
      queryApplicationGroups(onQuerySuccess, onFail);
    };

    getGroupOfApplication(appID, onGetSuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, appID, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let groupOptions = request.allGroups.map(group => {
      const {name, description} = group;
      return ({
        label: name + ' - ' + description,
        value: name,
      })
    });

    const groupComponent = (
      <InputComponent
        key="group"
        type="radio"
        label={texts.group}
        value={request.group}
        options={groupOptions}
        onChange={handleGroupChanged}
      />
    )

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Box>
          {groupComponent}
        </Box>
      </Stack>
    );

    title = texts.title;
    buttons = [
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={onList}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}></Box>
      </GridItem>
      <GridItem xs={12}>
        <CardContainer
          title={title}
          components={content}
        />
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ModifyApplication.propTypes = {
  appID: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
