import AddModeratorIcon from '@mui/icons-material/AddModerator';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// dashboard components
import { Checkbox } from "@mui/material";
import { queryApplicationGroups, queryGroups } from "backend_api";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/ObjectTable.js";
import Info from "components/Typography/Info.js";
import AddActorGroupDialog from "./AddActorGroupsDialog";
import RemoveActorGroupDialog from "./RemoveActorGroupDialog";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    titleManagerGroups: "Add Manager Groups",
    titleApplicationGroups: "Add Application Groups",
    btnAddApp: "Add Application Groups",
    btnAddManager: "Add Manager Groups",
    btnRemove: "Remove",
    btnUpdate: "Update",
    group: "Group",
    owner: "Owner",
    executor: "Executor",
    updater: "Updater",
    viewer: "Viewer",
    noRecord: "No actors available",
    operates: "Operates",
  },
  cn: {
    back: "返回",
    titleManagerGroups: "添加管理员组",
    titleApplicationGroups: "添加应用组",
    btnAddApp: "添加应用组",
    btnAddManager: "添加管理员组",
    btnRemove: "删除",
    btnUpdate: "更新",
    group: "组名称",
    owner: "管理权限",
    executor: "执行权限",
    updater: "更新权限",
    viewer: "查看权限",
    noRecord: "尚无权限组信息",
    operates: "操作",
  },
};

export default function ActorList(props) {
  const { title, lang, loader, updater, updatePrivileges } = props;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [item, setItem] = React.useState("");
  const [index, setIndex] = React.useState(0);
  //for dialog
  const [addManagerDialogVisible, setAddManagerDialogVisible] = React.useState(false);
  const [addApplicationDialogVisible, setAddApplicationDialogVisible] = React.useState(false);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      setDataList(result);
    };

    loader(onLoadSuccess, onLoadFail);
  }, [showErrorMessage, mounted]);

  const toggleActorPrivilege = (name, index) => {
    return () => {
      let actors = dataList;
      actors[index][name] = !actors[index][name];
      setDataList([...actors]);
    }
  }
  //remove
  const showRemoveDialog = (groupName, index) => {
    setItem(groupName);
    setIndex(index);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (index) => {    
    //remove by index
    let actors = dataList;
    actors.splice(index, 1);
    setDataList(actors);
    closeRemoveDialog();
  };

  const showAddManagerDialog = () => {
    setAddManagerDialogVisible(true);
  };

  const closeAddManagerDialog = () => {
    setAddManagerDialogVisible(false);
  };

  const onAddManagerSuccess = (groups, privileges) => {
    closeAddManagerDialog();
    addGroups(groups, privileges);
  };

  const showAddApplicationDialog = () => {
    setAddApplicationDialogVisible(true);
  };

  const closeAddApplicationDialog = () => {
    setAddApplicationDialogVisible(false);
  };

  const onAddApplicationSuccess = (groups, privileges) => {
    closeAddApplicationDialog();
    addGroups(groups, privileges);
  };

  const addGroups = (groups, privileges) => {
    let currentActors = dataList;
    for(let groupName of groups){
      currentActors.push({
        ...privileges,
        group: groupName,
      })
    }
    setDataList(currentActors);
  };

  const updateActors = () =>{
    updater(dataList, showErrorMessage);
  }

  const dataToNodes = (record, buttons, index) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const {
      group,
      owner,
      executor,
      updater,
      viewer,
    } = record;
    return [
      group,
      <Checkbox key='owner' checked={owner} color='info' onChange={toggleActorPrivilege('owner', index)} />,
      <Checkbox key='executor' checked={executor} color='info' onChange={toggleActorPrivilege('executor', index)} />,
      <Checkbox key='updater' checked={updater} color='info' onChange={toggleActorPrivilege('updater', index)} />,
      <Checkbox key='viewer' checked={viewer} color='info' onChange={toggleActorPrivilege('viewer', index)} />,
      operates,
    ];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let existedGroups = [];
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record, index) => {
        const buttons = [
          {
            onClick: () => showRemoveDialog(record.group, index),
            icon: DeleteIcon,
            label: texts.btnRemove,
            privileges: updatePrivileges,
          },
        ];
        rows.push(dataToNodes(record, buttons, index));
        existedGroups.push(record.group);
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.group,
            texts.owner,
            texts.executor,
            texts.updater,
            texts.viewer,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }
    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{title}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: () => {
        props.history.goBack();
      },
      label: texts.back,
      icon: NavigateBeforeIcon,
    },
    {
      onClick: showAddApplicationDialog,
      label: texts.btnAddApp,
      icon: AddModeratorIcon,
      privileges: updatePrivileges,
    },
    {
      onClick: showAddManagerDialog,
      label: texts.btnAddManager,
      icon: GroupAddIcon,
      privileges: updatePrivileges,
    },
  ];

  const submitButton = {
    onClick: updateActors,
    label: texts.btnUpdate,
    icon: BuildIcon,
    privileges: updatePrivileges,
    size: 'large',
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box>
          <AuthenticatedButtons buttons={buttons} />
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <GridItem xs={12}>
        <Divider />
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Box>
            <AuthenticatedButtons buttons={[submitButton]} />
          </Box>
        </Box>
      </GridItem>
      <AddActorGroupDialog
        lang={lang}
        open={addManagerDialogVisible}
        title={texts.titleManagerGroups}
        queryHandler={queryGroups}
        existed={existedGroups}
        onSuccess={onAddManagerSuccess}
        onCancel={closeAddManagerDialog}
      />
      <AddActorGroupDialog
        lang={lang}
        open={addApplicationDialogVisible}
        title={texts.titleApplicationGroups}
        queryHandler={queryApplicationGroups}
        existed={existedGroups}
        onSuccess={onAddApplicationSuccess}
        onCancel={closeAddApplicationDialog}
      />
      <RemoveActorGroupDialog
        lang={lang}
        open={removeDialogVisible}
        groupName={item}
        groupIndex={index}
        onConfirm={onRemoveSuccess}
        onCancel={closeRemoveDialog}
        />
      
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ActorList.propTypes = {
  title: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  loader: PropTypes.func.isRequired,
  updater: PropTypes.func.isRequired,
  history: PropTypes.object,
  updatePrivileges: PropTypes.object,
};
