import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import { queryBlocks } from "backend_api";
import { PrivilegeRequirements, RoleActionReadDomain } from "privileges";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    tableTitle: "Block List",
    id: "Block ID",
    invoker: "Invoker",
    timestamp: "Generated Time",
    height: "Block Height",
    endorsements: "Endorsements",
    transactions: "Transactions",
    noRecord: "No block available",
    operates: "Operates",
    detail: "Detail",
    listButton: "Transactions",
  },
  cn: {
    tableTitle: "区块列表",
    id: "区块ID",
    invoker: "发起节点",
    timestamp: "生成时间",
    height: "区块高度",
    endorsements: "签名数量",
    transactions: "交易数量",
    noRecord: "无区块",
    operates: "操作",
    detail: "详情",
    listButton: "交易清单",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function BlockList(props) {
  const recordPerPage = 10;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { blocks, from, height } = result;
      setDataList(blocks);
      const offset = from - 1;
      const total = height;
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const from = currentPage * recordPerPage + 1;
    const to = from + recordPerPage - 1;
    queryBlocks(chainName, domainName, from, to, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, currentPage, chainName, mounted]);

  const dataToNodes = (data, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { id, invoker, timestamp, height, endorsements, transactions } = data;
    return [
      height.toString(),
      id,
      invoker,
      new Date(timestamp).toLocaleString(),
      endorsements.toString(),
      transactions.toString(),
      operates,
    ];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((block) => {
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            href: "/admin/blocks/" + block.id,
            privileges: new PrivilegeRequirements().roleActions(["read_chain", RoleActionReadDomain]),
          },
          {
            icon: ReceiptIcon,
            label: texts.listButton,
            href: "/admin/blocks/" + block.id + "/transactions/",
            privileges: new PrivilegeRequirements().roleActions(["read_chain", RoleActionReadDomain]),
          },
        ];
        rows.push(dataToNodes(block, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.height,
            texts.id,
            texts.invoker,
            texts.timestamp,
            texts.endorsements,
            texts.transactions,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

BlockList.propTypes = {
  lang: PropTypes.string.isRequired,
};
