const actionDefinesCN = {
  declare_var: {
    name: "声明变量",
    parameters: [
      {
        label: "新变量标识",
        tooltip: "要创建并赋值的新变量标识",
      },
      {
        label: "初始值标识",
        tooltip: "新变量初始值，支持系统变量和自定义变量，决定新变量的数据类型",
      },
    ],
    helper: "使用初始值{1}的类型创建新变量{0}，并使用值{1}将其初始化",
  },
  declare_string: {
    name: "声明字符串变量",
    parameters: [
      {
        label: "新变量标识",
        tooltip: "要创建的新变量标识",
      },
      {
        label: "初始值标识",
        tooltip:
          "用于赋值的初始值标识，支持字符串常量、系统变量和自定义变量，必须为字符串类型",
      },
    ],
    helper: "创建字符串类型新变量{0}，并使用值{1}将其初始化",
  },
  declare_bool: {
    name: "声明布尔变量",
    parameters: [
      {
        label: "新变量标识",
        tooltip: "要创建的新变量标识",
      },
      {
        label: "初始值标识",
        tooltip:
          "用于赋值的初始值标识，支持字符串常量、系统变量和自定义变量，必须为布尔类型",
      },
    ],
    helper: "创建布尔类型新变量{0}，并使用值{1}将其初始化",
  },
  declare_int: {
    name: "声明整数变量",
    parameters: [
      {
        label: "新变量标识",
        tooltip: "要创建的新变量标识",
      },
      {
        label: "初始值标识",
        tooltip:
          "用于赋值的初始值标识，支持字符串常量、系统变量和自定义变量，必须为整数类型",
      },
    ],
    helper: "创建整数类型新变量{0}，并使用值{1}将其初始化",
  },
  declare_float: {
    name: "声明浮点数变量",
    parameters: [
      {
        label: "新变量标识",
        tooltip: "要创建的新变量标识",
      },
      {
        label: "初始值标识",
        tooltip:
          "用于赋值的初始值标识，支持字符串常量、系统变量和自定义变量，必须为浮点数类型",
      },
    ],
    helper: "创建浮点数类型新变量{0}，并使用值{1}将其初始化",
  },
  declare_currency: {
    name: "声明货币变量",
    parameters: [
      {
        label: "新变量标识",
        tooltip: "要创建的新变量标识",
      },
      {
        label: "初始值标识",
        tooltip:
          "用于赋值的初始值标识，支持字符串常量、系统变量和自定义变量，必须为货币类型",
      },
    ],
    helper: "创建货币类型新变量{0}，并使用值{1}将其初始化",
  },
  set_var: {
    name: "对变量赋值",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "被赋值的变量标识",
      },
      {
        label: "源变量标识",
        tooltip:
          "用于赋值的初始值标识，支持字符串常量、系统变量和自定义变量，源标识和目标标识类型必须一致",
      },
    ],
    helper:
      "如果{0}存在，则使用{1}当前值赋值给{0}，否则使用{1}类型和值创建并初始化{0}。源标识和目标标识类型必须一致",
  },
  set_bool: {
    name: "对布尔变量赋值",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "被赋值的变量标识",
      },
      {
        label: "左值变量标识",
        tooltip: "逻辑判断式左值，支持字符串常量、系统变量和自定义变量",
      },
      {
        label: "判断标识符",
        tooltip:
          '逻辑判断操作符，可选项：">"、"<"、"="、"!="、">="、"<="，操作符必须匹配左值变量类型',
      },
      {
        label: "右值变量标识",
        tooltip:
          "逻辑判断式右值，支持字符串常量、系统变量和自定义变量。右值如为常量，则自动转换为左值变量类型；右值如为变量，则必须与左值类型一致",
      },
    ],
    helper: "使用{1} {2} {3}进行条件判断，并将判断结果的布尔值写入{0}",
  },
  //数字处理
  add: {
    name: "加法运算",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "被加数标识",
        tooltip:
          "被加数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
      {
        label: "加数标识",
        tooltip:
          "加数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{0} = {1} + {2}",
  },

  sub: {
    name: "减法运算",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "被减数标识",
        tooltip:
          "被减数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
      {
        label: "减数标识",
        tooltip:
          "减数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{0} = {1} - {2}",
  },
  multiply: {
    name: "乘法运算",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "被乘数标识",
        tooltip:
          "被乘数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
      {
        label: "乘数标识",
        tooltip:
          "乘数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{0} = {1} * {2}",
  },
  div: {
    name: "除法运算",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "被除数标识",
        tooltip:
          "被除数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
      {
        label: "除数标识",
        tooltip:
          "除数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{0} = {1} / {2}",
  },
  mod: {
    name: "取余运算",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "被除数标识",
        tooltip:
          "被除数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
      {
        label: "除数标识",
        tooltip:
          "除数标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{0} = {1} % {2}",
  },
  floor: {
    name: "向下取整",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "来源值标识",
        tooltip:
          "源值标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{1}向下取整，结果保存到{0}",
  },
  ceil: {
    name: "向上取整",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "来源值标识",
        tooltip:
          "源值标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{1}向上取整，结果保存到{0}",
  },
  round: {
    name: "四舍五入取整",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "来源值标识",
        tooltip:
          "源值标识，支持字符串常量、系统变量和自定义变量，变量类型必须与目标变量一致，常量会自动进行转换",
      },
    ],
    helper: "{1}值四舍五入取整，结果保存到{0}",
  },
  //字符串处理
  insert: {
    name: "插入字符串",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "原始字符串变量标识",
        tooltip: "原始字符串标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "插入字符串变量标识",
        tooltip: "插入的字符串标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "{2}内容插入到{1}前，结果字符串保存到{0}",
  },
  append: {
    name: "追加字符串",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "原始字符串变量标识",
        tooltip: "原始字符串标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "追加字符串变量标识",
        tooltip: "追加的字符串标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "{2}内容追加到{1}后，结果字符串保存到{0}",
  },
  trim: {
    name: "修剪字符串",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "计算结果存储变量，不存在则自动创建",
      },
      {
        label: "原始字符串变量标识",
        tooltip: "原始字符串标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "修剪字符串变量标识",
        tooltip: "要进行修剪的字符串标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "在{1}头尾位置去除{2}后，结果字符串保存到{0}",
  },
  contains: {
    name: "是否包含子字符串",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "存储判断结果，不存在则自动创建",
      },
      {
        label: "查询字符串标识",
        tooltip: "查询目标字符串标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "子字符串标识",
        tooltip: "查询子字符串标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "判断{1}是否包含{2}，结果保存到{0}",
  },
  //数字资产处理
  create_doc: {
    name: "创建数字资产",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "存储新建资产，变量不存在则自动创建",
      },
      {
        label: "资产类别标识",
        tooltip: "资产类别的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产ID标识",
        tooltip:
          "资产ID的标识，必须为字符串常量或者字符串类型变量。如果为空，则由系统生成",
      },
    ],
    helper:
      "创建类别为{1}，ID为{2}的数字资产，保存到{0}，如果{2}为空则自动生成",
  },
  get_doc: {
    name: "获取数字资产",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "存储读取的文档对象的目标变量",
      },
      {
        label: "资产类别标识",
        tooltip: "资产类别的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产ID标识",
        tooltip: "资产ID的标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "读取类别为{1}，ID为{2}的数字资产，保存到{0}",
  },
  get_doc_id: {
    name: "获取数字资产ID",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "存储资产ID的目标变量，必须为字符串类型",
      },
      {
        label: "资产变量标识",
        tooltip: "资产对象标识，必须为文档类型变量",
      },
    ],
    helper: "获取资产变量{1}的ID，存储到{0}中",
  },
  get_doc_schema: {
    name: "获取数字资产类别",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "存储资产类别的目标变量，必须为字符串类型",
      },
      {
        label: "资产变量标识",
        tooltip: "资产对象标识，必须为文档类型变量",
      },
    ],
    helper: "获取资产变量{1}的类别，存储到{0}中",
  },
  delete_doc: {
    name: "删除数字资产",
    parameters: [
      {
        label: "资产类别标识",
        tooltip: "资产类别的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产ID标识",
        tooltip: "资产ID的标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "从系统中删除类别为{1}，ID为{0}的数字资产",
  },
  update_doc: {
    name: "更新数字资产",
    parameters: [
      {
        label: "资产类别标识",
        tooltip: "资产类别的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产变量标识",
        tooltip: "要更新到系统的资产变量，必须为资产类型变量",
      },
    ],
    helper: "将{1}的资产内容写入类别为{0}的数字资产中，使用{1}中的资产ID",
  },
  get_property: {
    name: "获取数字资产属性",
    parameters: [
      {
        label: "目标变量标识",
        tooltip:
          "存储资产属性值的目标变量，必须为自定义变量。如果变量已经存在，类型必须与属性类型一致；如果变量不存在，则使用属性类型创建新变量。",
      },
      {
        label: "资产类别标识",
        tooltip: "资产类别的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产ID标识",
        tooltip: "资产ID的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产属性标识",
        tooltip: "资产属性的标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "读取类别为{1}，ID为{2}的数字资产的属性{3}，保存到{0}",
  },
  set_property: {
    name: "设置数字资产属性",
    parameters: [
      {
        label: "资产变量标识",
        tooltip: "必须为已声明的资产类型自定义变量",
      },
      {
        label: "资产属性标识",
        tooltip: "资产属性的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "属性值标识",
        tooltip:
          "要赋值的属性值标识，支持字符串常量、系统变量和自定义变量。变量类型必须与属性一致。如果值为常量，则根据属性值类型自动转换",
      },
    ],
    helper: "将数字资产{0}的属性{1}的内容，更新为{2}",
  },
  has_doc: {
    name: "是否存在数字资产",
    parameters: [
      {
        label: "目标变量标识",
        tooltip: "存储判断结果，必须为布尔类型变量，变量不存在则自动创建",
      },
      {
        label: "资产类别标识",
        tooltip: "资产类别的标识，必须为字符串常量或者字符串类型变量",
      },
      {
        label: "资产ID标识",
        tooltip: "资产ID的标识，必须为字符串常量或者字符串类型变量",
      },
    ],
    helper: "检查类别为{1}，ID为{2}的数字资产是否存在，结果保存到{0}",
  },
  //流程控制
  require: {
    name: "强制满足条件",
    parameters: [
      {
        label: "左值标识",
        tooltip: "逻辑判断式左值，支持字符串常量、系统变量和自定义变量",
      },
      {
        label: "判断操作符",
        tooltip:
          '逻辑判断操作符，可选项：">"、"<"、"="、"!="、">="、"<="，操作符必须匹配左值变量类型',
      },
      {
        label: "右值标识",
        tooltip:
          "逻辑判断式右值，支持字符串常量、系统变量和自定义变量。右值如为常量，则自动转换为左值变量类型；右值如为变量，则必须与左值类型一致",
      },
    ],
    helper: "使用{0}{1}{2}进行逻辑判断，条件不满足则终止合约执行",
  },
  submit: {
    name: "提交变更",
    helper: "结束当前合约执行，并将所有变更提交系统",
  },
  rollback: {
    name: "回退变更",
    helper: "结束当前合约执行，所有变更撤回，不提交系统",
  },
  if: {
    name: "条件跳转",
    parameters: [
      {
        label: "左值标识",
        tooltip: "逻辑判断式左值，支持字符串常量、系统变量和自定义变量",
      },
      {
        label: "判断操作符",
        tooltip:
          '逻辑判断操作符，可选项：">"、"<"、"="、"!="、">="、"<="，操作符必须匹配左值变量类型',
      },
      {
        label: "右值标识",
        tooltip:
          "逻辑判断式右值，支持字符串常量、系统变量和自定义变量。右值如为常量，则自动转换为左值变量类型；右值如为变量，则必须与左值类型一致",
      },
    ],
    helper:
      "使用{0}{1}{2}进行逻辑判断，结果为真则继续执行，不为真则跳转到对应else或者end_if后续动作执行",
  },
  else: {
    name: "条件分支",
    helper: "标记条件判断不为真时执行动作，与if动作匹配",
  },
  end_if: {
    name: "结束条件跳转",
    helper: "标记条件跳转动作段的结束位置，与if动作匹配",
  },
  loop: {
    name: "条件循环",
    parameters: [
      {
        label: "左值标识",
        tooltip: "逻辑判断式左值，支持字符串常量、系统变量和自定义变量",
      },
      {
        label: "判断操作符",
        tooltip:
          '逻辑判断操作符，可选项：">"、"<"、"="、"!="、">="、"<="，操作符必须匹配左值变量类型',
      },
      {
        label: "右值标识",
        tooltip:
          "逻辑判断式右值，支持字符串常量、系统变量和自定义变量。右值如为常量，则自动转换为左值变量类型；右值如为变量，则必须与左值类型一致",
      },
    ],
    helper:
      "使用{0}{1}{2}进行逻辑判断，结果为真则继续循环执行，不为真则结束循环",
  },
  end_loop: {
    name: "结束条件循环",
    helper: "标记条件循环段的结束位置，与loop动作匹配",
  },
  continue: {
    name: "跳到下一循环",
    helper: "结束当前循环处理，开始执行下一循环，仅在loop/end_loop中可用",
  },
  break: {
    name: "跳出当前循环",
    helper: "结束当前处理并退出循环，仅在loop/end_loop中可用",
  },
};

const actionDefinesEN = {
  declare_var: {
    name: "Declare New Variable",
    parameters: [
      {
        label: "New Variable",
        tooltip: "Tag of New Variable need to create",
      },
      {
        label: "Initial Value",
        tooltip:
          "Source variable of initial value, allow system variable or customize variable",
      },
    ],
    helper: "Create new variable {0} using type and value of {1}",
  },
  declare_string: {
    name: "Declare String Variable",
    parameters: [
      {
        label: "New Variable",
        tooltip: "Tag of New Variable need to create",
      },
      {
        label: "Initial Value",
        tooltip: "must be string constant or string variable",
      },
    ],
    helper: "Create string variable {0}, initialize using the value of {1}",
  },
  declare_bool: {
    name: "Declare Boolean Variable",
    parameters: [
      {
        label: "New Variable",
        tooltip: "Tag of New Variable need to create",
      },
      {
        label: "Initial Value",
        tooltip: "must be string constant or boolean variable",
      },
    ],
    helper: "Create boolean variable {0}, initialize using the value of {1}",
  },
  declare_int: {
    name: "Declare Integer Variable",
    parameters: [
      {
        label: "New Variable",
        tooltip: "Tag of New Variable need to create",
      },
      {
        label: "Initial Value",
        tooltip: "must be string constant or integer variable",
      },
    ],
    helper: "Create integer variable {0}, initialize using the value of {1}",
  },
  declare_float: {
    name: "Declare Float Variable",
    parameters: [
      {
        label: "New Variable",
        tooltip: "Tag of New Variable need to create",
      },
      {
        label: "Initial Value",
        tooltip: "must be string constant or float variable",
      },
    ],
    helper: "Create float variable {0}, initialize using the value of {1}",
  },
  declare_currency: {
    name: "Declare Currency Variable",
    parameters: [
      {
        label: "New Variable",
        tooltip: "Tag of New Variable need to create",
      },
      {
        label: "Initial Value",
        tooltip: "must be string constant or currency variable",
      },
    ],
    helper: "Create currency variable {0}, initialize using the value of {1}",
  },
  set_var: {
    name: "Variable Assignment",
    parameters: [
      {
        label: "Destination Variable",
        tooltip: "Variable Tag for assignment",
      },
      {
        label: "Source Variable",
        tooltip: "variable or string constant, value type must be the same",
      },
    ],
    helper: "assign the value of {1} to {0}, must be the same value type.",
  },
  set_bool: {
    name: "Boolean Assignment",
    parameters: [
      {
        label: "Destination Variable",
        tooltip: "Variable Tag for assignment",
      },
      {
        label: "Left Value",
        tooltip:
          "The left value for comparison, accepts variables and string constants",
      },
      {
        label: "Comparison operators",
        tooltip:
          'Comparison operators, optional: ">", "<", "=", "! =", "> =", "< =". ',
      },
      {
        label: "Right Value",
        tooltip:
          "The right value for comparison,automatically convert according to the value type of the left variable.",
      },
    ],
    helper: "Evaluate {1} {2} {3}, store result into {0}.",
  },
  //数字处理
  add: {
    name: "Addition",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Summand",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
      {
        label: "Addend",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "{0} = {1} + {2}",
  },

  sub: {
    name: "Subtraction",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Minuend",
        tooltip:
          "Tag of Minuend，The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
      {
        label: "Subtrahend",
        tooltip:
          "Tag of Subtrahend，The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "{0} = {1} - {2}",
  },
  multiply: {
    name: "Multiplication",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Multiplicand",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
      {
        label: "Multiplicator",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "{0} = {1} * {2}",
  },
  div: {
    name: "Division",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Dividend",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
      {
        label: "divisor",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "{0} = {1} / {2}",
  },
  mod: {
    name: "Remainder",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Dividend",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
      {
        label: "Divisor",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "{0} = {1} % {2}",
  },
  floor: {
    name: "Floor",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Source Variable",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "Rounds {1} downwards to the nearest integer, stores into{0}",
  },
  ceil: {
    name: "Ceil",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Source Variable",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "Rounds {1} upwards to the nearest integer, stores into{0}",
  },
  round: {
    name: "Round",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Source Variable",
        tooltip:
          "The value type of the variable must be the same as the target, and the constant value will convert automatically.",
      },
    ],
    helper: "Rounds {1} to the nearest integer, stores into{0}",
  },
  //字符串处理
  insert: {
    name: "Insert String",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Original String",
        tooltip: "must be string variable or constant",
      },
      {
        label: "String for Inserting",
        tooltip: "must be string variable or constant",
      },
    ],
    helper: "Insert {2} before {1}, result stores into {0}",
  },
  append: {
    name: "Append String",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Original String",
        tooltip: "must be string variable or constant",
      },
      {
        label: "String for Appending",
        tooltip: "must be string variable or constant",
      },
    ],
    helper: "Append {2} after {1}, result stores into {0}",
  },
  trim: {
    name: "Trim String",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Original String",
        tooltip: "must be string variable or constant",
      },
      {
        label: "String need to trim",
        tooltip: "must be string variable or constant",
      },
    ],
    helper: "trim {2} at both head and tail of {1}, result stores into {0}",
  },
  contains: {
    name: "Contains Substring",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Whole String",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Substring",
        tooltip: "must be string variable or constant",
      },
    ],
    helper:
      "Determine whether {1} contains substring {2}, and save the result to {0}",
  },
  //数字资产处理
  create_doc: {
    name: "Create Digital Asset",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Asset Schema",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset ID",
        tooltip:
          "must be string variable or constant.Generated by the system when omitting",
      },
    ],
    helper:
      "Create a digital asset with tag '{1}.{2}', save to {0}. ID is automated generated if {2} is blank.",
  },
  get_doc: {
    name: "Get Digital Asset",
    parameters: [
      {
        label: "Destination Variable Tag",
        tooltip: "The variable for store asset object",
      },
      {
        label: "Asset Schema",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset ID",
        tooltip: "must be string variable or constant",
      },
    ],
    helper: "Load digital asset '{1}.{2}', save to {0}",
  },
  get_doc_id: {
    name: "Get ID of Digital Asset",
    parameters: [
      {
        label: "Destination Variable",
        tooltip: "The variable for store asset ID, must be string type",
      },
      {
        label: "Asset Variable",
        tooltip: "must be asset type",
      },
    ],
    helper: "Get asset ID from variable {1}, save to {0}",
  },
  get_doc_schema: {
    name: "Get Schema of Digital Asset",
    parameters: [
      {
        label: "Destination Variable",
        tooltip: "The variable for store asset schema, must be string type",
      },
      {
        label: "Asset Variable",
        tooltip: "must be asset type",
      },
    ],
    helper: "Get asset Schema from variable {1}, save to {0}",
  },
  delete_doc: {
    name: "Delete Digital Asset",
    parameters: [
      {
        label: "Asset Schema",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset ID",
        tooltip: "must be string variable or constant",
      },
    ],
    helper: "Remove ditigal asset '{1}.{0}' from system",
  },
  update_doc: {
    name: "Update Digital Asset",
    parameters: [
      {
        label: "Asset Schema",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset Variable",
        tooltip: "must be asset type",
      },
    ],
    helper: "Write content of {1} as asset of schema {0}",
  },
  get_property: {
    name: "Get Property of Digital Asset",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "A custom variable stores the property value. The variable must be the same value type as property if it exists.",
      },
      {
        label: "Asset Schema",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset ID",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset Property",
        tooltip: "must be string variable or constant",
      },
    ],
    helper:
      "Read the property {3} of the digital asset '{1}.{2}', and save to {0}.",
  },
  set_property: {
    name: "Set Property of Digital Asset",
    parameters: [
      {
        label: "Asset Variable",
        tooltip: "must be declared asset variable",
      },
      {
        label: "Asset Property",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Property Value",
        tooltip:
          "The value type of the variable must be the same as the property, while the constant value will convert automatically.",
      },
    ],
    helper: "Update property {1} of asset varible {0} to {2}",
  },
  has_doc: {
    name: "Check Digital Asset",
    parameters: [
      {
        label: "Destination Variable",
        tooltip:
          "The variable for store result, automate created if not exists.",
      },
      {
        label: "Asset Schema",
        tooltip: "must be string variable or constant",
      },
      {
        label: "Asset ID",
        tooltip: "must be string variable or constant",
      },
    ],
    helper: "Check if asset '{1}.{2}' exists, save to {0}",
  },
  //流程控制
  require: {
    name: "Must Meet Requirements",
    parameters: [
      {
        label: "Left Value",
        tooltip:
          "The left value for comparison, which supports variables and string constants",
      },
      {
        label: "Comparison operators",
        tooltip:
          'Comparison operators, optional: ">", "<", "=", "! =", "> =", "< =". ',
      },
      {
        label: "Right Value",
        tooltip:
          "The right value for comparison,automatically convert according to the value type of the left variable.",
      },
    ],
    helper: "Evaluate {0} {1} {2}, terminate if result is false",
  },
  submit: {
    name: "Submit",
    helper:
      "Complete the execution of the current contract session and submit all changes to the system",
  },
  rollback: {
    name: "Rollback",
    helper:
      "Terminate the execution of the current contract session and cancel all changes.",
  },
  if: {
    name: "If",
    parameters: [
      {
        label: "Left Value",
        tooltip:
          "The left value for comparison, which supports variables and string constants",
      },
      {
        label: "Comparison operators",
        tooltip:
          'Comparison operators, optional: ">", "<", "=", "! =", "> =", "< =". ',
      },
      {
        label: "Right Value",
        tooltip:
          "The right value for comparison,automatically convert according to the value type of the left variable.",
      },
    ],
    helper:
      "Evaluate {0} {1} {2}, jump to 'else' segment or exit 'if' block if the result is false.",
  },
  else: {
    name: "Else",
    helper: "Mark the 'else' block, corresponds to the 'If' action.",
  },
  end_if: {
    name: "End If",
    helper: "Mark the end of the 'if' block, corresponds to the 'If' action.",
  },
  loop: {
    name: "Loop",
    parameters: [
      {
        label: "Left Value",
        tooltip:
          "The left value for comparison, which supports variables and string constants",
      },
      {
        label: "Comparison operators",
        tooltip:
          'Comparison operators, optional: ">", "<", "=", "! =", "> =", "< =". ',
      },
      {
        label: "Right Value",
        tooltip:
          "The right value for comparison,automatically convert according to the value type of the left variable.",
      },
    ],
    helper: "Evaluate {0} {1} {2}, stop the loop if the result is not true",
  },
  end_loop: {
    name: "End Loop",
    helper:
      "Mark the end of the 'Loop' block,corresponds to the 'Loop' action.",
  },
  continue: {
    name: "Continue",
    helper:
      "Forces to execute the next iteration of the loop, only available in the loop block",
  },
  break: {
    name: "Break",
    helper: "terminate the loop, only available in the loop block",
  },
};

const actionCatalogs = [
  {
    label: {
      cn: "变量声明与赋值",
      en: "Variable Declare and Assignment",
    },
    actions: [
      "declare_var",
      "declare_string",
      "declare_bool",
      "declare_int",
      "declare_float",
      "declare_currency",
      "set_var",
      "set_bool",
    ],
  },
  {
    label: {
      cn: "数值计算",
      en: "Numberical Computation",
    },
    actions: ["add", "sub", "multiply", "div", "mod", "floor", "ceil", "round"],
  },
  {
    label: {
      cn: "字符串处理",
      en: "String Processing",
    },
    actions: ["insert", "append", "trim", "contains"],
  },
  {
    label: {
      cn: "数字资产处理",
      en: "Assets Processing",
    },
    actions: [
      "read_doc",
      "get_doc",
      "get_doc_id",
      "get_doc_schema",
      "delete_doc",
      "update_doc",
      "get_property",
      "set_property",
      "has_doc",
    ],
  },
  {
    label: {
      cn: "控制流程",
      en: "Control Flow",
    },
    actions: [
      "require",
      "submit",
      "rollback",
      "if",
      "else",
      "end_if",
      "loop",
      "end_loop",
      "continue",
      "break",
    ],
  },
];

export function getActionDefines(lang) {
  switch (lang) {
    case "en":
      return actionDefinesEN;
    default:
      return actionDefinesCN;
  }
}

export function getActionCatalogOptions(lang) {
  const actionDefines = getActionDefines(lang);
  const subItemPrefix = "----";
  let options = [];
  actionCatalogs.forEach(({ label, actions }) => {
    //catalog name
    const catalogName = label[lang];
    options.push({
      value: catalogName,
      label: catalogName,
      disabled: true,
    });
    actions.forEach((action) => {
      const { name } = actionDefines[action];
      options.push({
        value: action,
        label: subItemPrefix + name,
      });
    });
  });

  return options;
}
