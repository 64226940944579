import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import { InputComponent } from "components/CustomInput/InputList";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Alert from "@mui/material/Alert";
import { Redirect } from "react-router-dom";
// import { Redirect } from "@reach/router";
import bgImage from "assets/img/login_background.jpg";
import LanguageSelector from "components/Language/Selector.js";
import {
  loginUser,
  writeLog,
  getSystemStatus,
} from "backend_api.js";
import { PROJECT_NAME } from "project.js";
import { saveLoggedSession } from "utils.js";

const getClasses = makeStyles(() => ({
  background: {
    backgroundImage: "url(" + bgImage + ")",
    height: "100vh",
  },
}));

const i18n = {
  cn: {
    title: PROJECT_NAME + "管理门户",
    user: "用户名",
    password: "密码",
    login: "登录",
    promptEmptyName: "请输入用户名",
    promptEmptyPassword: "请输入密码",
    promptError: "用户名或者密码错误",
  },
  en: {
    title: PROJECT_NAME + " Portal",
    user: "User",
    password: "Password",
    login: "Login",
    promptEmptyName: "Please input user ID",
    promptEmptyPassword: "Please input password",
    promptError: "Invalid user or password",
  },
};

const generateNonce = () => {
  const length = 16;
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export default function Login(props) {
  const { lang, setLang } = props;
  const texts = i18n[lang];
  const classes = getClasses();
  const [request, setRequest] = React.useState({
    user: "",
    password: "",
    nonce: generateNonce(),
    type: "manager",
  });
  const [errorMessage, setError] = React.useState("");
  const [systemReady, setSystemReady] = React.useState(true);
  const [initialed, setInitialed] = React.useState(false);
  const [isLogged, setLogged] = React.useState(false);

  const notifyError = React.useCallback(
    (message) => {
      const notifyElapsed = 5000;
      setError(message);
      setTimeout(() => {
        setError("");
      }, notifyElapsed);
    },
    [setError]
  );

  const handleRequestPropsChanged = (name) => (e) => {
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const onLoginFail = React.useCallback(() => {
    notifyError(texts.promptError);
  }, [notifyError]);

  const onLoginSuccess = (payload) => {
    const { timeout, tags, privileges, address } = payload;
    const sessionID = payload.session;
    let session = {
      id: sessionID,
      timeout: timeout,
      menu: tags,
      address: address,
      user: request.user,
      nonce: request.nonce,
      type: request.type,
      privileges: privileges,
    };
    saveLoggedSession(session);
    if (!isLogged) {
      setLogged(true);
      writeLog("login success");
    }
  };

  const handleLoginClick = () => {
    if (!request.user) {
      notifyError(texts.promptEmptyName);
      return;
    }
    if (!request.password) {
      notifyError(texts.promptEmptyPassword);
      return;
    }

    loginUser(
      request.user,
      request.password,
      request.nonce,
      onLoginSuccess,
      onLoginFail
    );
  };

  React.useEffect(() => {
    if (initialed) {
      return;
    }
    const onQueryStatusSuccess = (result) => {
      if (!result.initialized) {
        setSystemReady(false);
      }
      setInitialed(true);
    };

    getSystemStatus(onQueryStatusSuccess, notifyError);
  }, [initialed, notifyError]);

  let content, button;
  if (!initialed) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else if (!systemReady) {
    return <Redirect to="/initial" />;
  } else if (isLogged) {
    const PreviousKey = "previous";
    let params = new URLSearchParams(window.location.search);
    if (params.has(PreviousKey)) {
      return <Redirect to={decodeURIComponent(params.get(PreviousKey))} />;
    } else {
      return <Redirect to="/admin" />;
    }
  } else {
    let errorBar;
    if (errorMessage) {
      errorBar = (
        <GridItem xs={12}>
          <Alert variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </GridItem>
      );
    } else {
      errorBar = <GridItem xs={12} />;
    }
    button = (
      <Button color="info" onClick={handleLoginClick}>
        {texts.login}
      </Button>
    );
    content = (
      <Grid container>
        <GridItem xs={12}>
          <InputComponent
            type="text"
            label={texts.user}
            onChange={handleRequestPropsChanged("user")}
            value={request.user}
          />
        </GridItem>
        <GridItem xs={12}>
          <InputComponent
            type="password"
            label={texts.password}
            onChange={handleRequestPropsChanged("password")}
            value={request.password}
          />
        </GridItem>
        <GridItem xs={12}>
          <Box alignItems="center" display="flex" m={1}>
            {button}
            <Box flexGrow={1} />
            <LanguageSelector lang={lang} setLang={setLang} />
          </Box>
        </GridItem>
        <GridItem xs={12}>{errorBar}</GridItem>
      </Grid>
    );
  }
  return (
    <Box component="div" className={classes.background}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={10} sm={6} md={4}>
            <Box mt={20} p={0}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>{texts.title}</h4>
                </CardHeader>
                <CardBody>{content}</CardBody>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Login.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};
