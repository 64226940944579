import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import StorageIcon from "@mui/icons-material/Storage";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import { queryChains } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionListNode,
  RoleActionReadDomain,
} from "privileges";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    // back: "Back",
    // createButton: "Deploy New Contract",
    tableTitle: "Chain List",
    name: "Chain Name",
    onlinePeers: "Online Peers",
    offlinePeers: "Offline Peers",
    totalPeers: "Total Peers",
    onlineGateways: "Online Gateways",
    offlineGateways: "Offline Gateways",
    totalGateways: "Total Gateways",
    domainCount: "Domains",
    noRecord: "No Chain available",
    operates: "Operates",
    peers: "Peers",
    gateways: "Gateways",
    domain: "Domains",
    // detail: "Detail",
    // remove: "Remove",
  },
  cn: {
    // back: "Back",
    // createButton: "Deploy New Contract",
    tableTitle: "区块链列表",
    name: "链名称",
    onlinePeers: "在线节点",
    offlinePeers: "离线节点",
    totalPeers: "节点总数",
    onlineGateways: "在线网关",
    offlineGateways: "离线网关",
    totalGateways: "网关总数",
    domainCount: "数据域",
    noRecord: "无可用区块链",
    operates: "操作",
    peers: "查看节点",
    gateways: "查看网关",
    domain: "查看数据域",
    // detail: "Detail",
    // remove: "Remove",
  },
};

export default function ChainList(props) {
  const { lang } = props;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadContractList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      setDataList(result);
    };

    queryChains(onLoadSuccess, onLoadFail);
  }, [showErrorMessage, mounted]);

  const dataToNodes = (data, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const {
      name,
      online_peers,
      offline_peers,
      total_peers,
      online_gateways,
      offline_gateways,
      total_gateways,
      domains,
    } = data;
    return [
      name,
      online_peers,
      offline_peers,
      total_peers,
      online_gateways,
      offline_gateways,
      total_gateways,
      domains,
      operates,
    ];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadContractList();
    return () => {
      setMounted(false);
    };
  }, [reloadContractList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((data) => {
        const buttons = [
          {
            icon: StorageIcon,
            label: texts.peers,
            href: "/admin/chains/" + data.name + "/peers/",
            privileges: new PrivilegeRequirements().roleActions([RoleActionListNode]),
          },
          {
            icon: DeviceHubIcon,
            label: texts.gateways,
            href: "/admin/chains/" + data.name + "/gateways/",
            privileges: new PrivilegeRequirements().roleActions([RoleActionListNode]),
          },

          {
            icon: ViewQuiltIcon,
            label: texts.domain,
            href: "/admin/chains/" + data.name + "/domains/system/status",
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadDomain]),
          },
        ];
        rows.push(dataToNodes(data, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.name,
            texts.onlinePeers,
            texts.offlinePeers,
            texts.totalPeers,
            texts.onlineGateways,
            texts.offlineGateways,
            texts.totalGateways,
            texts.domainCount,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>{tableContent}</CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} m={1}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ChainList.propTypes = {
  lang: PropTypes.string.isRequired,
};
