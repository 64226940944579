import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CertificateList from "views/Identities/CertificateList";
import IdentityList from "views/Identities/IdentityList";

const i18n = {
  en: {
    tabCertificates: "Digital Certificates",
    tabIdentities: "Digital Identities",
  },
  cn: {
    tabCertificates: "数字证书",
    tabIdentities: "数字身份",
  },
};

export default function MainTabs(properties) {
  const texts = i18n[properties.lang];
  const [tab, setTab] = React.useState("certificates");

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1", bgcolor: "background.paper" }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label={texts.tabCertificates} value="certificates" />
            <Tab label={texts.tabIdentities} value="identites" />
          </TabList>
        </Box>
        <TabPanel value="certificates">
          <CertificateList lang={properties.lang} />
        </TabPanel>
        <TabPanel value="identites">
          <IdentityList lang={properties.lang} />
        </TabPanel>
      </TabContext>
      <ToastContainer autoClose={3500} draggable={false} />
    </Box>
  );
}

MainTabs.prototype = {
  lang: PropTypes.string.isRequired,
};
