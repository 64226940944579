import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ListIcon from "@mui/icons-material/List";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "components/CustomButtons/IconButton";
import { getTransaction } from "backend_api.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Detail of Transaction: ",
    back: "Back",
    listButton: "Transaction List",
    block: "Block",
    id: "Transaction ID",
    create: "Generate",
    status: "Status",
    tagValidated: "Validated",
    tagUnvalidated: "Unvalidated",
    operate: "Operate",
    world: "World Version",
    signatured: "Signatured: ",
    endorsement: "Endorse Peers",
    endorsed: "Endorsed: ",
    recordSet: "Record Set ",
    tagModifySchema: "Modify Schema: ",
    tagModifyDocument: "Modify Document: ",
    tagDeleteSchema: "Delete Schema: ",
    tagDeleteDocument: "Delete Document: ",
    version: "Version: ",
    content: "Content: ",
  },
  cn: {
    title: "交易详情： ",
    listButton: "交易列表",
    back: "返回",
    block: "所属区块",
    id: "交易ID",
    create: "生成",
    status: "交易状态",
    tagValidated: "已验证",
    tagUnvalidated: "未验证",
    operate: "触发操作",
    world: "世界状态版本",
    signatured: "已签名：",
    endorsement: "背书节点",
    endorsed: "已背书: ",
    recordSet: "数据变更",
    tagModifySchema: "变更资产类别: ",
    tagModifyDocument: "变更数字资产: ",
    tagDeleteSchema: "删除资产类别: ",
    tagDeleteDocument: "删除数字资产: ",
    version: "版本号: ",
    content: "变更内容: ",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function TransactionDetail(props) {
  const blockID = props.match.params.block;
  const transID = props.match.params.id;
  const [detailData, setDetailData] = React.useState(null);

  const showErrorMessage = React.useCallback((msg) => {
    toast.error(msg);
  }, []);

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const reloadDetail = React.useCallback(() => {
    const onGetDetailSuccess = (data) => {
      setDetailData(data);
    };
    getTransaction(
      defaultChainName,
      defaultDomainName,
      blockID,
      transID,
      onGetDetailSuccess,
      onFail
    );
  }, [onFail]);

  React.useEffect(() => {
    reloadDetail();
  }, [reloadDetail]);

  const { lang } = props;
  const texts = i18n[lang];
  let content, title;
  let buttons = [];
  if (null === detailData) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let transData = JSON.parse(detailData.content);
    let createdTime = new Date(detailData.timestamp).toLocaleString();
    let createValue;
    if ("cn" === lang) {
      createValue =
        "由节点 " + transData.invoker + " 在 " + createdTime + " 创建";
    } else {
      createValue = transData.invoker + " created at " + createdTime;
    }

    let statusChips;
    if (!detailData.validated) {
      statusChips = [
        <Chip label={texts.tagUnvalidated} color="warning" key="validated" />,
      ];
    } else {
      statusChips = [
        <Tooltip
          title={transData.hash_method + ": " + transData.hash}
          key="validated"
        >
          <Chip label={texts.tagValidated} color="success" />
        </Tooltip>,
      ];
    }
    if (transData.signature) {
      statusChips.push(
        <Tooltip
          title={transData.signature_method + ": " + transData.signature}
          key="signature"
        >
          <Chip
            label={texts.signatured + transData.signature_method}
            color="info"
          />
        </Tooltip>
      );
    }

    let tableData = [
      {
        title: texts.block,
        value: (
          <Link href={"/admin/blocks/" + detailData.block}>
            {detailData.block}
          </Link>
        ),
      },
      {
        title: texts.id,
        value: detailData.transaction,
      },
      {
        title: texts.create,
        value: createValue,
      },
      {
        title: texts.status,
        value: (
          <Stack direction="row" spacing={2}>
            {statusChips}
          </Stack>
        ),
      },
      {
        title: texts.operate,
        value: transData.method,
      },
      {
        title: texts.world,
        value: transData.world ? transData.world : "0",
      },
    ];

    let endorsers = [];
    transData.endorsements.forEach(({ endorser, signature }) => {
      endorsers.push(
        <Tooltip
          title={transData.signature_method + ": " + signature}
          key={endorser}
        >
          <Chip label={texts.endorsed + endorser} color="info" />
        </Tooltip>
      );
    });

    tableData.push({
      title: texts.endorsement,
      value: (
        <Stack direction="row" spacing={2}>
          {endorsers}
        </Stack>
      ),
    });

    if (transData.record_sets && transData.record_sets.write_set) {
      const maxLength = 100;
      transData.record_sets.write_set.forEach(
        ({ version, schema, id, content, is_delete }, index) => {
          let catalog;
          if (id) {
            catalog = (
              <div key="catalog">
                {(is_delete
                  ? texts.tagDeleteDocument
                  : texts.tagModifyDocument) +
                  schema +
                  "." +
                  id +
                  "(" +
                  texts.version +
                  version +
                  ")"}
              </div>
            );
          } else {
            catalog = (
              <div key="catalog">
                {(is_delete ? texts.tagDeleteSchema : texts.tagModifySchema) +
                  schema +
                  "(" +
                  texts.version +
                  version +
                  ")"}
              </div>
            );
          }

          let contentElement;
          if (is_delete) {
            contentElement = <div />;
          } else {
            if (content.length >= maxLength) {
              contentElement = (
                <Tooltip title={content} key="content">
                  <Typography variant="body2">
                    {texts.content + content.substring(0, maxLength) + "..."}
                  </Typography>
                </Tooltip>
              );
            } else {
              contentElement = (
                <Typography variant="body2" key="content">
                  {texts.content + content}
                </Typography>
              );
            }
          }

          tableData.push({
            title: texts.recordSet + index.toString(),
            value: (
              <Typography variant="body2">
                {catalog}
                {contentElement}
              </Typography>
            ),
          });
        }
      );
    }
    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>
                    {row.operators
                      ? row.operators.map(({ label, icon, onClick }, key) => (
                          <IconButton key={key} label={label} icon={icon} onClick={onClick} size="large" />
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );

    title = texts.title + detailData.transaction;
    buttons = [
      <Button
        key="list"
        size="sm"
        color="info"
        round
        onClick={() => {
          const listURL = "/admin/blocks/" + blockID + "/transactions/";
          window.location.assign(listURL);
        }}
      >
        <ListIcon />
        {texts.listButton}
      </Button>,
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={() => {
          props.history.goBack();
        }}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

TransactionDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
