import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import DeleteManagerDialog from "views/Access/DeleteManagerDialog";
import { queryManagers, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionUpdateManager,
  RoleActionDeleteManager,
  RoleActionCreateManager,
  RoleActionCreateIdentity,
 } from "privileges";

const i18n = {
  en: {
    name: "Manager",
    noRecord: "No manager available",
    operates: "Operates",
    addButton: "Create Manager",
    modifyButton: "Modify Groups",
    removeButton: "Delete Manager",
  },
  cn: {
    name: "管理员",
    noRecord: "暂无管理员",
    operates: "操作",
    addButton: "新建管理员",
    modifyButton: "修改所属组",
    removeButton: "删除管理员",
  },
};

export default function ManagerList(properties) {
  const recordPerPage = 10;
  const { lang, onAdd, onModify, onSuccess, onError } = properties;
  const texts = i18n[lang];
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      onError(err);
    };

    const onLoadSuccess = (result) => {
      const { managers, offset, total } = result;
      setDataList(managers);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryManagers(offset, recordPerPage, onLoadSuccess, onLoadFail);
  }, [mounted, currentPage]);

  //remove
  const showRemoveDialog = (managerID) => {
    setCurrentItem(managerID);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (managerID) => {
    closeRemoveDialog();
    onSuccess("manager " + managerID + " deleted");
    writeLog("deleted manager " + managerID);
    reloadDataList();
  };

  const dataToNodes = (name, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    return [name, operates];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: GroupsIcon,
            label: texts.modifyButton,
            onClick: () => onModify(record),
            privileges: new PrivilegeRequirements().roleActions([RoleActionUpdateManager]),
          },
          {
            onClick: () => showRemoveDialog(record),
            icon: DeleteIcon,
            label: texts.removeButton,
            privileges: new PrivilegeRequirements().roleActions([RoleActionDeleteManager]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.name, texts.operates]}
          rows={rows}
        />
      );
    }
    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }
    content = (
      <Container maxWidth="sm">
        {tableContent}
        {pagination}
      </Container>
    );
  }

  const buttons = [
    {
      onClick: onAdd,
      label: texts.addButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionCreateManager, RoleActionCreateIdentity]),
    },
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <DeleteManagerDialog
        lang={lang}
        open={removeDialogVisible}
        managerID={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
    </GridContainer>
  );
}

ManagerList.prototype = {
  onAdd: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
