import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InnerButton from "@mui/material/IconButton";

export default function IconButton(props) {
  const { label, icon, href, placement, color, onClick } = props;
  let tooltipPlacement, iconColor;
  if (placement) {
    tooltipPlacement = placement;
  } else {
    tooltipPlacement = "top";
  }
  if (color) {
    iconColor = color;
  } else {
    iconColor = "default";
  }
  let innerButton;
  if (onClick) {
    innerButton = (
      <InnerButton onClick={onClick} color={iconColor}>
        {React.createElement(icon)}
      </InnerButton>
    );
  } else if (href) {
    innerButton = (
      <Link to={href}>
        <InnerButton color={iconColor}>{React.createElement(icon)}</InnerButton>
      </Link>
    );
  } else {
    innerButton = (
      <InnerButton color={iconColor}>{React.createElement(icon)}</InnerButton>
    );
  }

  return (
    <Tooltip title={label} placement={tooltipPlacement}>
      {innerButton}
    </Tooltip>
  );
}

IconButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
  href: PropTypes.string,
  placement: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};
