import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import Button from "components/CustomButtons/Button.js";
import { InputComponent } from "components/CustomInput/InputList";
import { queryGatewayLogs } from "backend_api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    tableTitle: "Gateway Log:",
    timestamp: "Timestamp",
    content: "Content",
    noRecord: "No Log available",
    traceMode: "Auto Trace",
    tagOn: "On",
    tagOff: "Off",
  },
  cn: {
    back: "返回",
    tableTitle: "网关日志:",
    timestamp: "时间",
    content: "日志内容",
    noRecord: "无日志内容",
    traceMode: "自动跟踪",
    tagOn: "开",
    tagOff: "关",
  },
};

export default function GatewayLogs(props) {
  const DEFAULT_MAX_RECORD = 20;
  const UPDATE_INTERVAL = 3; //seconds
  const chainName = props.match.params.chain;
  const gatewayName = props.match.params.gateway;
  const recordPerPage = DEFAULT_MAX_RECORD;
  const { lang } = props;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [traceMode, setTraceMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { logs, offset, total } = result;
      setDataList(logs);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryGatewayLogs(
      chainName,
      gatewayName,
      offset,
      recordPerPage,
      onLoadSuccess,
      onLoadFail
    );
  }, [showErrorMessage, currentPage, chainName, gatewayName, mounted]);

  const dataToNodes = (content) => {
    const timestampLength = 26;
    const timestamp = content.slice(0, timestampLength);
    const log = content.slice(timestampLength + 1);
    return [timestamp, log];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const handleModeChanged = (e) => {
    let checked = e.target.checked;
    if (checked) {
      //lock to first page
      setCurrentPage(0);
    }
    setTraceMode(checked);
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    let timerID;
    if (traceMode) {
      const updateInterval = UPDATE_INTERVAL * 1000;
      timerID = setInterval(() => {
        if (!mounted) {
          return;
        }
        reloadDataList();
      }, updateInterval);
    }
    return () => {
      setMounted(false);
      if (traceMode && timerID) {
        clearInterval(timerID);
      }
    };
  }, [reloadDataList, traceMode]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((content) => {
        rows.push(dataToNodes(content));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.timestamp, texts.content]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (!traceMode && totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {texts.tableTitle + gatewayName}
          </h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={() => {
        props.history.goBack();
      }}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
    <InputComponent
      key="traceMode"
      type="switch"
      label={texts.traceMode}
      value={traceMode}
      on={texts.tagOn}
      off={texts.tagOff}
      onChange={handleModeChanged}
    />,
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} m={1}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

GatewayLogs.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
