import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import Table from "components/Table/ObjectTable.js";
import DeleteDocumentDialog from "views/Assets/DeleteDocumentDialog";
import {
  queryDocuments,
  writeLog,
  isSystemResource,
  getSchema,
} from "backend_api";
import { 
  PrivilegeRequirements,
  DomainActionReadSystemDocument,
  DomainActionUpdateSystemDocument,
  DomainActionDeleteSystemDocument,
  DomainActionReadSystemSchema,
  DomainActionReadDocument,
  DomainActionUpdateDocument,
  DomainActionDeleteDocument,
 } from "privileges";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    listButton: "Schema List",
    tableTitle: "Asset List: ",
    createButton: "Add New Asset",
    modifyButton: "Modify Asset",
    name: "Asset ID",
    content: "Content",
    version: "Latest Version",
    modified: "Modified Time",
    noRecord: "No asset available",
    operates: "Operates",
    detail: "Detail",
    remove: "Delete",
    actors: "Privileges",
    history: "Version Records",
  },
  cn: {
    back: "返回",
    listButton: "资产类别清单",
    tableTitle: "数字资产列表：",
    createButton: "添加新资产",
    modifyButton: "修改资产",
    name: "资产ID",
    content: "资产内容",
    version: "最新版本",
    modified: "修改时间",
    noRecord: "无可用资产",
    operates: "操作",
    detail: "详情",
    remove: "删除",
    actors: "管理权限",
    history: "版本历史",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function DocumentList(props) {
  const MAX_PROPERTIES = 3;
  const schemaName = props.match.params.schema;
  const recordPerPage = 10;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [displayProperties, setDisplayProperties] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentItem, setCurrentItem] = React.useState("");
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.info(msg);
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    if (!schemaName) {
      return;
    }
    let schemaData;
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };

    const onLoadSuccess = (result) => {
      const { documents, offset, total } = result;
      //choose display properties
      let properties = [];
      if (schemaData.properties) {
        schemaData.properties.every(({ name }, index) => {
          if (index >= MAX_PROPERTIES) {
            return false;
          }
          properties.push(name);
          return true;
        });
      }

      setDisplayProperties(properties);
      setDataList(documents);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
      setInitialized(true);
    };

    const onGetSuccess = (data) => {
      if (!mounted) {
        return;
      }
      schemaData = data;
      const offset = currentPage * recordPerPage;
      const filters = null;
      queryDocuments(
        chainName,
        domainName,
        schemaName,
        offset,
        recordPerPage,
        filters,
        onLoadSuccess,
        onLoadFail
      );
    };

    getSchema(chainName, domainName, schemaName, onGetSuccess, onLoadFail);
  }, [showErrorMessage, currentPage, chainName, mounted]);

  const dataToNodes = (data, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { id, content, version, modified } = data;
    let obj = JSON.parse(content);
    let rowData = [id];
    displayProperties.forEach((property) => {
      rowData.push(obj[property].toString());
    });
    rowData.push(
      version.toString(),
      new Date(modified).toLocaleString(),
      operates
    );
    return rowData;
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const showRemoveDialog = (docID) => {
    setCurrentItem(docID);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (docID) => {
    closeRemoveDialog();
    showNotifyMessage("asset " + docID + " deleted");
    writeLog("deleted asset " + schemaName + "." + docID);
    reloadDataList();
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        let read, modify, remove;
        if (isSystemResource(record.id)) {
          read = DomainActionReadSystemDocument;
          modify = DomainActionUpdateSystemDocument;
          remove = DomainActionDeleteSystemDocument;
        } else {
          read = DomainActionReadDocument;
          modify = DomainActionUpdateDocument;
          remove = DomainActionDeleteDocument;
        }
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            href: "/admin/assets/schemas/" + schemaName + "/docs/" + record.id,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            icon: HistoryIcon,
            label: texts.history,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
            href:
              "/admin/assets/schemas/" +
              schemaName +
              "/docs/" +
              record.id +
              "/logs/",
          },
          {
            icon: EditIcon,
            label: texts.modifyButton,
            privileges: new PrivilegeRequirements().defaultDomainActions([modify]),
            href:
              "/admin/assets/schemas/" +
              schemaName +
              "/docs/" +
              record.id +
              "/edit/",
          },
          {
            icon: AdminPanelSettingsIcon,
            label: texts.actors,
            href:
              "/admin/assets/schemas/" +
              schemaName +
              "/docs/" +
              record.id +
              "/actors/",
            privileges: new PrivilegeRequirements().defaultDomainActions([modify]),
          },
          {
            onClick: () => showRemoveDialog(record.id),
            icon: DeleteIcon,
            label: texts.remove,
            privileges: new PrivilegeRequirements().defaultDomainActions([remove]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      let headers = [texts.name];
      headers = headers.concat(displayProperties);
      headers.push(texts.version, texts.modified, texts.operates);
      tableContent = <Table color="primary" headers={headers} rows={rows} />;
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {texts.tableTitle + schemaName}
          </h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: () => {
        const listURL = "/admin/assets/";
        window.location.assign(listURL);
      },
      label: texts.listButton,
      icon: NavigateBeforeIcon,
      privileges: new PrivilegeRequirements().defaultDomainActions([DomainActionReadSystemSchema]),
    },
    {
      onClick: () => {
        window.location.assign(
          "/admin/assets/schemas/" + schemaName + "/create_doc/"
        );
      },
      label: texts.createButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().defaultDomainActions([DomainActionReadDocument]),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <DeleteDocumentDialog
        lang={lang}
        open={removeDialogVisible}
        chainName={chainName}
        domainName={domainName}
        schemaName={schemaName}
        docID={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

DocumentList.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
