import React from "react";
import PropTypes from "prop-types";
import ActorList from "views/Shared/ActorList";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";
import { 
    PrivilegeRequirements, 
    DomainActionUpdateSchema, 
    DomainActionUpdateSystemSchema,
 } from "privileges";
import { getSchemaActors, updateSchemaActors, isSystemResource } from "backend_api";

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function SchemaActorList(props) {
    const schemaName = props.match.params.schema;
    const { lang, history } = props;
    let title;
    if ('cn' === lang){
        title = `资产类别权限： ${schemaName}`;
    }else{
        title = `Schema Privileges: ${schemaName}`;
    }
    let requiredPrivileges;
    if (isSystemResource(schemaName)){
        requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([DomainActionUpdateSystemSchema]);
    }else{
        requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([DomainActionUpdateSchema]);
    }
    const onUpdateSuccess = () =>{
        const listURL = "/admin/assets/";
        window.location.assign(listURL);
    }
    const actorLoader = (onLoadSuccess, onLoadFail) => {
        getSchemaActors(
            defaultChainName, 
            defaultDomainName, 
            schemaName,
            onLoadSuccess, 
            onLoadFail,
        );
    };
    const actorUpdater = (actors, onUpdateFail) => {
        updateSchemaActors(
            defaultChainName, 
            defaultDomainName, 
            schemaName,
            actors,
            onUpdateSuccess, 
            onUpdateFail,
        );
    };
    return (
        <ActorList
            lang={lang}
            title={title}
            history={history}
            loader={actorLoader}
            updater={actorUpdater}
            updatePrivileges={requiredPrivileges}
            />
    )
}

SchemaActorList.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
    lang: PropTypes.string.isRequired,
};