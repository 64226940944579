import React from "react";
import PropTypes from "prop-types";
import InputList from "components/CustomInput/InputList";
import CustomDialog from "components/Dialog/CustomDialog.js";
import { updatePeerCertificate } from "backend_api";

const i18n = {
  en: {
    title: "Update Certificate of Peer: ",
    labelMonth: "Validity(in Month)",
    cancel: "Cancel",
    confirm: "Confirm",
  },
  cn: {
    title: "更新节点证书：",
    labelMonth: "有效期（月）",
    cancel: "取消",
    confirm: "确定",
  },
};

export default function UpdateCertificateDialog(props) {
  const defaultValues = {
    month: "",
  };
  const { lang, open, chainName, peerName, onSuccess, onCancel } = props;
  const [operatable, setOperatable] = React.useState(true);
  const [prompt, setPrompt] = React.useState("");
  const [request, setRequest] = React.useState(defaultValues);
  const texts = i18n[lang];
  const title = texts.title + peerName;
  const onOperateFail = (msg) => {
    setOperatable(true);
    setPrompt(msg);
  };

  const resetDialog = () => {
    setPrompt("");
    setRequest(defaultValues);
  };

  const closeDialog = () => {
    resetDialog();
    onCancel();
  };

  const onOperateSuccess = () => {
    setOperatable(true);
    resetDialog();
    onSuccess(peerName);
  };

  const handleConfirm = () => {
    setOperatable(false);
    if (!request.month || "" === request.month) {
      onOperateFail("Validity month required");
      return;
    }
    let validityMonth = parseInt(request.month, 10);
    if (isNaN(validityMonth)) {
      onOperateFail("invalid month: " + request.month);
      return;
    }
    updatePeerCertificate(
      chainName,
      peerName,
      validityMonth,
      onOperateSuccess,
      onOperateFail
    );
  };

  const handleRequestPropsChanged = (name) => (e) => {
    let value = e.target.value;
    setRequest((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const inputs = [
    {
      type: "text",
      label: texts.labelMonth,
      onChange: handleRequestPropsChanged("month"),
      value: request.month,
      required: true,
      oneRow: true,
      xs: 6,
    },
  ];
  const content = <InputList inputs={inputs} />;
  const buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: closeDialog,
    },
    {
      color: "info",
      label: texts.confirm,
      onClick: handleConfirm,
    },
  ];

  return (
    <CustomDialog
      size="xs"
      open={open}
      prompt={prompt}
      title={title}
      buttons={buttons}
      content={content}
      operatable={operatable}
    />
  );
}

UpdateCertificateDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  chainName: PropTypes.string.isRequired,
  peerName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
