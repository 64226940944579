import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CustomDialog from "components/Dialog/CustomDialog.js";

const i18n = {
  en: {
    cancel: "Cancel",
    confirm: "Confirm",
    placeHolder: "Input content here",
  },
  cn: {
    cancel: "取消",
    confirm: "确定",
    placeHolder: "请输入导入内容",
  },
};

export default function ParseContentDialog(props) {
  const { lang, open, title, label, parser, onSuccess, onCancel } = props;
  const texts = i18n[lang];
  const rows = 10;
  const [content, setContent] = React.useState('');

  const showErrorMessage = msg => {
    toast.error(msg);
  }

  const closeDialog = () =>{
    setContent("");
    onCancel();
  }

  const onConfirm = () => {
    try {
      let result = parser(content);
      setContent("");
      onSuccess(result);
    } catch (e) {
      showErrorMessage(e.message);
    }
  }

  const handleTextChanged = e =>{
    setContent(e.target.value);
  }

  const buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: closeDialog,
    },
    {
      color: "info",
      label: texts.confirm,
      onClick: onConfirm,
    },
  ];

  let components = (
    <Box m={0} pt={2}>
      <TextField
        label={label}
        onChange={handleTextChanged}
        value={content}
        margin="normal"
        rows={rows}
        placeholder={texts.placeHolder}
        color="info"
        focused
        autoFocus
        multiline
        fullWidth
      />
    </Box>
  );

  return (
    <CustomDialog
      size="md"
      open={open}
      prompt=""
      title={title}
      buttons={buttons}
      content={components}
      operatable={true}
    />
  );
}

ParseContentDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  parser: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
