import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function CardContainer(props) {
  const {color, title, components} = props;
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color={color ? color : 'primary'}>
        <h4 className={classes.cardTitleWhite}>{title}</h4>
      </CardHeader>
      <CardBody>
        {components}
      </CardBody>
    </Card>
  );
}

CardContainer.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  components: PropTypes.node.isRequired,
};
