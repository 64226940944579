//Domain Actions
export const DomainActionReadSystemSchema = "read_system_schema"
export const DomainActionCreateSystemSchema = "create_system_schema"
export const DomainActionDeleteSystemSchema = "delete_system_schema"
export const DomainActionUpdateSystemSchema = "update_system_schema"
export const DomainActionListSystemSchema = "list_system_schema"
export const DomainActionReadSchema = "read_schema"
export const DomainActionCreateSchema = "create_schema"
export const DomainActionDeleteSchema = "delete_schema"
export const DomainActionUpdateSchema = "update_schema"
export const DomainActionListSchema = "list_schema"
export const DomainActionReadSystemDocument = "read_system_doc"
export const DomainActionCreateSystemDocument = "create_system_doc"
export const DomainActionDeleteSystemDocument = "delete_system_doc"
export const DomainActionUpdateSystemDocument = "update_system_doc"
export const DomainActionListSystemDocument = "list_system_doc"
export const DomainActionReadDocument = "read_doc"
export const DomainActionCreateDocument = "create_doc"
export const DomainActionDeleteDocument = "delete_doc"
export const DomainActionUpdateDocument = "update_doc"
export const DomainActionListDocument = "list_doc"
export const DomainActionReadSystemContract = "read_system_contract"
export const DomainActionDeploySystemContract = "deploy_system_contract"
export const DomainActionRevokeSystemContract = "revoke_system_contract"
export const DomainActionExecuteSystemContract = "execute_system_contract"
export const DomainActionListSystemContract = "list_system_contract"
export const DomainActionReadContract = "read_contract"
export const DomainActionDeployContract = "deploy_contract"
export const DomainActionRevokeContract = "revoke_contract"
export const DomainActionExecuteContract = "execute_contract"
export const DomainActionListContract = "list_contract"
//Role Actions
export const RoleActionCreateChain = "create_chain"
export const RoleActionUpdateChain = "update_chain"
export const RoleActionDeleteChain = "delete_chain"
export const RoleActionReadChain = "read_chain"
export const RoleActionListChain = "list_chain"
export const RoleActionReadNode = "read_node"
export const RoleActionAddNode = "add_node"
export const RoleActionRemoveNode = "remove_node"
export const RoleActionUpdateNode = "update_node"
export const RoleActionListNode = "list_node"
export const RoleActionReadDomain = "read_domain"
export const RoleActionCreateDomain = "create_domain"
export const RoleActionDeleteDomain = "delete_domain"
export const RoleActionUpdateDomain = "update_domain"
export const RoleActionListDomain = "list_domain"
export const RoleActionReadApplication = "read_app"
export const RoleActionAddApplication = "add_app"
export const RoleActionRemoveApplication = "remove_app"
export const RoleActionUpdateApplication = "update_app"
export const RoleActionListApplication = "list_app"
export const RoleActionReadPerson = "read_person"
export const RoleActionCreatePerson = "create_person"
export const RoleActionDeletePerson = "delete_person"
export const RoleActionUpdatePerson = "update_person"
export const RoleActionListPerson = "list_person"
export const RoleActionReadManager = "read_manager"
export const RoleActionCreateManager = "create_manager"
export const RoleActionDeleteManager = "delete_manager"
export const RoleActionUpdateManager = "update_manager"
export const RoleActionListManager = "list_manager"
export const RoleActionReadRole = "read_role"
export const RoleActionCreateRole = "create_role"
export const RoleActionDeleteRole = "delete_role"
export const RoleActionUpdateRole = "update_role"
export const RoleActionListRole = "list_role"
export const RoleActionReadIdentity = "read_identity"
export const RoleActionCreateIdentity = "create_identity"
export const RoleActionDeleteIdentity = "delete_identity"
export const RoleActionUpdateIdentity = "update_identity"
export const RoleActionListIdentity = "list_identity"
export const RoleActionListLog = "list_log"
export const RoleActionAddLog = "add_log"
export const RoleActionRemoveLog = "remove_log"


const PRIVILEGE_LABELS = {
  cn: {
    group_chain: "链配置管理",
    [RoleActionCreateChain]: "创建链配置",
    [RoleActionUpdateChain]: "修改链配置",
    [RoleActionDeleteChain]: "删除链配置",
    [RoleActionReadChain]: "读取链配置",
    [RoleActionListChain]: "查询链清单",
    [RoleActionCreateDomain]: "创建数据域",
    [RoleActionUpdateDomain]: "修改数据域",
    [RoleActionDeleteDomain]: "删除数据域",
    [RoleActionReadDomain]: "读取数据域",
    [RoleActionListDomain]: "查询数据域",
    [RoleActionAddNode]: "添加节点",
    [RoleActionUpdateNode]: "修改节点",
    [RoleActionRemoveNode]: "移除节点",
    [RoleActionReadNode]: "读取节点",
    [RoleActionListNode]: "查询节点",
    group_process: "业务处理",
    [DomainActionDeploySystemContract]: "部署系统合约",
    [DomainActionRevokeSystemContract]: "撤销系统合约",
    [DomainActionExecuteSystemContract]: "执行系统合约",
    [DomainActionReadSystemContract]: "读取系统合约",
    [DomainActionListSystemContract]: "查询系统合约",
    [DomainActionDeployContract]: "部署合约",
    [DomainActionRevokeContract]: "撤销合约",
    [DomainActionExecuteContract]: "执行合约",
    [DomainActionReadContract]: "读取合约",
    [DomainActionListContract]: "查询合约",
    group_asset: "数字资产",
    [DomainActionCreateSystemSchema]: "创建系统类别",
    [DomainActionUpdateSystemSchema]: "更新系统类别",
    [DomainActionDeleteSystemSchema]: "删除系统类别",
    [DomainActionReadSystemSchema]: "读取系统类别",
    [DomainActionListSystemSchema]: "查询系统类别",
    [DomainActionCreateSchema]: "创建资产类别",
    [DomainActionUpdateSchema]: "更新资产类别",
    [DomainActionDeleteSchema]: "删除资产类别",
    [DomainActionReadSchema]: "读取资产类别",
    [DomainActionListSchema]: "查询资产类别",
    [DomainActionCreateSystemDocument]: "创建系统资产",
    [DomainActionUpdateSystemDocument]: "更新系统资产",
    [DomainActionDeleteSystemDocument]: "删除系统资产",
    [DomainActionReadSystemDocument]: "读取系统资产",
    [DomainActionListSystemDocument]: "查询系统资产",
    [DomainActionCreateDocument]: "创建数字资产",
    [DomainActionUpdateDocument]: "更新数字资产",
    [DomainActionDeleteDocument]: "删除数字资产",
    [DomainActionReadDocument]: "查看数字资产",
    [DomainActionListDocument]: "查询数字资产",
    group_system: "系统配置",
    [RoleActionAddApplication]: "添加应用",
    [RoleActionUpdateApplication]: "更新应用",
    [RoleActionRemoveApplication]: "移除应用",
    [RoleActionReadApplication]: "读取应用",
    [RoleActionListApplication]: "查询应用清单",
    [RoleActionReadRole]: "读取角色",
    [RoleActionCreateRole]: "创建角色",
    [RoleActionUpdateRole]: "更新角色",
    [RoleActionDeleteRole]: "删除角色",
    [RoleActionListRole]: "查询角色清单",
    [RoleActionReadIdentity]: "读取数字证书",
    [RoleActionCreateIdentity]: "创建数字证书",
    [RoleActionUpdateIdentity]: "更新数字证书",
    [RoleActionDeleteIdentity]: "删除数字证书",
    [RoleActionListIdentity]: "查询数字证书",
    [RoleActionReadManager]: "读取管理员",
    [RoleActionCreateManager]: "创建管理员",
    [RoleActionUpdateManager]: "更新管理员",
    [RoleActionDeleteManager]: "删除管理员",
    [RoleActionListManager]: "查询管理员",
    [RoleActionReadPerson]: "读取个人用户",
    [RoleActionCreatePerson]: "创建个人用户",
    [RoleActionUpdatePerson]: "更新个人用户",
    [RoleActionDeletePerson]: "删除个人用户",
    [RoleActionListPerson]: "查询个人用户",
    [RoleActionAddLog]: "添加日志",
    [RoleActionRemoveLog]: "移除日志",
    [RoleActionListLog]: "查询日志",
  },
  en: {
    group_chain: "Chain Management",
    [RoleActionCreateChain]: "Create Chain",
    [RoleActionUpdateChain]: "Update Chain",
    [RoleActionDeleteChain]: "Delete Chain",
    [RoleActionReadChain]: "Read Chain",
    [RoleActionListChain]: "Query Chains",
    [RoleActionCreateDomain]: "Create Domain",
    [RoleActionUpdateDomain]: "Update Domain",
    [RoleActionDeleteDomain]: "Delete Domain",
    [RoleActionReadDomain]: "Read Domain",
    [RoleActionListDomain]: "Query Domains",
    [RoleActionAddNode]: "Add Node",
    [RoleActionUpdateNode]: "Update Node",
    [RoleActionRemoveNode]: "Remove Node",
    [RoleActionReadNode]: "View Node",
    [RoleActionListNode]: "Query Nodes",
    group_process: "Process Flow",
    [DomainActionDeploySystemContract]: "Deploy System Contract",
    [DomainActionRevokeSystemContract]: "Revoke System Contract",
    [DomainActionExecuteSystemContract]: "Execute System Contract",
    [DomainActionReadSystemContract]: "View System Contract",
    [DomainActionListSystemContract]: "Query System Contracts",
    [DomainActionDeployContract]: "Deploy Contract",
    [DomainActionRevokeContract]: "Revoke Contract",
    [DomainActionExecuteContract]: "Execute Contract",
    [DomainActionReadContract]: "View Contract",
    [DomainActionListContract]: "Query Contracts",
    group_asset: "Ditigal Asset",
    [DomainActionCreateSystemSchema]: "Create System Catalog",
    [DomainActionUpdateSystemSchema]: "Update System Catalog",
    [DomainActionDeleteSystemSchema]: "Delete System Catalog",
    [DomainActionReadSystemSchema]: "View System Catalog",
    [DomainActionListSystemSchema]: "Query System Catalogs",
    [DomainActionCreateSchema]: "Create Asset Catalog",
    [DomainActionUpdateSchema]: "Update Asset Catalog",
    [DomainActionDeleteSchema]: "Delete Asset Catalog",
    [DomainActionReadSchema]: "View Asset Catalog",
    [DomainActionListSchema]: "Query Asset Catalogs",
    [DomainActionCreateSystemDocument]: "Create System Asset",
    [DomainActionUpdateSystemDocument]: "Update System Asset",
    [DomainActionDeleteSystemDocument]: "Delete System Asset",
    [DomainActionReadSystemDocument]: "View System Asset",
    [DomainActionListSystemDocument]: "Query System Assets",
    [DomainActionCreateDocument]: "Create Digital Asset",
    [DomainActionUpdateDocument]: "Update Digital Asset",
    [DomainActionDeleteDocument]: "Delete Digital Asset",
    [DomainActionReadDocument]: "View Digital Asset",
    [DomainActionListDocument]: "Query Digital Assets",
    group_system: "System Configure",
    [RoleActionAddApplication]: "Add Application",
    [RoleActionUpdateApplication]: "Update Application",
    [RoleActionRemoveApplication]: "Remove Application",
    [RoleActionReadApplication]: "Read Application",
    [RoleActionListApplication]: "Query Applications",
    [RoleActionReadRole]: "Read Role",
    [RoleActionCreateRole]: "Create Role",
    [RoleActionUpdateRole]: "Update Role",
    [RoleActionDeleteRole]: "Delete Role",
    [RoleActionListRole]: "Query Roles",
    [RoleActionReadIdentity]: "Read Digital Identity",
    [RoleActionCreateIdentity]: "Create Digital Identity",
    [RoleActionUpdateIdentity]: "Update Digital Identity",
    [RoleActionDeleteIdentity]: "Delete Digital Identity",
    [RoleActionListIdentity]: "Query Digital Identities",
    [RoleActionReadManager]: "Read Manager",
    [RoleActionCreateManager]: "Create Manager",
    [RoleActionUpdateManager]: "Update Manager",
    [RoleActionDeleteManager]: "Delete Manager",
    [RoleActionListManager]: "Query Managers",
    [RoleActionReadPerson]: "Read Person",
    [RoleActionCreatePerson]: "Create Person",
    [RoleActionUpdatePerson]: "Update Person",
    [RoleActionDeletePerson]: "Delete Person",
    [RoleActionListPerson]: "Query People",
    [RoleActionAddLog]: "Add Log",
    [RoleActionRemoveLog]: "Remove Log",
    [RoleActionListLog]: "Query Logs",
  },
};

const ROLE_PRIVILEGE_GROUPS = [
  {
    group: "group_chain",
    privileges: [
      RoleActionCreateChain,
      RoleActionUpdateChain,
      RoleActionDeleteChain,
      RoleActionReadChain,
      RoleActionListChain,
      RoleActionCreateDomain,
      RoleActionUpdateDomain,
      RoleActionDeleteDomain,
      RoleActionReadDomain,
      RoleActionListDomain,
      RoleActionAddNode,
      RoleActionUpdateNode,
      RoleActionRemoveNode,
      RoleActionReadNode,
      RoleActionListNode,
    ],
  },
  {
    group: "group_system",
    privileges: [
      RoleActionAddApplication,
      RoleActionUpdateApplication,
      RoleActionRemoveApplication,
      RoleActionReadApplication,
      RoleActionListApplication,
      RoleActionReadRole,
      RoleActionCreateRole,
      RoleActionUpdateRole,
      RoleActionDeleteRole,
      RoleActionListRole,
      RoleActionReadIdentity,
      RoleActionCreateIdentity,
      RoleActionUpdateIdentity,
      RoleActionDeleteIdentity,
      RoleActionListIdentity,
      RoleActionReadManager,
      RoleActionCreateManager,
      RoleActionUpdateManager,
      RoleActionDeleteManager,
      RoleActionListManager,
      RoleActionReadPerson,
      RoleActionCreatePerson,
      RoleActionUpdatePerson,
      RoleActionDeletePerson,
      RoleActionListPerson,
      RoleActionAddLog,
      RoleActionRemoveLog,
      RoleActionListLog,
    ],
  },
];

const DOMAIN_PRIVILEGE_GROUPS = [
  {
    group: "group_process",
    privileges: [
      DomainActionDeploySystemContract,
      DomainActionRevokeSystemContract,
      DomainActionExecuteSystemContract,
      DomainActionReadSystemContract,
      DomainActionListSystemContract,
      DomainActionDeployContract,
      DomainActionRevokeContract,
      DomainActionExecuteContract,
      DomainActionReadContract,
      DomainActionListContract,
    ],
  },
  {
    group: "group_asset",
    privileges: [
      DomainActionCreateSystemSchema,
      DomainActionUpdateSystemSchema,
      DomainActionDeleteSystemSchema,
      DomainActionReadSystemSchema,
      DomainActionListSystemSchema,
      DomainActionCreateSchema,
      DomainActionUpdateSchema,
      DomainActionDeleteSchema,
      DomainActionReadSchema,
      DomainActionListSchema,
      DomainActionCreateSystemDocument,
      DomainActionUpdateSystemDocument,
      DomainActionDeleteSystemDocument,
      DomainActionReadSystemDocument,
      DomainActionListSystemDocument,
      DomainActionCreateDocument,
      DomainActionUpdateDocument,
      DomainActionDeleteDocument,
      DomainActionReadDocument,
      DomainActionListDocument,
    ],
  },
];

export function getPrivilegeLabels(lang, valueList) {
  let privileges;
  if (Object.prototype.hasOwnProperty.call(PRIVILEGE_LABELS, lang)) {
    privileges = PRIVILEGE_LABELS[lang];
  } else {
    privileges = PRIVILEGE_LABELS["cn"];
  }
  return valueList.map((value) => {
    if (Object.prototype.hasOwnProperty.call(privileges, value)) {
      return privileges[value];
    } else {
      return "unknown privilege: " + value;
    }
  });
}

export function getRolePrivileges(lang) {
  let labels;
  if (Object.prototype.hasOwnProperty.call(PRIVILEGE_LABELS, lang)) {
    labels = PRIVILEGE_LABELS[lang];
  } else {
    labels = PRIVILEGE_LABELS["cn"];
  }
  let values = {};
  let groups = ROLE_PRIVILEGE_GROUPS.map(({ group, privileges }) => {
    let groupLabel;
    if (Object.prototype.hasOwnProperty.call(labels, group)) {
      groupLabel = labels[group];
    } else {
      groupLabel = "unknown group: " + group;
    }
    let options = privileges.map((value) => {
      let valueLabel;
      if (Object.prototype.hasOwnProperty.call(labels, value)) {
        valueLabel = labels[value];
      } else {
        valueLabel = "unknown privilege: " + value;
      }
      values[value] = false;
      return {
        label: valueLabel,
        value: value,
      };
    });
    return {
      group: groupLabel,
      options: options,
    };
  });
  return {
    groups: groups,
    values: values,
  };
}

export function getDomainPrivileges(lang) {
  let labels;
  if (Object.prototype.hasOwnProperty.call(PRIVILEGE_LABELS, lang)) {
    labels = PRIVILEGE_LABELS[lang];
  } else {
    labels = PRIVILEGE_LABELS["cn"];
  }
  let values = {};
  let groups = DOMAIN_PRIVILEGE_GROUPS.map(({ group, privileges }) => {
    let groupLabel;
    if (Object.prototype.hasOwnProperty.call(labels, group)) {
      groupLabel = labels[group];
    } else {
      groupLabel = "unknown group: " + group;
    }
    let options = privileges.map((value) => {
      let valueLabel;
      if (Object.prototype.hasOwnProperty.call(labels, value)) {
        valueLabel = labels[value];
      } else {
        valueLabel = "unknown privilege: " + value;
      }
      values[value] = false;
      return {
        label: valueLabel,
        value: value,
      };
    });
    return {
      group: groupLabel,
      options: options,
    };
  });
  return {
    groups: groups,
    values: values,
  };
}

export function parseRolePrivileges(mappedValues) {
  let result = [];
  ROLE_PRIVILEGE_GROUPS.forEach(({ privileges }) => {
    privileges.forEach((privilege) => {
      if (
        Object.prototype.hasOwnProperty.call(mappedValues, privilege) &&
        mappedValues[privilege]
      ) {
        result.push(privilege);
      }
    });
  });
  return result;
}

export function parseDomainPrivileges(mappedValues) {
  let result = [];
  DOMAIN_PRIVILEGE_GROUPS.forEach(({ privileges }) => {
    privileges.forEach((privilege) => {
      if (
        Object.prototype.hasOwnProperty.call(mappedValues, privilege) &&
        mappedValues[privilege]
      ) {
        result.push(privilege);
      }
    });
  });
  return result;
}

export class PrivilegeRequirements {
  constructor() {
    this.role_actions = [];
    this.domains = new Map();
  }

  roleActions(actions) {
    this.role_actions = this.role_actions.concat(actions);
    return this;
  }

  domainActions(domainName, actions) {
    if (this.domains.has(domainName)) {
      //concat
      let currentActions = this.domains.get(domainName);
      let allActions = currentActions.concat(actions);
      this.domains.set(domainName, allActions);
    } else {
      this.domains.set(domainName, actions);
    }
    return this;
  }

  defaultDomainActions(actions) {
    this.domainActions('system', actions);
    return this;
  }

  meetRequirements(privileges) {
    if (!this.role_actions.every(action => {
      return privileges.actions.some(value => value === action);
    })) {
      return false;
    }
    //check domain actions
    for (const [domainName, required_actions] of this.domains.entries()) {
      if (!privileges.domain_privileges.some(domain => {
        const { name, actions } = domain;
        if (name === domainName) {
          return required_actions.every(required => actions.some(available => available === required));
        }
        return false;
      })) {
        return false;
      }
    }
    return true;
  }
}
