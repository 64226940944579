import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "components/CustomButtons/IconButton.js";
import { hasPrivileges } from "utils";

export default function AuthenticatedIconButtons(props) {
  let { color, buttons } = props;
  if (!color) {
    color = "secondary";
  }
  let components = [];
  buttons.forEach(({ label, icon, privileges, ...rest }, key) => {
    if (hasPrivileges(privileges)) {
      components.push(
        <Box
          key={key}
          sx={{
            m: 0,
            p: 0,
          }}
        >
          <IconButton color={color} icon={icon} label={label} {...rest} size="large" />
        </Box>
      );
    }
  });
  return <Box display="flex">{components}</Box>;
}

AuthenticatedIconButtons.propTypes = {
  color: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
};
