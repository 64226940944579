import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import InnerBackdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Backdrop(props) {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <InnerBackdrop className={classes.backdrop} {...rest}>
      {children}
    </InnerBackdrop>
  );
}

Backdrop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
