import React from "react";
import PropTypes from "prop-types";
import CustomDialog from "components/Dialog/CustomDialog.js";

const i18n = {
  en: {
    title: "Remove Actor Group",
    content: "Are you sure to remove group ",
    cancel: "Cancel",
    confirm: "Confirm",
  },
  cn: {
    title: "删除权限组",
    content: "是否删除权限组 ",
    cancel: "取消",
    confirm: "确定",
  },
};

export default function RemoveActorGroupDialog(props) {
  const { lang, open, groupName, groupIndex, onConfirm, onCancel } = props;
  const texts = i18n[lang];
  const title = texts.title;
  const content = texts.content + groupName;
  const buttons = [
    {
      color: "transparent",
      label: texts.cancel,
      onClick: onCancel,
    },
    {
      color: "info",
      label: texts.confirm,
      onClick: () => onConfirm(groupIndex),
    },
  ];

  return (
    <CustomDialog
      size="xs"
      open={open}
      prompt=""
      title={title}
      buttons={buttons}
      content={content}
      operatable={true}
    />
  );
}

RemoveActorGroupDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
