import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import makeStyles from '@mui/styles/makeStyles';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "@mui/material/Skeleton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/ObjectTable.js";
import { InputComponent } from "components/CustomInput/InputList";
import ActionDescription from "views/Contracts/ActionDescription";
import { getContract } from "backend_api";
import { getActionDefines } from "contract_define";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    tableTitle: "Content of Contract: ",
    segmentInput: "Input Parameters",
    name: "Parameter Name",
    description: "Description",
    segmentSteps: "Steps",
    step: "Step",
    action: "Action",
    parameters: "Parameters",
    noRecord: "No step available",
    operates: "Operates",
    rawMode: "Raw Mode",
    tagOn: "On",
    tagOff: "Off",
    copied: "Contract content copied",
    copyButton: "Copy To ClipBoard",
    addButton: "Add Step",
    removeButton: "Remove Step",
    modifyButton: "Modify Step",
    moveUpButton: "Move Up",
    moveDownButteon: "Move Down",
    promptNoParameters: "No parameter configured",
  },
  cn: {
    back: "返回",
    tableTitle: "合约内容: ",
    segmentInput: "调用参数",
    name: "参数名",
    description: "描述",
    segmentSteps: "合约步骤",
    step: "步骤",
    action: "动作",
    parameters: "参数",
    noRecord: "无有效步骤",
    operates: "操作",
    rawMode: "源码模式",
    tagOn: "打开",
    tagOff: "关闭",
    copied: "合约内容已复制",
    copyButton: "复制到剪切板",
    addButton: "添加步骤",
    removeButton: "删除步骤",
    modifyButton: "修改步骤",
    moveUpButton: "上移",
    moveDownButteon: "下移",
    promptNoParameters: "未配置调用参数",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function ContractDetail(props) {
  const contractName = props.match.params.contract;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [rawMode, setRawMode] = React.useState(false);

  const actionDefines = getActionDefines(lang);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.success(msg);
  };

  const handleModeChanged = (e) => {
    let checked = e.target.checked;
    setRawMode(checked);
  };

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { content } = result;
      let contentObject = JSON.parse(content);
      if (!contentObject.parameters) {
        contentObject.parameters = [];
      }
      setData(contentObject);
      setInitialized(true);
    };

    getContract(chainName, domainName, contractName, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, contractName, chainName, mounted]);

  const dataToNodes = (index, step) => {
    const { action, params } = step;
    const stepText = (index + 1).toString();
    let actionName = "";
    let actionContent = "";
    if (!rawMode) {
      actionName = actionDefines[action].name;
      actionContent = (
        <ActionDescription
          lang={lang}
          parameters={params}
          inputs={data.parameters}
          action={action}
        />
      );
    } else {
      actionName = action;
      if (params && 0 !== params.length) {
        actionContent = "[ '" + params.join("', '") + "' ]";
      }
    }
    return [stepText, actionName, actionContent];
  };

  const mapParameters = (parameter) => {
    const { name, description } = parameter;
    return [name, description];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let parameterList;
    if (!data.parameters || 0 === data.parameters.length) {
      parameterList = (
        <Box sx={{ m: 2, pl: 1 }}>
          <Typography variant="body2">{texts.promptNoParameters}</Typography>
        </Box>
      );
    } else {
      let parameterRows = data.parameters.map((parameter, index) =>
        mapParameters(parameter, index)
      );
      let parameterHeaders = [texts.name, texts.description];
      parameterList = (
        <Box sx={{ ml: 2 }}>
          <GridContainer>
            <GridItem key="name" xs={12} sm={8} md={6}>
              <Table
                color="primary"
                headers={parameterHeaders}
                rows={parameterRows}
              />
            </GridItem>
          </GridContainer>
        </Box>
      );
    }

    let tableContent;
    if (!data || 0 === data.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      data.steps.forEach((record, index) => {
        rows.push(dataToNodes(index, record));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.step, texts.action, texts.parameters]}
          rows={rows}
        />
      );
    }
    const rawComponent = (
      <InputComponent
        key="rawMode"
        type="switch"
        label={texts.rawMode}
        value={rawMode}
        on={texts.tagOn}
        off={texts.tagOff}
        onChange={handleModeChanged}
      />
    );
    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {texts.tableTitle + contractName}
          </h4>
        </CardHeader>
        <CardBody>
          <Stack divider={<Divider />} spacing={2}>
            <Box>
              <Typography variant="subtitle1">{texts.segmentInput}</Typography>
              {parameterList}
            </Box>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">
                    {texts.segmentSteps}
                  </Typography>
                </Box>
                <Box sx={{ pr: 10, mr: 1 }}>{rawComponent}</Box>
              </Box>
              <Box sx={{ m: 2 }}>{tableContent}</Box>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={() => {
        props.history.goBack();
      }}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
    <CopyToClipboard
      key="copy"
      text={JSON.stringify(data)}
      onCopy={() => showNotifyMessage(texts.copied)}
    >
      <Button size="sm" color="info" round>
        <ContentCopyIcon />
        {texts.copyButton}
      </Button>
    </CopyToClipboard>,
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} sx={{ m: 1 }}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ContractDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
