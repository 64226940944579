import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { InputComponent } from "components/CustomInput/InputList";
import { modifyGroup, getGroup, writeLog } from "backend_api.js";

const i18n = {
  en: {
    title: "Modify Group - ",
    back: "Back",
    name: "Group Name",
    description: "Description",
    submitButton: "Submit",
  },
  cn: {
    title: "修改用户组 - ",
    back: "返回",
    name: "组名",
    description: "描述",
    submitButton: "提交变更",
  },
};

export default function ModifyGroup(props) {
  const { lang, groupName, onList } = props;
  const texts = i18n[lang];
  const defaultValues = {
    description: "",
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleDescriptionChanged = (e) => {
    let description = e.target.value;
    setRequest((previous) => ({
      ...previous,
      description: description,
    }));
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified group " + groupName);
      toast.success("group " + groupName + " modified");
      onList();
    };

    //format privileges

    modifyGroup(groupName, request.description, onOperateSuccess, onFail);
  };

  React.useEffect(() => {
    setMounted(true);
    if (!groupName) {
      return;
    }
    const onGetSuccess = (data) => {
      if (!mounted) {
        return;
      }
      const { description } = data;
      let request = {
        description: description,
      };
      setRequest(request);
      setInitialized(true);
    };

    getGroup(groupName, onGetSuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, groupName, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const descriptionInput = (
      <InputComponent
        type="textarea"
        label={texts.description}
        value={request.description}
        onChange={handleDescriptionChanged}
        xs={8}
        md={6}
        lg={4}
      />
    );

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Box>{descriptionInput}</Box>
      </Stack>
    );

    title = texts.title + groupName;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

ModifyGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
