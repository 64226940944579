import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "components/CustomButtons/Button.js";
import { hasPrivileges } from "utils";

export default function AuthenticatedButtons(props) {
  let { color, size, buttons } = props;
  if (!color) {
    color = "info";
  }
  if (!size) {
    size = "sm";
  }
  let components = [];
  buttons.forEach(({ label, icon, onClick, privileges }, key) => {
    if (hasPrivileges(privileges)) {
      components.push(
        <Box key={key} m={1}>
          <Button size={size} color={color} round onClick={onClick}>
            {React.createElement(icon)}
            {label}
          </Button>
        </Box>
      );
    }
  });
  return <Box display="flex">{components}</Box>;
}

AuthenticatedButtons.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
};
