import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import WidgetsIcon from "@mui/icons-material/Widgets";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import DeleteAppGroupDialog from "views/Applications/DeleteAppGroupDialog";
import { queryApplicationGroups, writeLog } from "backend_api";
import { 
  PrivilegeRequirements,
  RoleActionReadApplication,
  RoleActionUpdateApplication,
  RoleActionRemoveApplication,
  RoleActionAddApplication,
  RoleActionCreateIdentity,
  RoleActionListApplication,
 } from "privileges";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    name: "Application Group Name",
    tableTitle: "Application Groups",
    description: "Description",
    noRecord: "No application group available",
    operates: "Operates",
    addButton: "Create New Group",
    appButton: "Manage Applications",
    modifyButton: "Modify",
    removeButton: "Remove",
    rolesButton: "Roles",
    membersButton: "Applications in group",
  },
  cn: {
    name: "应用组名",
    tableTitle: "链应用组",
    description: "描述",
    noRecord: "暂无应用组",
    operates: "操作",
    addButton: "创建应用组",
    appButton: "管理链应用",
    modifyButton: "修改",
    removeButton: "删除",
    rolesButton: "权限管理",
    membersButton: "所属应用",
  },
};

export default function AppGroupList(properties) {
  const { lang, onAdd, onModify, onRoles, onMembers } = properties;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const showNotifyMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.success(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { groups } = result;
      setDataList(groups);
    };

    queryApplicationGroups(onLoadSuccess, onLoadFail);
  }, [showErrorMessage, mounted]);

  //remove
  const showRemoveDialog = (groupName) => {
    setCurrentItem(groupName);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (groupName) => {
    closeRemoveDialog();
    showNotifyMessage("group " + groupName + " removed");
    writeLog("deleted group " + groupName);
    reloadDataList();
  };

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { name, description } = record;
    return [name, description, operates];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        const buttons = [
          {
            icon: WidgetsIcon,
            label: texts.membersButton,
            onClick: () => onMembers(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication]),
          },
          {
            icon: AccountTreeIcon,
            label: texts.rolesButton,
            onClick: () => onRoles(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication, RoleActionUpdateApplication]),
          },
          {
            icon: EditIcon,
            label: texts.modifyButton,
            onClick: () => onModify(record.name),
            privileges: new PrivilegeRequirements().roleActions([RoleActionReadApplication, RoleActionUpdateApplication]),
          },
          {
            onClick: () => showRemoveDialog(record.name),
            icon: DeleteIcon,
            label: texts.removeButton,
            privileges: new PrivilegeRequirements().roleActions([RoleActionRemoveApplication]),
          },
        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.name, texts.description, texts.operates]}
          rows={rows}
        />
      );
    }
    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: onAdd,
      label: texts.addButton,
      icon: AddIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionAddApplication, RoleActionCreateIdentity]),
    },
    {
      onClick: () => {
        window.location.assign("/admin/applications/");
      },
      label: texts.appButton,
      icon: WidgetsIcon,
      privileges: new PrivilegeRequirements().roleActions([RoleActionListApplication, RoleActionReadApplication]),
    },
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <DeleteAppGroupDialog
        lang={lang}
        open={removeDialogVisible}
        groupName={currentItem}
        onSuccess={onRemoveSuccess}
        onCancel={closeRemoveDialog}
      />
    </GridContainer>
  );
}

AppGroupList.prototype = {
  onAdd: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onRoles: PropTypes.func.isRequired,
  onMembers: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
