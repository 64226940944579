import React from "react";
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";

const realtimeBarOptions = (
  series,
  maxValue,
  beginTime,
  mapValue,
  axisFormatter
) => {
  const defaultBrushColor = "white";
  const backgroundColor = "#ccc";
  if (!maxValue) {
    maxValue = 1;
    series[0].data.forEach((value) => {
      maxValue = Math.max(maxValue, value[1]);
    });
  }

  let options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      containLabel: true,
    },
    series: series.map(({ label, data, color }) => ({
      name: label,
      data: data,
      type: "bar",
      itemStyle: {
        color: color ? color : defaultBrushColor,
        borderColor: color ? color : defaultBrushColor,
        borderWidth: 2,
        opacity: 0.9,
      },
      lineStyle: {
        width: 4,
        opacity: 0.8,
      },
    })),
    xAxis: {
      type: "time",
      boundaryGap: false,
      min: beginTime,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
    },
    yAxis: {
      type: "value",
      max: maxValue,
      splitNumber: 4,
      axisLabel: {
        color: defaultBrushColor,
      },
      axisLine: {
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
    },
  };
  if (axisFormatter) {
    options.yAxis.axisLabel.formatter = axisFormatter;
  }
  if (mapValue) {
    options.tooltip.formatter = (series) => {
      let content =
        series[0].axisValueLabel + "<span stype='margin-left: 10%;'>";
      series.forEach(({ seriesName, value }) => {
        content += "<br>" + seriesName + ": <b>" + mapValue(value[1]) + "</b>";
      });
      content += "</span>";
      return content;
    };
  }

  return options;
};

export default function RealtimeBarChart(properties) {
  const { series, maxValue, interval, mapValue, axisFormatter } = properties;
  let dataCount = series[0].data.length;
  let timeSequence = [];
  let now = new Date();
  for (let i = 0; i < dataCount; i++) {
    timeSequence.unshift(now - i * interval * 1000);
  }
  const beginTime = timeSequence[0];
  let seriesWithTimestamp = [];
  series.forEach(({ data, label, color }) => {
    //map to timeline
    let mappedData = data.map(
      (value, index) => new Array(timeSequence[index], value)
    );
    seriesWithTimestamp.push({
      data: mappedData,
      color: color,
      label: label,
    });
  });
  let options = realtimeBarOptions(
    seriesWithTimestamp,
    maxValue,
    beginTime,
    mapValue,
    axisFormatter
  );
  return <ReactECharts option={options} />;
}

RealtimeBarChart.prototype = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.string),
      color: PropTypes.string,
    })
  ),
  maxValue: PropTypes.number,
  interval: PropTypes.number.isRequired,
  mapValue: PropTypes.func,
  axisFormatter: PropTypes.func,
};
