import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import MainTabs from "views/Identities/MainTabs";
import MustInitialized from "views/Login/MustInitialized";

export default function IdentityRoutes(properties) {
  const component = (
    <div>
      <Route
        path="/admin/identities"
        exact
        render={(childrenProps) =>
          React.createElement(MainTabs, {
            ...childrenProps,
            ...properties,
          })
        }
      />
    </div>
  );
  return <MustInitialized lang={properties.lang} component={component} />;
}

IdentityRoutes.prototype = {
  lang: PropTypes.string.isRequired,
};
