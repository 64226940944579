import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CustomDialog from "components/Dialog/CustomDialog.js";

const i18n = {
  en: {
    group: "Group to add",
    existed: "(Already existed)",
    privileges: "Group Privileges",
    owner: "Owner",
    executor: "Executor",
    updater: "Updater",
    viewer: "Viewer",
    btnClose: "Close",
    btnSubmit: "Submit",
    alertPrivileges: "Permit one privilege at least",
    alertGroups: "At least add one group",
  },
  cn: {
    group: "需要添加的组",
    existed: "(已经添加)",
    privileges: "默认组权限",
    owner: "管理",
    executor: "执行",
    updater: "更新",
    viewer: "查看",
    btnClose: "关闭",
    btnSubmit: "提交",
    alertPrivileges: "至少允许一项权限",
    alertGroups: "至少添加一个用户组",
  },
};

export default function AddActorGroupsDialog(props) {
  const initialPrivileges = {
    owner: false,
    executor: false,
    updater: false,
    viewer: true,
  };
  const { lang, open, title, queryHandler, existed, onSuccess, onCancel } = props;
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const [operatable, setOperatable] = React.useState(true);
  const [privileges, setPrivileges] = React.useState(initialPrivileges);
  const [groups, setGroups] = React.useState([]);
  const texts = i18n[lang];

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onOperateFail = React.useCallback((msg) => {
    setOperatable(true);
    showErrorMessage(msg);
  }, [showErrorMessage]);

  const resetDialog = () => {
    setOperatable(true);
    setPrivileges(initialPrivileges);
    setGroups([]);
  };

  const cancelDialog = () => {
    resetDialog();
    onCancel();
  };

  const handleConfirm = () => {
    setOperatable(false);
    if (!privileges.owner && !privileges.executor && !privileges.updater && !privileges.viewer){
      onOperateFail(texts.alertPrivileges);
      return
    }
    let chosenGroups = [];
    for (let group of groups){
      if (!group.existed && group.checked){
        chosenGroups.push(group.name);
      }
    }
    if (0 == chosenGroups.length){
      onOperateFail(texts.alertGroups);
      return
    }
    resetDialog();
    onSuccess(chosenGroups, privileges);
  };

  const handlePrivilegeChanged = (e) => {
    let checked = e.target.checked;
    let label = e.target.value;
    setPrivileges(previous => ({
      ...previous,
      [label]: checked,
    }))
  }

  const handleGroupChanged = (e) => {
    let index = Number.parseInt(e.target.value);
    let currentGroups = groups;
    currentGroups[index].checked = e.target.checked;
    setGroups([...currentGroups]);
  };

  React.useEffect(() => {
    setMounted(true);
    if (!open){
      return;
    }

    const onLoadSuccess = (result) => {
      let allGroups = result.groups;
      let existedGroups = new Set();
      for (let groupName of existed){
        existedGroups.add(groupName);
      }
      let groupData = [];
      for (let group of allGroups){
        const {name, description } = group;
        let data = {
          name: name,
          description: description,
          checked: false,
        };
        data.existed = existedGroups.has(name);
        groupData.push(data);
      }
      setGroups(groupData);
      setInitialized(true);
    };

    queryHandler(onLoadSuccess, onOperateFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, open, existed, onOperateFail]);

  //rendering
  let buttons = [
    {
      color: "transparent",
      label: texts.btnClose,
      onClick: cancelDialog,
    },
    {
      color: "info",
      label: texts.btnSubmit,
      onClick: handleConfirm,
    },
  ];

  let contentComponents;
  if (!initialized){
    contentComponents = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  }else{  
    contentComponents = (
      <Stack spacing={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 0,
            pt: 3,
            pb: 0,
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend" color='text'>{texts.privileges}</FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="owner"
                control={<Checkbox color="info" checked={privileges.owner} onChange={handlePrivilegeChanged}/>}
                label={texts.owner}
              />
              <FormControlLabel
                value="executor"
                control={<Checkbox color="info" checked={privileges.executor} onChange={handlePrivilegeChanged}/>}
                label={texts.executor}
              />
              <FormControlLabel
                value="updater"
                control={<Checkbox color="info" checked={privileges.updater} onChange={handlePrivilegeChanged}/>}
                label={texts.updater}
              />
              <FormControlLabel
                value="viewer"
                control={<Checkbox color="info" checked={privileges.viewer} onChange={handlePrivilegeChanged}/>}
                label={texts.viewer}
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 0,
            pt: 3,
            pb: 0,
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend" color='text'>{texts.group}</FormLabel>
            <FormGroup aria-label="position">
              {
                groups.map((group, index) => {
                  const {name, description, existed, checked} = group;
                  let disabled, label;
                  if (existed) {
                    disabled = true;
                    label = name + texts.existed + ' - ' + description;
                  } else {
                    disabled = false;
                    label = name + ' - ' + description;
                  }
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="info"
                          value={index}
                          disabled={disabled}
                          checked={checked}
                          onChange={handleGroupChanged}
                        />
                      }
                      label={label}
                    />
                  )
                })
              }
            </FormGroup>
          </FormControl>
        </Box>
        <Divider/>
        <ToastContainer autoClose={3500} draggable={false} />
      </Stack>
  
    );
  }
  

  return (
    <CustomDialog
      size="sm"
      open={open}
      title={title}
      buttons={buttons}
      content={contentComponents}
      operatable={operatable}
    />
  );
}

AddActorGroupsDialog.propTypes = {
  lang: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  queryHandler: PropTypes.func.isRequired,
  existed: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
