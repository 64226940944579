import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveIcon from "@mui/icons-material/Remove";
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from "@mui/icons-material/Add";

// dashboard components
import ParseContentDialog from "views/Shared/ParseContentDialog";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import Info from "components/Typography/Info.js";
import IconButton from "components/CustomButtons/IconButton";
import { InputComponent } from "components/CustomInput/InputList";
import { createSchema, writeLog } from "backend_api.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Create New Schema",
    back: "Back",
    name: "Schema Name",
    propertyName: "Property Name",
    propertyType: "Value Type",
    propertyIndexed: "Property Indexed",
    tagYes: "Yes",
    tagNo: "No",
    tagTypeString: "String",
    tagTypeInteger: "Integer",
    tagTypeFloat: "Float",
    tagTypeBool: "Boolean",
    tagTypeCurrency: "Currency",
    noRecord: "No property available, click to add",
    operates: "Operates",
    submitButton: "Create New Schema",
    addButton: "Add Property",
    removeButton: "Remove Property",
    moveUpButton: "Move Up",
    moveDownButton: "Move Down",
    btnImport: "Import",
    importTitle: "Import Schema",
    importLabel: "Schema Define(JSON)",
  },
  cn: {
    title: "新建资产类别",
    back: "返回",
    name: "类别名称",
    propertyName: "属性名",
    propertyType: "属性值类型",
    propertyIndexed: "属性值是否索引",
    tagYes: "是",
    tagNo: "否",
    tagTypeString: "字符串",
    tagTypeInteger: "整数",
    tagTypeFloat: "浮点数",
    tagTypeBool: "布尔类型",
    tagTypeCurrency: "货币",
    noRecord: "未设置属性, 点击添加",
    operates: "操作",
    submitButton: "创建新数据类别",
    addButton: "添加属性",
    removeButton: "删除属性",
    moveUpButton: "上移",
    moveDownButton: "下移",
    btnImport: "导入",
    importTitle: "导入资产类别",
    importLabel: "资产类别定义（JSON格式）",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function CreateSchema(props) {
  const { lang } = props;
  const texts = i18n[lang];
  const defaultValues = {
    name: "",
    properties: [],
  };
  const valueTypeOptions = [
    {
      label: texts.tagTypeString,
      value: "string",
    },
    {
      label: texts.tagTypeInteger,
      value: "int",
    },
    {
      label: texts.tagTypeFloat,
      value: "float",
    },
    {
      label: texts.tagTypeBool,
      value: "bool",
    },
    {
      label: texts.tagTypeCurrency,
      value: "currency",
    },
  ];
  const [request, setRequest] = React.useState(defaultValues);
  const [importVisiable, setImportVisiable] = React.useState(false);

  const showErrorMessage = React.useCallback((msg) => {
    toast.error(msg);
  }, []);

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const schemaParser = content => {
    let define = JSON.parse(content);
    const {name, properties} = define;
    if (!name || 0 === name.length){
      throw new Error("name required");
    }
    if (!properties || 0 === properties.length){
      throw new Error("properties required");
    }   
    return define; 
  };

  const showImportDialog = () => {
    setImportVisiable(true);
  }

  const closeImportDialog = () => {
    setImportVisiable(false);
  }

  const onImportSuccess = define => {    
    const {name, properties} = define;    
    setRequest({
      name: name,
      properties: properties,
    });
    closeImportDialog();
  }

  const moveUp = (index) => {
    if (request.properties && request.properties[index - 1]) {
      let properties = request.properties;
      let previous = properties[index - 1];
      properties.splice(index - 1, 1);
      properties.splice(index, 0, previous); //insert
      setRequest({
        name: request.name,
        properties: properties,
      });
    }
  };

  const moveDown = (index) => {
    if (request.properties && request.properties[index + 1]) {
      let properties = request.properties;
      let current = properties[index];
      properties.splice(index, 1);
      properties.splice(index + 1, 0, current); //insert
      setRequest({
        name: request.name,
        properties: properties,
      });
    }
  };

  const removeProperty = (index) => {
    if (request.properties && request.properties[index]) {
      let properties = request.properties;
      properties.splice(index, 1);
      setRequest({
        name: request.name,
        properties: properties,
      });
    }
  };

  const addProperty = () => {
    let newProperty = {
      name: "",
      type: "string",
      indexed: false,
    };
    let properties = request.properties;
    properties.push(newProperty);
    setRequest({
      name: request.name,
      properties: properties,
    });
  };

  const handlePropertyNameChanged = (index) => {
    return (e) => {
      let value = e.target.value;
      let properties = request.properties;
      properties[index].name = value;
      setRequest({
        name: request.name,
        properties: properties,
      });
    };
  };

  const handlePropertyTypeChanged = (index) => {
    return (e) => {
      let value = e.target.value;
      let properties = request.properties;
      properties[index].type = value;
      setRequest({
        name: request.name,
        properties: properties,
      });
    };
  };

  const handlePropertyIndexedChanged = (index) => {
    return (e) => {
      let value = e.target.checked;
      let properties = request.properties;
      properties[index].indexed = value;
      setRequest({
        name: request.name,
        properties: properties,
      });
    };
  };

  const handleConfirm = () => {
    const onOperateSuccess = () => {
      writeLog("created new schema " + request.name);
      const listURL = "/admin/assets/";
      window.location.assign(listURL);
    };
    let wordOnly = new RegExp("\\W");
    if (!request.name) {
      onFail("schema name required");
      return;
    } else if (wordOnly.test(request.name)) {
      onFail("only words allow in schema name: " + request.name);
      return;
    }

    if (!request.properties || 0 === request.properties.length) {
      onFail("schema property required");
      return;
    }
    let propertyNames = new Set();
    if (
      !request.properties.every(({ name }, index) => {
        if (!name || "" === name) {
          onFail("name omit on " + index + "th property");
          return false;
        }
        if (propertyNames.has(name)) {
          onFail("property '" + name + "' duplicated");
          return false;
        }
        propertyNames.add(name);
        return true;
      })
    ) {
      return;
    }

    createSchema(
      defaultChainName,
      defaultDomainName,
      request.name,
      request.properties,
      onOperateSuccess,
      onFail
    );
  };

  const handleSchemaNameChanged = (e) => {
    let value = e.target.value;
    if (value !== request.name) {
      setRequest((previous) => ({
        ...previous,
        name: value,
      }));
    }
  };

  const dataToNodes = (index, data, buttons) => {
    const operates = buttons.map((button, key) =>
      React.createElement(IconButton, {
        ...button,
        color: "secondary",
        key: key,
      })
    );
    const { name, type, indexed } = data;
    let inputName = (
      <InputComponent
        type="text"
        label={texts.propertyName}
        value={name}
        required={true}
        xs={6}
        onChange={handlePropertyNameChanged(index)}
      />
    );
    let inputType = (
      <InputComponent
        type="select"
        label={texts.propertyType}
        value={type}
        options={valueTypeOptions}
        xs={6}
        onChange={handlePropertyTypeChanged(index)}
      />
    );
    let inputIndex = (
      <InputComponent
        type="switch"
        label={texts.propertyIndexed}
        value={indexed}
        on={texts.tagYes}
        off={texts.tagNo}
        xs={6}
        onChange={handlePropertyIndexedChanged(index)}
      />
    );
    return [inputName, inputType, inputIndex, operates];
  };

  const nameInput = (
    <InputComponent
      type="text"
      label={texts.name}
      value={request.name}
      required={true}
      xs={8}
      md={6}
      lg={4}
      xl={2}
      onChange={handleSchemaNameChanged}
    />
  );
  let buttons = [];
  let rows = [];
  if (!request || !request.properties || 0 === request.properties.length) {
    rows.push([
      <Box display="flex" justifyContent="center" key="none">
        <Info>{texts.noRecord}</Info>
      </Box>,
      "",
      "",
      "",
    ]);
  } else {
    const propertyCount = request.properties.length;
    request.properties.forEach((property, index) => {
      const buttons = [];
      if (propertyCount > 1) {
        //2 properties at least
        if (0 !== index) {
          //up
          buttons.push({
            onClick: () => moveUp(index),
            icon: ArrowUpwardIcon,
            label: texts.moveUpButton,
          });
        }
        if (propertyCount !== index + 1) {
          //down
          buttons.push({
            onClick: () => moveDown(index),
            icon: ArrowDownwardIcon,
            label: texts.moveDownButton,
          });
        }
      }
      buttons.push({
        onClick: () => removeProperty(index),
        icon: RemoveIcon,
        label: texts.removeButton,
      });
      rows.push(dataToNodes(index, property, buttons));
    });
  }

  let addButton = (
    <IconButton
      label={texts.addButton}
      icon={AddIcon}
      color="primary"
      onClick={addProperty}
      key="add"
      size="large" />
  );
  let content = (
    <Table
      color="primary"
      headers={[
        texts.propertyName,
        texts.propertyType,
        texts.propertyIndexed,
        texts.operates,
      ]}
      rows={rows}
    />
  );

  let title = texts.title;
  buttons = [
    <Button
      key="back"
      size="sm"
      color="info"
      round
      onClick={() => {
        const listURL = "/admin/assets/";
        window.location.assign(listURL);
        // props.history.goBack();
      }}
    >
      <NavigateBeforeIcon />
      {texts.back}
    </Button>,
    <Button
      key="import"
      size="sm"
      color="info"
      round
      onClick={showImportDialog}
      >
      <CodeIcon />
      {texts.btnImport}
    </Button>
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}></Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>
            {nameInput}
            {content}
            {addButton}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
      <ParseContentDialog
        lang={lang}
        open={importVisiable}
        title={texts.importTitle}
        label={texts.importLabel}
        parser={schemaParser}
        onSuccess={onImportSuccess}
        onCancel={closeImportDialog}
      />
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

CreateSchema.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
