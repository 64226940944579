import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Table from "components/Table/ObjectTable.js";
import { InputComponent } from "components/CustomInput/InputList";
import { queryOperateLogs } from "backend_api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    tableTitle: "Operate Logs",
    timestamp: "Timestamp",
    content: "Content",
    noRecord: "No log available",
    duration: "Log Duration",
    half: "The last half year",
    month: "The last month",
    year: "The last year",
  },
  cn: {
    tableTitle: "操作日志",
    timestamp: "时间",
    content: "日志内容",
    noRecord: "无日志内容",
    duration: "日志范围",
    half: "最近半年",
    month: "最近一个月",
    year: "最近一年",
  },
};

export default function OpereateLogs(props) {
  const DEFAULT_MAX_RECORD = 15;
  const recordPerPage = DEFAULT_MAX_RECORD;
  const DURATION_MONTH = 0;
  const DURATION_HALF_YEAR = 1;
  const DURATION_YEAR = 2;
  const { lang } = props;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  const [duration, setDuration] = React.useState(DURATION_MONTH);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadDataList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { logs, offset, total } = result;
      setDataList(logs);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    const now = new Date();
    const endTime = now.toISOString();
    let beginTime;
    switch (duration) {
      case DURATION_HALF_YEAR:
        beginTime = new Date(
          now.getFullYear(),
          now.getMonth() - 6,
          now.getDay()
        );
        break;
      case DURATION_YEAR:
        beginTime = new Date(
          now.getFullYear() - 1,
          now.getMonth(),
          now.getDay()
        );
        break;
      default:
        //last month
        beginTime = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDay()
        );
        break;
    }
    queryOperateLogs(
      offset,
      recordPerPage,
      beginTime.toISOString(),
      endTime,
      onLoadSuccess,
      onLoadFail
    );
  }, [showErrorMessage, currentPage, duration, mounted]);

  const dataToNodes = ({ time, content }) => {
    return [new Date(time).toLocaleString(), content];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const handleDurationChanged = (e) => {
    let value = e.target.value;
    setCurrentPage(0);
    setDuration(value);
  };

  React.useEffect(() => {
    setMounted(true);
    reloadDataList();
    return () => {
      setMounted(false);
    };
  }, [reloadDataList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((log) => {
        rows.push(dataToNodes(log));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.timestamp, texts.content]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const durationOptions = [
    {
      label: texts.month,
      value: DURATION_MONTH,
    },
    {
      label: texts.half,
      value: DURATION_HALF_YEAR,
    },
    {
      label: texts.year,
      value: DURATION_YEAR,
    },
  ];
  const operateComponents = [
    <InputComponent
      key="duration"
      type="radio"
      label={texts.duration}
      value={duration}
      options={durationOptions}
      onChange={handleDurationChanged}
      horizontal
    />,
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {operateComponents.map((component, key) => (
            <Box key={key} m={1}>
              {component}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

OpereateLogs.propTypes = {
  lang: PropTypes.string.isRequired,
};
