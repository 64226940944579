import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "components/CustomButtons/Button.js";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import Table from "components/Table/ObjectTable.js";
import RemoveContractDialog from "views/Contracts/RemoveContractDialog.js";
// import AddRangeDialog from "views/AddressPools/AddRangeDialog.js";
import {
  queryContracts,
  getContract,
  writeLog,
  isSystemResource,
  enableContractTrace,
  disableContractTrace,
} from "backend_api";
import {
  PrivilegeRequirements,
  DomainActionDeploySystemContract,
  DomainActionReadSystemContract,
  DomainActionDeployContract,
  DomainActionRevokeContract,
  DomainActionExecuteContract,
} from "privileges";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";
import { hasPrivileges } from "utils";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    createButton: "Deploy New Contract",
    tableTitle: "Contract List",
    name: "Contract Name",
    parameters: "Parameters",
    steps: "Steps",
    modifyTime: "Modified Time",
    enabled: "Enabled",
    enable: "Enable",
    disable: "Disable",
    trace: 'Trace',
    traceEnabled: 'Trace On',
    traceDisabled: 'Trace Off',
    noContract: "No contract available",
    operates: "Operates",
    test: "Test",
    detail: "Detail",
    modify: "Modify",
    remove: "Remove",
    actors: "Privileges",
    export: "Export Define",
    history: "Modify Records",
  },
  cn: {
    back: "返回",
    createButton: "部署新合约",
    tableTitle: "智能合约清单",
    name: "合约名称",
    parameters: "调用参数数量",
    steps: "执行步骤",
    modifyTime: "最后修改时间",
    enabled: "是否启用",
    enable: "启用",
    disable: "禁用",
    trace: '调试跟踪',
    traceEnabled: '已打开',
    traceDisabled: '已关闭',
    noContract: "无智能合约",
    operates: "操作",
    test: "调试",
    detail: "详情",
    modify: "编辑",
    remove: "删除",
    actors: "管理权限",
    export: "导出定义",
    history: "变更历史",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function ContractList(props) {
  const recordPerPage = 20;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  //for dialog
  // const [ addDialogVisible, setAddDialogVisible ] = React.useState(false);
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const exportDefine = contractName => {
    const onGetSuccess = contract => {
      const { name, content } = contract;
      let define = JSON.parse(content);
      let payload = {
        name: name,
        parameters: define.parameters,
        steps: define.steps,
      }

      let blob = new Blob([JSON.stringify(payload, null, "  ")], { type: 'application/json' });
      var downloadURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = downloadURL;
      tempLink.setAttribute('download', `${contractName}.json`);
      tempLink.click();
    }
    getContract(
      chainName,
      domainName,
      contractName,
      onGetSuccess,
      showErrorMessage,
    );

  };

  const reloadContractList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { contracts, offset, total } = result;
      setDataList(contracts);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    queryContracts(
      chainName,
      domainName,
      offset,
      recordPerPage,
      onLoadSuccess,
      onLoadFail
    );
  }, [showErrorMessage, currentPage, chainName, mounted]);

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.info(msg);
  };

  //remove
  const showRemoveDialog = (contractName) => {
    setCurrentItem(contractName);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (contractName) => {
    closeRemoveDialog();
    showNotifyMessage("contract " + contractName + " removed");
    writeLog("removed contract " + contractName);
    reloadContractList();
  };

  const toggleTraceSwitch = (contractName) => (e => {
    let checked = e.target.checked;
    e.preventDefault();
    if (checked) {
      enableContractTrace(
        chainName,
        domainName,
        contractName,
        reloadContractList,
        showErrorMessage,
      );
    } else {
      disableContractTrace(
        chainName,
        domainName,
        contractName,
        reloadContractList,
        showErrorMessage,
      );
    }
  });

  const dataToNodes = (data, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { name, parameters, steps, modified_time, enabled, trace } = data;
    let time = new Date(modified_time);
    let onSwitchChange = toggleTraceSwitch(name);
    let requiredPrivileges;
    if (isSystemResource(name)) {
      requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([DomainActionDeploySystemContract]);
    } else {
      requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([DomainActionDeployContract]);
    }
    let metRequirements = hasPrivileges(requiredPrivileges);
    let traceSwitch;
    if (trace) {
      traceSwitch = <FormControlLabel control={<Switch checked={trace} onChange={onSwitchChange} disabled={!metRequirements} />} label={texts.traceEnabled} />
    } else {
      traceSwitch = <FormControlLabel control={<Switch checked={trace} onChange={onSwitchChange} disabled={!metRequirements} />} label={texts.traceDisabled} />
    }
    return [
      name,
      parameters.toString(),
      steps.toString(),
      time.toLocaleString(),
      enabled ? texts.enable : texts.disable,
      traceSwitch,
      operates,
    ];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadContractList();
    return () => {
      setMounted(false);
    };
  }, [reloadContractList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noContract}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((data) => {
        let read, modify, remove, test;
        if (isSystemResource(data.name)) {
          read = DomainActionReadSystemContract;
          modify = DomainActionDeploySystemContract;
          remove = DomainActionDeploySystemContract;
          test = DomainActionDeploySystemContract;
        } else {
          read = DomainActionDeploySystemContract;
          modify = DomainActionDeployContract;
          remove = DomainActionRevokeContract;
          test = DomainActionExecuteContract;
        }
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            href: "/admin/contracts/view/" + data.name,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            icon: HistoryIcon,
            label: texts.history,
            href: "/admin/contracts/versions/" + data.name,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            icon: GolfCourseIcon,
            label: texts.test,
            href: "/admin/contracts/test/" + data.name,
            privileges: new PrivilegeRequirements().defaultDomainActions([test]),
          },
          {
            icon: EditIcon,
            label: texts.modify,
            href: "/admin/contracts/modify/" + data.name,
            privileges: new PrivilegeRequirements().defaultDomainActions([modify]),
          },
          {
            icon: AdminPanelSettingsIcon,
            label: texts.actors,
            href: "/admin/contracts/actors/" + data.name,
            privileges: new PrivilegeRequirements().defaultDomainActions([modify]),
          },
          {
            onClick: () => exportDefine(data.name),
            icon: FileDownloadIcon,
            label: texts.export,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            onClick: () => showRemoveDialog(data.name),
            icon: DeleteIcon,
            label: texts.remove,
            privileges: new PrivilegeRequirements().defaultDomainActions([remove]),
          },
        ];
        rows.push(dataToNodes(data, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[
            texts.name,
            texts.parameters,
            texts.steps,
            texts.modifyTime,
            texts.enabled,
            texts.trace,
            texts.operates,
          ]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  let buttons = [];
  if (hasPrivileges(new PrivilegeRequirements().defaultDomainActions([DomainActionDeployContract])) ||
    new PrivilegeRequirements().defaultDomainActions([DomainActionDeploySystemContract])) {
    buttons.push({
      onClick: () => {
        window.location.assign("/admin/contracts/new_contract/");
      },
      label: texts.createButton,
      icon: AddIcon,
    });
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box display="flex">
          {
            buttons.map((properties, index) => {
              const { onClick, icon, label } = properties;
              return (
                <Box key={index} m={1}>
                  <Button round onClick={onClick} color="info">
                    {React.createElement(icon)}
                    {label}
                  </Button>
                </Box>
              )
            })
          }
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <GridItem>
        <RemoveContractDialog
          lang={lang}
          open={removeDialogVisible}
          chainName={chainName}
          domainName={domainName}
          contractName={currentItem}
          onSuccess={onRemoveSuccess}
          onCancel={closeRemoveDialog}
        />
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ContractList.propTypes = {
  lang: PropTypes.string.isRequired,
};
