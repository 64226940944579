import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "components/CustomButtons/Button.js";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedIconButtons from "components/CustomButtons/AuthenticatedIconButtons";
import Table from "components/Table/ObjectTable.js";
import DeleteSchemaDialog from "views/Assets/DeleteSchemaDialog.js";
import { querySchemas, writeLog, isSystemResource, getSchema } from "backend_api";
import {
  PrivilegeRequirements,
  DomainActionReadSystemSchema,
  DomainActionReadSystemDocument,
  DomainActionUpdateSystemSchema,
  DomainActionDeleteSystemSchema,
  DomainActionCreateSystemSchema,
  DomainActionReadSchema,
  DomainActionReadDocument,
  DomainActionUpdateSchema,
  DomainActionDeleteSchema,
  DomainActionCreateSchema,
} from "privileges";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";
import { hasPrivileges } from "utils";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    createButton: "Create New Schema",
    modifyButton: "Modify Schema",
    tableTitle: "Schema List",
    name: "Schema Name",
    version: "Latest Version",
    modified: "Modified Time",
    noRecord: "No schema available",
    operates: "Operates",
    detail: "Detail",
    docs: "Asset List",
    remove: "Delete",
    actors: "Privileges",
    export: "Export Define",
    history: "Version Records",
  },
  cn: {
    back: "返回",
    createButton: "新建资产类别",
    modifyButton: "修改资产类别",
    tableTitle: "资产类别列表",
    name: "名称",
    version: "最新版本",
    modified: "修改时间",
    noRecord: "无数据类别",
    operates: "操作",
    detail: "详情",
    docs: "资产清单",
    remove: "删除",
    actors: "管理权限",
    export: "导出定义",
    history: "版本历史",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function SchemaList(props) {
  const recordPerPage = 5;
  const { lang } = props;
  const texts = i18n[lang];
  const chainName = defaultChainName;
  const domainName = defaultDomainName;
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [dataList, setDataList] = React.useState(null);
  //for dialog
  const [removeDialogVisible, setRemoveDialogVisible] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const exportDefine = schemaName => {
    const onGetSuccess = define => {
      let payload = JSON.stringify(define, null, "  ");
      let blob = new Blob([payload], { type: 'application/json' });
      var downloadURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.href = downloadURL;
      tempLink.setAttribute('download', `${schemaName}.json`);
      tempLink.click();
    }
    getSchema(
      chainName,
      domainName,
      schemaName,
      onGetSuccess,
      showErrorMessage,
    );

  };

  const reloadSchemaList = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };
    const onLoadSuccess = (result) => {
      const { schemas, offset, total } = result;
      setDataList(schemas);
      if (0 !== total) {
        let current;
        if (offset < recordPerPage) {
          current = 0;
        } else {
          current = Math.floor(offset / recordPerPage);
        }
        let all;
        if (0 === total % recordPerPage) {
          all = total / recordPerPage;
        } else {
          all = Math.ceil(total / recordPerPage);
        }
        if (current !== currentPage) {
          setCurrentPage(current);
        }
        if (all !== totalPages) {
          setTotalPages(all);
        }
      }
    };

    const offset = currentPage * recordPerPage;
    querySchemas(
      chainName,
      domainName,
      offset,
      recordPerPage,
      onLoadSuccess,
      onLoadFail
    );
  }, [showErrorMessage, currentPage, chainName, mounted]);

  const showNotifyMessage = (msg) => {
    if (!mounted) {
      return;
    }
    toast.info(msg);
  };

  //remove
  const showRemoveDialog = (schemaName) => {
    setCurrentItem(schemaName);
    setRemoveDialogVisible(true);
  };

  const closeRemoveDialog = () => {
    setRemoveDialogVisible(false);
  };

  const onRemoveSuccess = (schemaName) => {
    closeRemoveDialog();
    showNotifyMessage("schema " + schemaName + " deleted");
    writeLog("deleted schema " + schemaName);
    reloadSchemaList();
  };

  const dataToNodes = (record, buttons) => {
    const operates = (
      <AuthenticatedIconButtons color="secondary" buttons={buttons} />
    );
    const { name, version, modified } = record;
    return [
      name,
      version.toString(),
      new Date(modified).toLocaleString(),
      operates,
    ];
  };

  const changeCurrentPage = (e, index) => {
    let page = index - 1;
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    reloadSchemaList();
    return () => {
      setMounted(false);
    };
  }, [reloadSchemaList]);

  //begin rendering
  let content;
  if (null === dataList) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    let tableContent;
    if (!dataList || 0 === dataList.length) {
      tableContent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      let rows = [];
      dataList.forEach((record) => {
        let read, readDoc, modify, remove;
        if (isSystemResource(record.name)) {
          read = DomainActionReadSystemSchema;
          readDoc = DomainActionReadSystemDocument;
          modify = DomainActionUpdateSystemSchema;
          remove = DomainActionDeleteSystemSchema;
        } else {
          read = DomainActionReadSchema;
          readDoc = DomainActionReadDocument;
          modify = DomainActionUpdateSchema;
          remove = DomainActionDeleteSchema;
        }
        const buttons = [
          {
            icon: VisibilityIcon,
            label: texts.detail,
            href: "/admin/assets/schemas/" + record.name,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            icon: HistoryIcon,
            label: texts.history,
            href: "/admin/assets/schemas/" + record.name + "/logs/",
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            icon: LibraryBooksIcon,
            label: texts.docs,
            href: "/admin/assets/schemas/" + record.name + "/docs/",
            privileges: new PrivilegeRequirements().defaultDomainActions([read, readDoc]),
          },
          {
            icon: EditIcon,
            label: texts.modifyButton,
            href: "/admin/assets/schemas/" + record.name + "/edit/",
            privileges: new PrivilegeRequirements().defaultDomainActions([modify]),
          },
          {
            icon: AdminPanelSettingsIcon,
            label: texts.actors,
            href: "/admin/assets/schemas/" + record.name + "/actors/",
            privileges: new PrivilegeRequirements().defaultDomainActions([modify]),
          },
          {
            onClick: () => exportDefine(record.name),
            icon: FileDownloadIcon,
            label: texts.export,
            privileges: new PrivilegeRequirements().defaultDomainActions([read]),
          },
          {
            onClick: () => showRemoveDialog(record.name),
            icon: DeleteIcon,
            label: texts.remove,
            privileges: new PrivilegeRequirements().defaultDomainActions([remove]),
          },

        ];
        rows.push(dataToNodes(record, buttons));
      });
      tableContent = (
        <Table
          color="primary"
          headers={[texts.name, texts.version, texts.modified, texts.operates]}
          rows={rows}
        />
      );
    }

    let pagination;
    if (totalPages > 1) {
      pagination = (
        <Box display="flex" alignItems="center" justifyContent="center" m={1}>
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            boundaryCount={2}
            showFirstButton
            showLastButton
            onChange={changeCurrentPage}
          />
        </Box>
      );
    } else {
      pagination = <div />;
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{texts.tableTitle}</h4>
        </CardHeader>
        <CardBody>
          {tableContent}
          {pagination}
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  let buttons = [];
  if (hasPrivileges(new PrivilegeRequirements().defaultDomainActions([DomainActionCreateSchema])) ||
    new PrivilegeRequirements().defaultDomainActions([DomainActionCreateSystemSchema])) {
    buttons.push({
      onClick: () => {
        window.location.assign("/admin/assets/create_schema/");
      },
      label: texts.createButton,
      icon: AddIcon,
    });
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box display="flex">
          {
            buttons.map((properties, index) => {
              const { onClick, icon, label } = properties;
              return (
                <Box key={index} m={1}>
                  <Button round onClick={onClick} color="info">
                    {React.createElement(icon)}
                    {label}
                  </Button>
                </Box>
              )
            })
          }
        </Box>
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <GridItem>
        <DeleteSchemaDialog
          lang={lang}
          open={removeDialogVisible}
          chainName={chainName}
          domainName={domainName}
          schemaName={currentItem}
          onSuccess={onRemoveSuccess}
          onCancel={closeRemoveDialog}
        />
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

SchemaList.propTypes = {
  lang: PropTypes.string.isRequired,
};
