import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Danger from "components/Typography/Danger.js";
import { getLanguage } from "utils.js";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import { MAIN_THEME } from "project";

// pages for this product
import Admin from "layouts/Admin.js";
import Login from "views/Login/Login.js";
import Initial from "views/Login/Initial.js";

const urlParams = new URLSearchParams(window.location.search);
let currentLang = urlParams.get("lang");
if (!currentLang){
  currentLang = getLanguage();
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorStack: null,
    };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return {
      hasError: true,
      error: error.message,
      errorStack: error.stack,
    };
  }

  render() {
    if (this.state.hasError) {
      return <Danger>{this.state.error}</Danger>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

let history = createBrowserHistory();
const mainTheme = createTheme(MAIN_THEME);
window.process = {};
function LanguageProvider() {
  const [lang, setLang] = React.useState(currentLang);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <ErrorBoundary>
          <Router history={history}>
            <Switch>
              <Route
                path="/admin"
                render={() => <Admin lang={lang} setLang={setLang} />}
              />
              <Route
                path="/login"
                render={() => <Login lang={lang} setLang={setLang} />}
              />
              <Route
                path="/initial"
                render={() => <Initial lang={lang} setLang={setLang} />}
              />
              <Redirect from="/" to="/login" />
            </Switch>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ReactDOM.render(<LanguageProvider />, document.getElementById("root"));
