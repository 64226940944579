import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Hidden from "@mui/material/Hidden";

import Menu from "@mui/icons-material/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import LanguageSelector from "components/Language/Selector.js";

import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { getCurrentVersion } from "backend_api.js";
import { PROJECT_NAME } from "project.js";

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    manual: "Online Manual",
    manualURL: "about:blank",
  },
  cn: {
    manual: "在线文档",
    manualURL: "about:blank",
  },
};

export default function NavBar(props) {
  const classes = useStyles();
  function makeBrand() {
    let name = "";
    props.routes.every((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = prop.display[props.lang];
        return false;
      }
      return true;
    });
    return name;
  }
  const { color, lang, setLang } = props;
  const texts = i18n[lang];
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  const version = (
    <Box mr={2}>
      <Typography component="span">
        {"Project " + PROJECT_NAME + " " + getCurrentVersion() + " © 2018~2023"}
      </Typography>
    </Box>
  );

  const manualButton = (
    <Tooltip title={texts.manual} placement="top">
      <Link target="_blank" href={texts.manualURL}>
        <IconButton color="default" size="small">
          <HelpOutlineIcon />
        </IconButton>
      </Link>
    </Tooltip>
  );

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <Hidden mdDown>
          {version}
          {manualButton}
        </Hidden>
        <LanguageSelector lang={lang} setLang={setLang} />
        <Hidden mdDown implementation="css">
          <AdminNavbarLinks lang={lang} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
            size="large">
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
