import React from "react";
import PropTypes from "prop-types";
import GroupList from "views/Access/GroupList";
import ModifyGroup from "views/Access/ModifyGroup";
import ModifyGroupRoles from "views/Access/ModifyGroupRoles";
import ModifyGroupTags from "views/Access/ModifyGroupTags";
import CreateGroup from "views/Access/CreateGroup";
import GroupMembers from "views/Access/GroupMembers";

export default function GroupTab(props) {
  const DEFAULT_MODE = "list";
  const MODE_ADD = "add";
  const MODE_MODIFY = "modify";
  const MODE_ROLES = "roles";
  const MODE_TAGS = "tags";
  const MODE_MEMBERS = "members";
  const [mode, setMode] = React.useState(DEFAULT_MODE);
  const [target, setTarget] = React.useState(null);

  const handleAdd = () => {
    setMode(MODE_ADD);
  };

  const handleListMembers = (groupName) => {
    setTarget(groupName);
    setMode(MODE_MEMBERS);
  };

  const handleModify = (groupName) => {
    setTarget(groupName);
    setMode(MODE_MODIFY);
  };

  const handleRoles = (groupName) => {
    setTarget(groupName);
    setMode(MODE_ROLES);
  };

  const handleTags = (groupName) => {
    setTarget(groupName);
    setMode(MODE_TAGS);
  };

  const list = () => {
    setMode(DEFAULT_MODE);
  };

  switch (mode) {
    case MODE_ADD:
      return <CreateGroup onList={list} {...props} />;
    case MODE_MEMBERS:
      return <GroupMembers onList={list} groupName={target} {...props} />;
    case MODE_ROLES:
      return <ModifyGroupRoles onList={list} groupName={target} {...props} />;
    case MODE_TAGS:
      return <ModifyGroupTags onList={list} groupName={target} {...props} />;
    case MODE_MODIFY:
      return <ModifyGroup onList={list} groupName={target} {...props} />;
    default:
      return (
        <GroupList
          onAdd={handleAdd}
          onModify={handleModify}
          onRoles={handleRoles}
          onTags={handleTags}
          onMembers={handleListMembers}
          {...props}
        />
      );
  }
}

GroupTab.prototype = {
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
