import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import MobiledataOffIcon from "@mui/icons-material/MobiledataOff";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import AccessTime from "@mui/icons-material/AccessTime";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import RealtimeLineChart from "views/Dashboard/RealtimeLineChart";
import RealtimeBarChart from "views/Dashboard/RealtimeBarChart";
import RealtimeBarCard from "views/Dashboard/RealtimeBarCard";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/ObjectTable.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import {
  getLoggedSession,
  redirectToLogin,
  truncateToRadix,
  bpsToString,
  bytesToString,
  reduceUsage,
  splitDuration,
} from "utils.js";
import {
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  grayColor,
  // whiteColor,
} from "assets/jss/custom-styles";
import { querySystemResources } from "backend_api";

const i18n = {
  en: {
    onlinePeers: "Online Peers ",
    offlinePeers: "Offline Peers ",
    onlineGateways: "Online Gateways ",
    offlineGateways: "Offline Gateways ",
    domainCount: "Domain Count ",
    coreUsage: "Core Usage",
    usedCores: "Used Cores",
    memoryUsage: "Memory Usage",
    usedMemory: "Used Memory",
    disk: "Disk Space",
    tagUsedDisk: "Used Disk",
    tagAvailableDisk: "Available Disk",
    networkBandwidth: "Network Bandwidth",
    networkRate: "Network Rate",
    tagSend: "Send",
    tagReceive: "Receive",
    diskBandwidth: "Disk IO Bandwidth",
    diskRate: "Disk IO Rate",
    tagRead: "Read",
    tagWrite: "Write",
    domainName: "Name",
    domainWorld: "World Version",
    domainHeight: "Block Height",
    domainTransaction: "Last Transaction ID",
    domainSchema: "Schema Count",
    domainContract: "Contract Count",
    domainWorkflow: "Workflow Count",
    tagDay: "Day(s)",
    tagHour: "Hour(s)",
    tagMinute: "Minute(s)",
    tagSecond: "Second(s)",
  },
  cn: {
    onlinePeers: "在线节点 ",
    offlinePeers: "离线节点 ",
    onlineGateways: "在线网关 ",
    offlineGateways: "离线网关 ",
    domainCount: "数据域 ",
    coreUsage: "核心利用率",
    usedCores: "已用核心数",
    memoryUsage: "内存利用率",
    usedMemory: "已用内存",
    disk: "集群存储空间",
    tagUsedDisk: "已用磁盘 ",
    tagAvailableDisk: "可用磁盘",
    networkBandwidth: "网络传输速度",
    networkRate: "网络传输报文数",
    tagSend: "发送",
    tagReceive: "接收",
    diskBandwidth: "磁盘读写速度",
    diskRate: "磁盘IO请求数",
    tagRead: "读取",
    tagWrite: "写入",
    domainName: "数据域",
    domainWorld: "世界版本",
    domainHeight: "区块高度",
    domainTransaction: "最近交易ID",
    domainSchema: "资产类别",
    domainContract: "智能合约",
    domainWorkflow: "工作流",
    tagDay: "天",
    tagHour: "小时",
    tagMinute: "分钟",
    tagSecond: "秒",
  },
};

const useStyles = makeStyles(styles);

export default function SystemResources(properties) {
  const CHART_INTERVAL = 2;
  const CORE_SEQUENCE_LENGTH = 30;
  const MEMORY_SEQUENCE_LENGTH = 30;
  const NETWORK_SEQUENCE_LENGTH = 15;
  const DISK_SEQUENCE_LENGTH = 15;
  const classes = useStyles();
  const { lang } = properties;
  const texts = i18n[lang];
  const [resources, setResources] = React.useState(null);
  const [valueSequence, setValueSequence] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const splitTwoSeries = (
    series,
    tag1,
    tag2,
    label1,
    label2,
    color1,
    color2
  ) => {
    let series1 = [],
      series2 = [];
    series.forEach((item) => {
      series1.push(item[tag1]);
      series2.push(item[tag2]);
    });
    return [
      {
        label: label1,
        color: color1,
        data: series1,
      },
      {
        label: label2,
        color: color2,
        data: series2,
      },
    ];
  };

  React.useEffect(() => {
    setMounted(true);
    const UPDATE_INTERVAL = CHART_INTERVAL * 1000;

    const onQuerySuccess = (data) => {
      if (!initialized) {
        //initial
        let chains = new Object();
        data.forEach(
          ({
            chain_name,
            core_usage,
            used_memory,
            disk_write_bandwidth,
            disk_write_rate,
            disk_read_bandwidth,
            disk_read_rate,
            network_send_bandwidth,
            network_send_rate,
            network_receive_bandwidth,
            network_receive_rate,
          }) => {
            //walk every chain
            let coreValues = new Array(CORE_SEQUENCE_LENGTH - 1).fill(0);
            coreValues.push(truncateToRadix(core_usage, 2));
            let memoryValues = new Array(MEMORY_SEQUENCE_LENGTH - 1).fill(0);
            memoryValues.push(used_memory);
            let networkBandwidthValues = new Array(
              NETWORK_SEQUENCE_LENGTH - 1
            ).fill({
              send: 0,
              receive: 0,
            });
            networkBandwidthValues.push({
              send: network_send_bandwidth,
              receive: network_receive_bandwidth,
            });
            let networkRateValues = new Array(NETWORK_SEQUENCE_LENGTH - 1).fill(
              {
                send: 0,
                receive: 0,
              }
            );
            networkRateValues.push({
              send: network_send_rate,
              receive: network_receive_rate,
            });
            let diskBandwidthValues = new Array(DISK_SEQUENCE_LENGTH - 1).fill({
              read: 0,
              write: 0,
            });
            diskBandwidthValues.push({
              read: disk_read_bandwidth,
              write: disk_write_bandwidth,
            });
            let diskRateValues = new Array(DISK_SEQUENCE_LENGTH - 1).fill({
              read: 0,
              write: 0,
            });
            diskRateValues.push({
              read: disk_read_rate,
              write: disk_write_rate,
            });
            let sequences = {
              core: coreValues,
              memory: memoryValues,
              networkBandwidth: networkBandwidthValues,
              networkRate: networkRateValues,
              diskBandwidth: diskBandwidthValues,
              diskRate: diskRateValues,
            };
            chains[chain_name] = sequences;
          }
        );
        setResources(data);
        setValueSequence(chains);
        setInitialized(true);
      } else {
        let chainValues = valueSequence;
        data.forEach(
          ({
            chain_name,
            core_usage,
            used_memory,
            disk_write_bandwidth,
            disk_write_rate,
            disk_read_bandwidth,
            disk_read_rate,
            network_send_bandwidth,
            network_send_rate,
            network_receive_bandwidth,
            network_receive_rate,
          }) => {
            let coreValues = chainValues[chain_name]["core"];
            coreValues.shift();
            coreValues.push(truncateToRadix(core_usage, 2));
            let memoryValues = chainValues[chain_name]["memory"];
            memoryValues.shift();
            memoryValues.push(used_memory);
            let networkBandwidthValues =
              chainValues[chain_name]["networkBandwidth"];
            networkBandwidthValues.shift();
            networkBandwidthValues.push({
              send: network_send_bandwidth,
              receive: network_receive_bandwidth,
            });
            let networkRateValues = chainValues[chain_name]["networkRate"];
            networkRateValues.shift();
            networkRateValues.push({
              send: network_send_rate,
              receive: network_receive_rate,
            });
            let diskBandwidthValues = chainValues[chain_name]["diskBandwidth"];
            diskBandwidthValues.shift();
            diskBandwidthValues.push({
              read: disk_read_bandwidth,
              write: disk_write_bandwidth,
            });
            let diskRateValues = chainValues[chain_name]["diskRate"];
            diskRateValues.shift();
            diskRateValues.push({
              read: disk_read_rate,
              write: disk_write_rate,
            });
            let sequences = {
              core: coreValues,
              memory: memoryValues,
              networkBandwidth: networkBandwidthValues,
              networkRate: networkRateValues,
              diskBandwidth: diskBandwidthValues,
              diskRate: diskRateValues,
            };
            chainValues[chain_name] = sequences;
          }
        );
        setResources(data);
        setValueSequence(chainValues);
      }
    };

    const updateSystemResources = () => {
      querySystemResources(onQuerySuccess, onFail);
    };

    updateSystemResources();

    let timerID = setInterval(() => {
      if (mounted) {
        updateSystemResources();
      }
    }, UPDATE_INTERVAL);
    return () => {
      setMounted(false);
      clearInterval(timerID);
    };
  }, [initialized]);

  let session = getLoggedSession();
  if (!session) {
    return redirectToLogin();
  }

  let content = <div />;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    content = resources.map(
      ({
        chain_name,
        start_time,
        timestamp,
        core_usage,
        cores,
        used_memory,
        total_memory,
        used_disk,
        total_disk,
        online_peers,
        offline_peers,
        online_gateways,
        offline_gateways,
        domain_count,
        domains,
      }) => {
        let title = (
          <Typography
            variant="h5"
            key="title"
            className={classes.cardTitleWhite}
            sx={{
              fontWeight: "bold",
              letterSpacing: 3,
            }}
          >
            {chain_name.toUpperCase()}
          </Typography>
        );
        let nowTime = new Date();
        let elapsed = nowTime - new Date(start_time); //in milliseconds
        let updateTime = new Date(timestamp);
        let updateTimeString = updateTime.toLocaleString();
        let updateBefore = Math.round((nowTime - updateTime) / 1000);
        let duration = splitDuration(elapsed);
        let durationText;
        if (0 != duration.day) {
          durationText =
            duration.day + texts.tagDay + duration.hour + texts.tagHour;
        } else if (0 != duration.hour) {
          durationText =
            duration.hour + texts.tagHour + duration.minute + texts.tagMinute;
        } else if (0 != duration.minute) {
          durationText =
            duration.minute +
            texts.tagMinute +
            duration.second +
            texts.tagSecond;
        } else {
          durationText = duration.second + texts.tagSecond;
        }
        let updateMessage, uptimeMessage, lastUpdate;
        if ("cn" === lang) {
          updateMessage = "更新时间：" + updateTimeString;

          uptimeMessage = "门户已运行 " + durationText;

          lastUpdate = updateBefore.toString() + "秒前更新";
        } else {
          updateMessage = "Updated at " + updateTimeString;
          uptimeMessage = "Uptime: " + durationText;
          lastUpdate = "Updated " + updateBefore.toString() + " second(s) ago";
        }
        let updateInfo = (
          <Typography variant="caption" key="update">
            {updateMessage}
          </Typography>
        );
        let uptimeInfo = (
          <Typography variant="body2" key="uptime">
            {uptimeMessage}
          </Typography>
        );
        let clusterInfo = (
          <Stack direction="row" spacing={1} key="cluster">
            <Chip
              icon={<WifiIcon />}
              label={texts.onlinePeers + online_peers}
              color="success"
              size="small"
            ></Chip>
            <Chip
              icon={<WifiOffIcon />}
              label={texts.offlinePeers + offline_peers}
              color="warning"
              size="small"
            ></Chip>
            <Chip
              icon={<SwapVertIcon />}
              label={texts.onlineGateways + online_gateways}
              color="success"
              size="small"
            ></Chip>
            <Chip
              icon={<MobiledataOffIcon />}
              label={texts.offlineGateways + offline_gateways}
              color="warning"
              size="small"
            ></Chip>
            <Chip
              icon={<ViewQuiltIcon />}
              label={texts.domainCount + domain_count}
              color="info"
              size="small"
            ></Chip>
          </Stack>
        );
        const {
          core,
          memory,
          networkBandwidth,
          networkRate,
          diskBandwidth,
          diskRate,
        } = valueSequence[chain_name];

        let domainData = domains.map(
          ({
            domain_name,
            world_version,
            block_height,
            last_transaction,
            schema_count,
            contract_count,
            workflow_count,
          }) => [
            domain_name,
            world_version,
            block_height,
            last_transaction,
            schema_count,
            contract_count,
            workflow_count,
          ]
        );
        let domainInfo = (
          <Table
            color="primary"
            headers={[
              texts.domainName,
              texts.domainWorld,
              texts.domainHeight,
              texts.domainTransaction,
              texts.domainSchema,
              texts.domainContract,
              texts.domainWorkflow,
            ]}
            rows={domainData}
          />
        );
        let diskUsage = reduceUsage(used_disk, total_disk);
        let diskProgress = Math.round((diskUsage.used * 100) / diskUsage.quota);
        let diskInfo = (
          <Box
            sx={{
              flexDirection: "column",
              m: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography variant="caption">{texts.disk}</Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Typography variant="body2">
                {texts.tagUsedDisk +
                  diskUsage.used.toString() +
                  " / " +
                  diskUsage.quota.toString() +
                  " " +
                  diskUsage.unit}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
              <Box sx={{ width: "70%", ml: 3 }}>
                <LinearProgress
                  variant="determinate"
                  value={diskProgress}
                  sx={{ height: "3vh" }}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <Typography variant="caption">{diskProgress + "%"}</Typography>
              </Box>
            </Box>
          </Box>
        );
        let chainPanel = (
          <Card>
            <CardHeader color="primary">{title}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      m: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {[updateInfo, uptimeInfo, clusterInfo].map(
                      (element, index) => (
                        <Box sx={{ p: 1 }} key={index}>
                          {element}
                        </Box>
                      )
                    )}
                  </Box>
                </GridItem>
                <GridItem xs={6}>{diskInfo}</GridItem>
                <GridItem xs={12}>{domainInfo}</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        );

        let coreInfo = (
          <Box>
            <Card chart>
              <CardHeader color="success">
                <RealtimeLineChart
                  series={[
                    {
                      data: core,
                      label: texts.usedCores,
                    },
                  ]}
                  maxValue={cores}
                  interval={CHART_INTERVAL}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>{texts.coreUsage}</h4>
                <p className={classes.cardCategory}>
                  {texts.usedCores +
                    " " +
                    truncateToRadix(core_usage, 2).toString() +
                    " / " +
                    cores.toString()}
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> {lastUpdate}
                </div>
              </CardFooter>
            </Card>
          </Box>
        );
        let memoryUsage = reduceUsage(used_memory, total_memory);
        let memoryInfo = (
          <Box sx={{ height: "50%" }}>
            <Card chart>
              <CardHeader color="info">
                <RealtimeBarChart
                  series={[
                    {
                      data: memory,
                      label: texts.usedMemory,
                    },
                  ]}
                  maxValue={total_memory}
                  interval={CHART_INTERVAL}
                  axisFormatter={bytesToString}
                  mapValue={bytesToString}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>{texts.memoryUsage}</h4>
                <p className={classes.cardCategory}>
                  {texts.usedMemory +
                    " " +
                    memoryUsage.used +
                    "/" +
                    memoryUsage.quota +
                    " " +
                    memoryUsage.unit}
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> {lastUpdate}
                </div>
              </CardFooter>
            </Card>
          </Box>
        );

        let networkBandwidthSeries = splitTwoSeries(
          networkBandwidth,
          "receive",
          "send",
          texts.tagReceive,
          texts.tagSend,
          infoColor[3],
          successColor[3]
        );

        let networkBandwidthInfo = (
          <RealtimeBarCard
            title={texts.networkBandwidth}
            color="warning"
            series={networkBandwidthSeries}
            interval={2}
            updateText={lastUpdate}
            mapValue={bpsToString}
            axisFormatter={bpsToString}
          />
        );
        let networkRateSeries = splitTwoSeries(
          networkRate,
          "receive",
          "send",
          texts.tagReceive,
          texts.tagSend,
          grayColor[2],
          successColor[2]
        );
        let networkRateInfo = (
          <RealtimeBarCard
            title={texts.networkRate}
            color="rose"
            series={networkRateSeries}
            interval={2}
            updateText={lastUpdate}
          />
        );
        let diskBandwidthSeries = splitTwoSeries(
          diskBandwidth,
          "write",
          "read",
          texts.tagWrite,
          texts.tagRead,
          infoColor[3],
          warningColor[3]
        );
        let diskBandwidthInfo = (
          <RealtimeBarCard
            title={texts.diskBandwidth}
            color="success"
            series={diskBandwidthSeries}
            interval={2}
            updateText={lastUpdate}
            mapValue={bpsToString}
            axisFormatter={bpsToString}
          />
        );
        let diskRateSeries = splitTwoSeries(
          diskRate,
          "write",
          "read",
          texts.tagWrite,
          texts.tagRead,
          primaryColor[3],
          warningColor[3]
        );
        let diskRateInfo = (
          <RealtimeBarCard
            title={texts.diskRate}
            color="info"
            series={diskRateSeries}
            interval={2}
            updateText={lastUpdate}
          />
        );
        return (
          <GridContainer spacing={1} key="container">
            <GridItem xs={12}>{chainPanel}</GridItem>
            <GridItem xs={12} md={6}>
              {coreInfo}
            </GridItem>
            <GridItem xs={12} md={6}>
              {memoryInfo}
            </GridItem>
            <GridItem xs={12} md={6}>
              {networkBandwidthInfo}
            </GridItem>
            <GridItem xs={12} md={6}>
              {networkRateInfo}
            </GridItem>
            <GridItem xs={12} md={6}>
              {diskBandwidthInfo}
            </GridItem>
            <GridItem xs={12} md={6}>
              {diskRateInfo}
            </GridItem>
          </GridContainer>
        );
      }
    );
  }
  return (
    <Container maxWidth="xl">
      <Stack spacing={2}>
        {content}
        <ToastContainer autoClose={3500} draggable={false} />
      </Stack>
      <Divider />
    </Container>
  );
}

SystemResources.prototype = {
  lang: PropTypes.string.isRequired,
};
