import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "components/CustomButtons/IconButton";
import { getApplication } from "backend_api.js";
import { getPrivilegeLabels } from "privileges";

const i18n = {
  en: {
    title: "Detail of Application: ",
    back: "Back",
    version: "Format Version",
    id: "ID",
    name: "Name",
    group: "Application Group",
    owner: "Owner",
    issuer: "Issuer",
    signed: "Signed: ",
    privilegedOprations: "Privileged Oprations",
    domainPrivileges: "Domain Privileges - ",
  },
  cn: {
    title: "应用证书详情： ",
    back: "返回",
    version: "格式版本",
    id: "标识",
    name: "名称",
    group: "所属应用组",
    owner: "拥有者",
    issuer: "签发者",
    signed: "已签名：",
    privilegedOprations: "授权操作",
    domainPrivileges: "数据域权限 - ",
  },
};

export default function ApplicationDetail(props) {
  const { lang, appID, onList } = props;
  const [detailData, setDetailData] = React.useState(null);

  const showErrorMessage = React.useCallback((msg) => {
    toast.error(msg);
  }, []);

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const reloadDetail = React.useCallback(() => {
    const onGetDetailSuccess = (data) => {
      setDetailData(data);
    };
    getApplication(appID, onGetDetailSuccess, onFail);
  }, [onFail]);

  React.useEffect(() => {
    reloadDetail();
  }, [reloadDetail]);

  const texts = i18n[lang];
  let content, title;
  let buttons = [];
  if (null === detailData) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const {name, group, privileges, identity } = detailData;
    const {
      version,
      id,
      issuer,
      signature_algorithm,
      signature,
    } = identity;
    const signatureChip = (
      <Tooltip title={signature_algorithm + ": " + signature} key="signature">
        <Chip label={signature_algorithm} color="success" />
      </Tooltip>
    );
    let tableData = [
      {
        title: texts.version,
        value: version,
      },
      {
        title: texts.id,
        value: id,
      },
      {
        title: texts.name,
        value: name,
      },
      {
        title: texts.group,
        value: group,
      },
      {
        title: texts.issuer,
        value: issuer,
      },
      {
        title: texts.signed,
        value: signatureChip,
      },
    ];
    const { actions, domain_privileges } = privileges;
    if (actions){
      let availablePrivileges = getPrivilegeLabels(lang, actions);
      tableData.push({
        title: texts.privilegedOprations,
        value: (
          <Typography variant="body2">
            <ul>
              {availablePrivileges.map((label, i) => (
                <li key={i}>{label}</li>
              ))}
            </ul>
          </Typography>
        ),
      });
    }

    if (domain_privileges){
      domain_privileges.forEach((item, i) => {
        const {name, actions} = item;
        if (actions){
          let availablePrivileges = getPrivilegeLabels(lang, actions);
          tableData.push({
            title: texts.domainPrivileges + name,
            value: (
              <Typography variant="body2" key={i}>
                <ul>
                  {availablePrivileges.map((label, i) => (
                    <li key={i}>{label}</li>
                  ))}
                </ul>
              </Typography>
            ),
          });
        }
      });
    }

    content = (
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {tableData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell component={'div'}>{row.value}</TableCell>
                  <TableCell>
                    {row.operators
                      ? row.operators.map(({ label, icon, onClick }, key) => (
                          <IconButton key={key} label={label} icon={icon} onClick={onClick} size="large" />
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );

    title = texts.title + appID;
    buttons = [
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={onList}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

ApplicationDetail.propTypes = {
  appID: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
