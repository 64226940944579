import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// import { Router, Redirect } from "@reach/router";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import makeStyles from '@mui/styles/makeStyles';
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { appStyle } from "assets/jss/custom-styles";
import allRoutes from "routes.js";
import bgImage from "assets/img/sidebar.jpg";
// import logo from "assets/img/reactlogo.png";
// import logo from "assets/img/nano.ico";
import logo from "assets/img/nano_white.svg";
import { getLoggedSession, redirectToLogin } from "utils.js";
import { PROJECT_NAME } from "project.js";

let ps;

function SwitchRoutes(props) {
  const { routes, ...rest } = props;
  const first = routes[0];
  const firstPath = first.layout + first.path;
  return (
    <Switch>
      {routes.map((item, key) => {
        if (item.layout === "/admin") {
          return (
            <Route
              path={item.layout + item.path}
              render={() => React.createElement(item.component, rest)}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to={firstPath} />
    </Switch>
  );
}

SwitchRoutes.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      layout: PropTypes.string,
      path:PropTypes.string,
    })
  ).isRequired,
};

const useStyles = makeStyles(appStyle);

export default function AdminLayout({ lang, setLang, ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (mainPanel.current && navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (ps && navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  let session = getLoggedSession();
  if (null === session) {
    return redirectToLogin();
  }
  const menuList = session.menu;
  let authorizedMenus = [];
  allRoutes.forEach((menu) => {
    if (
      menuList.some((name) => {
        if (name === menu.name) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      authorizedMenus.push(menu);
    }
  });

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={authorizedMenus}
        logoText={PROJECT_NAME + " Portal"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="blue"
        lang={lang}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={authorizedMenus}
          handleDrawerToggle={handleDrawerToggle}
          lang={lang}
          setLang={setLang}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <SwitchRoutes lang={lang} routes={authorizedMenus} />
          </div>
        </div>
      </div>
    </div>
  );
}

AdminLayout.propTypes = {
  lang: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};
