import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RoleTab from "views/Access/RoleTab";
import GroupTab from "views/Access/GroupTab";
import ManagerTab from "views/Access/ManagerTab";

const i18n = {
  en: {
    tabManagers: "Managers",
    tabGroups: "Groups",
    tabRoles: "Roles",
  },
  cn: {
    tabManagers: "管理员",
    tabGroups: "用户组",
    tabRoles: "角色",
  },
};

export default function MainTabs(properties) {
  const texts = i18n[properties.lang];
  const [tab, setTab] = React.useState("managers");

  const showErrorMessage = (msg) => {
    toast.error(msg);
  };

  const showSuccessMessage = (msg) => {
    toast.success(msg);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1", bgcolor: "background.paper" }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label={texts.tabManagers} value="managers" />
            <Tab label={texts.tabGroups} value="groups" />
            <Tab label={texts.tabRoles} value="roles" />
          </TabList>
        </Box>
        <TabPanel value="managers">
          <ManagerTab
            lang={properties.lang}
            onError={showErrorMessage}
            onSuccess={showSuccessMessage}
          />
        </TabPanel>
        <TabPanel value="groups">
          <GroupTab lang={properties.lang} onError={showErrorMessage} />
        </TabPanel>
        <TabPanel value="roles">
          <RoleTab lang={properties.lang} />
        </TabPanel>
      </TabContext>
      <ToastContainer autoClose={3500} draggable={false} />
    </Box>
  );
}

MainTabs.prototype = {
  lang: PropTypes.string.isRequired,
};
