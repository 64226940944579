import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import ContractList from "views/Contracts/ContractList";
import ContractDetail from "views/Contracts/ContractDetail";
import CreateContract from "views/Contracts/CreateContract";
import ModifyContract from "views/Contracts/ModifyContract";
import TestContract from "views/Contracts/TestContract";
import ContractLogs from "views/Contracts/ContractLogs";
import ContractActorList from "./ContractActorList";
import MustInitialized from "views/Login/MustInitialized";

export default function Contracts(properties) {
  const component = (
    <div>
      <Route
        path="/admin/contracts"
        exact
        render={() => React.createElement(ContractList, properties)}
      />
      <Route
        path="/admin/contracts/new_contract/"
        exact
        render={() => React.createElement(CreateContract, properties)}
      />
      <Route
        path="/admin/contracts/view/:contract"
        exact
        render={(current) =>
          React.createElement(ContractDetail, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/contracts/modify/:contract"
        exact
        render={(current) =>
          React.createElement(ModifyContract, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/contracts/test/:contract"
        exact
        render={(current) =>
          React.createElement(TestContract, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/contracts/versions/:contract"
        exact
        render={(current) =>
          React.createElement(ContractLogs, {
            ...properties,
            ...current,
          })
        }
      />
      <Route
        path="/admin/contracts/actors/:contract"
        exact
        render={(current) =>
          React.createElement(ContractActorList, {
            ...properties,
            ...current,
          })
        }
      />

    </div>
  );
  return <MustInitialized lang={properties.lang} component={component} />;
}

Contracts.prototype = {
  lang: PropTypes.string.isRequired,
};
