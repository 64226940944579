import React from "react";
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import makeStyles from '@mui/styles/makeStyles';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const mapBarOptions = (
  series,
  maxValue,
  beginTime,
  mapValue,
  axisFormatter
) => {
  const defaultBrushColor = "white";
  const backgroundColor = "#ccc";
  let options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      containLabel: true,
    },
    series: series.map(({ label, data, color }) => ({
      name: label,
      data: data,
      type: "bar",
      // symbol: "circle",
      // symbolSize: 10,
      itemStyle: {
        color: color,
        borderColor: color,
        borderWidth: 2,
        opacity: 0.9,
      },
      lineStyle: {
        width: 4,
        opacity: 0.8,
      },
    })),
    xAxis: {
      type: "time",
      boundaryGap: false,
      min: beginTime,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
    },
    yAxis: {
      type: "value",
      splitNumber: 4,
      axisLabel: {
        color: defaultBrushColor,
      },
      axisLine: {
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: backgroundColor,
          type: [1, 3],
        },
      },
    },
  };
  if (maxValue) {
    options.yAxis.max = maxValue;
  }
  if (axisFormatter) {
    options.yAxis.axisLabel.formatter = axisFormatter;
  }
  return options;
};

export default function RealtimeBarCard(properties) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    title,
    color,
    series,
    maxValue,
    interval,
    updateText,
    mapValue,
    axisFormatter,
  } = properties;
  let dataCount = series[0].data.length;
  let timeSequence = [];
  let now = new Date();
  for (let i = 0; i < dataCount; i++) {
    timeSequence.unshift(now - i * interval * 1000);
  }
  const beginTime = timeSequence[0];
  let lastValueLabels = [];
  let seriesWithTimestamp = [];
  series.forEach(({ data, label, color }) => {
    //map to timeline
    let mappedData = data.map(
      (value, index) => new Array(timeSequence[index], value)
    );
    const lastValue = data[data.length - 1];
    let valueString;
    if (mapValue) {
      valueString = mapValue(lastValue);
    } else {
      valueString = lastValue.toString();
    }
    lastValueLabels.push(label + ": " + valueString);
    seriesWithTimestamp.push({
      data: mappedData,
      color: color,
      label: label,
    });
  });

  let options = mapBarOptions(
    seriesWithTimestamp,
    maxValue,
    beginTime,
    mapValue,
    axisFormatter
  );
  let footer;
  if (updateText) {
    footer = (
      <CardFooter chart>
        <div className={classes.stats}>
          <AccessTimeIcon /> {updateText}
        </div>
      </CardFooter>
    );
  } else {
    footer = <CardFooter />;
  }
  return (
    <Card chart>
      <CardHeader color={color}>
        <ReactECharts option={options} />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>{title}</h4>
        <p className={classes.cardCategory}>{lastValueLabels.join(" / ")}</p>
      </CardBody>
      {footer}
    </Card>
  );
}

RealtimeBarCard.prototype = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number),
    })
  ).isRequired,
  maxValue: PropTypes.number,
  interval: PropTypes.number.isRequired,
  updateText: PropTypes.string,
  mapValue: PropTypes.func,
  axisFormatter: PropTypes.func,
};
