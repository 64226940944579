import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
// import Hidden from "@mui/material/Hidden";
import Person from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import Button from "components/CustomButtons/Button.js";
import ModifyPasswordDialog from "components/LoggedUser/ModifyPasswordDialog.js";
import { headerLinksStyle } from "assets/jss/custom-styles.js";
import { getLoggedSession, logoutSession, redirectToLogin } from "utils.js";
import { updateSession, writeLog } from "backend_api.js";

const useStyles = makeStyles(headerLinksStyle);

const i18n = {
  en: {
    modify: "Modify Password",
    logout: "Logout",
  },
  cn: {
    modify: "修改密码",
    logout: "注销",
  },
};

export default function LoggedUser(props) {
  const classes = useStyles();
  const { lang } = props;
  const texts = i18n[lang];
  const [mounted, setMounted] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const handleClickProfile = (event) => {
    if (!mounted) {
      return;
    }
    if (!open) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const closeMenu = () => {
    if (!mounted) {
      return;
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const logout = () => {
    if (!mounted) {
      return;
    }

    const onFinished = () => {
      logoutSession();
      closeMenu();
    };
    writeLog("logout", onFinished, onFinished);
  };

  const showModifyPassword = () => {
    setDialogVisible(true);
    closeMenu();
  };

  const closeModifyPassword = () => {
    setDialogVisible(false);
  };

  const keepAlive = React.useCallback(() => {
    const onUpdateFail = () => {
      logoutSession();
    };
    updateSession(onUpdateFail);
  }, []);

  //mount/unmount only
  React.useEffect(() => {
    setMounted(true);
    keepAlive();
    let session = getLoggedSession();
    if (!session) {
      return;
    }

    setUsername(session.user);

    let updateInterval = (session.timeout * 1000 * 2) / 3;
    // let updateInterval = 3000;
    let timerID = setInterval(() => {
      keepAlive();
    }, updateInterval);
    return () => {
      setMounted(false);
      if (timerID) {
        clearInterval(timerID);
      }
    };
  }, [keepAlive]);

  //render begin
  let session = getLoggedSession();
  if (!session) {
    return redirectToLogin();
  }

  return (
    <div className={classes.manager}>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        simple={!(window.innerWidth > 959)}
        aria-owns={anchorEl ? "profile-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleClickProfile}
        className={classes.buttonLink}
        sx={{ border: 1 }}
      >
        {username}
        <Person className={classes.icons} />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={showModifyPassword}>
          <Typography component="div" variant="overline">
            {texts.modify}
          </Typography>
        </MenuItem>
        <Divider light />
        <MenuItem onClick={logout}>
          <Typography component="div" variant="overline">
            {texts.logout}
          </Typography>
        </MenuItem>
      </Menu>
      <ModifyPasswordDialog
        lang={lang}
        open={dialogVisible}
        user={username}
        onSuccess={closeModifyPassword}
        onCancel={closeModifyPassword}
      />
    </div>
  );
}

LoggedUser.propTypes = {
  lang: PropTypes.string.isRequired,
};
