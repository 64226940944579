import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckIcon from "@mui/icons-material/Check";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const i18n = {
  en: {
    version: "Version",
    invoker: "Invoker",
    block: "Block",
    promptStored: "Persistent stored in ",
    transaction: "Transaction",
    promptStore: "Persistent Stored",
    promptCommitted: "Committed",
  },
  cn: {
    version: "版本号",
    invoker: "触发者",
    block: "区块",
    promptStored: "已持久化存储到",
    transaction: "交易",
    promptStore: "已持久化存储",
    promptCommitted: "已提交",
  },
};

export default function LogTimeline(props) {
  const { lang, logs } = props;
  const texts = i18n[lang];
  let items = [];
  if (logs) {
    const itemCount = logs.length;
    items = logs.map(
      (
        { version, timestamp, operate, invoker, block, transaction, confirmed },
        index
      ) => {
        const time = (
          <TimelineOppositeContent color="text.secondary">
            <Typography sx={{ pt: 6 }} variant="body2">
              {new Date(timestamp).toLocaleString()}
            </Typography>
          </TimelineOppositeContent>
        );
        let dot = (
          <TimelineDot key="dot" color={confirmed ? "success" : "info"}>
            <Tooltip
              title={confirmed ? texts.promptStore : texts.promptCommitted}
            >
              {confirmed ? <CheckIcon /> : <WatchLaterIcon />}
            </Tooltip>
          </TimelineDot>
        );
        let storageText;
        if (confirmed) {
          storageText = (
            <Typography variant="body2" component="div" sx={{ p: 1 }}>
              {texts.promptStored}
              <Link href={"/admin/blocks/" + block}>{texts.block}</Link>
              {", " + texts.transaction}
              <Link
                href={"/admin/blocks/" + block + "/transactions/" + transaction}
              >
                {transaction}
              </Link>
            </Typography>
          );
        } else {
          storageText = (
            <Typography variant="body2" component="div" sx={{ p: 1 }}>
              {texts.transaction + transaction + texts.promptCommitted}
            </Typography>
          );
        }
        let content = (
          <TimelineContent
            sx={{
              m: 0,
            }}
          >
            <Typography variant="subtitle1" sx={{ p: 1 }}>
              {texts.version + " " + version.toString() + " : " + operate}
            </Typography>
            {storageText}
            <Typography variant="caption" component="div" sx={{ p: 1 }}>
              {texts.invoker + ":" + invoker}
            </Typography>
          </TimelineContent>
        );
        return (
          <TimelineItem key={index}>
            {time}
            <TimelineSeparator>
              <TimelineConnector
                sx={{
                  visibility: 0 === index ? 'hidden': 'visible',
                }}/>
                {dot}
              <TimelineConnector
                sx={{
                  visibility: index === itemCount - 1? 'hidden': 'visible',
                }}/>
            </TimelineSeparator>
            {content}
          </TimelineItem>
        );
      }
    );
  }
  return <Timeline position="alternate">{items}</Timeline>;
}

LogTimeline.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
  lang: PropTypes.string.isRequired,
};
