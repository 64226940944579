import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import { getSchema, getDocument } from "backend_api.js";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";

const i18n = {
  en: {
    title: "Properties of Digital Asset: ",
    back: "Back",
    propertyName: "Property Name",
    propertyType: "Value Type",
    propertyValue: "Property Value",
    tagTypeString: "String",
    tagTypeInteger: "Integer",
    tagTypeFloat: "Float",
    tagTypeBool: "Boolean",
    tagTypeCurrency: "Currency",
    operates: "Operates",
    editButton: "Enter Modify",
    submitButton: "Submit",
    updateButton: "Update Property",
  },
  cn: {
    title: "数字资产属性列表： ",
    back: "返回",
    propertyName: "属性名",
    propertyType: "属性值类型",
    propertyValue: "属性值",
    tagTypeString: "字符串",
    tagTypeInteger: "整数",
    tagTypeFloat: "浮点数",
    tagTypeBool: "布尔类型",
    tagTypeCurrency: "货币",
    operates: "操作",
    editButton: "进入编辑模式",
    submitButton: "提交变更",
    updateButton: "修改属性",
  },
};

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function DocumentDetail(props) {
  const schemaName = props.match.params.schema;
  const docID = props.match.params.id;
  const [initialed, setInitialed] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [detailData, setDetailData] = React.useState(null);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(msg);
    },
    [showErrorMessage, mounted]
  );

  const reloadDetail = React.useCallback(() => {
    let schema, doc;
    const onGetDocSuccess = (data) => {
      doc = data;
      setDetailData({
        schema: schema,
        doc: doc,
      });
      setInitialed(true);
    };
    const onGetSchemaSuccess = (data) => {
      schema = data;
      getDocument(
        defaultChainName,
        defaultDomainName,
        schemaName,
        docID,
        onGetDocSuccess,
        onFail
      );
    };

    getSchema(
      defaultChainName,
      defaultDomainName,
      schemaName,
      onGetSchemaSuccess,
      onFail
    );
  }, [onFail, schemaName, docID]);

  React.useEffect(() => {
    setMounted(true);
    reloadDetail();
    return () => {
      setMounted(false);
    };
  }, [reloadDetail]);

  const { lang } = props;
  const texts = i18n[lang];
  let content, title;
  let buttons = [];
  if (!initialed) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const { schema, doc } = detailData;
    const docObject = JSON.parse(doc.content);
    let rows = [];
    schema.properties.forEach((property) => {
      const { name, type } = property;
      let valueTag, value;
      let exists = Object.prototype.hasOwnProperty.call(docObject, name);
      if (exists) {
        value = docObject[name];
      }
      switch (type) {
        case "int":
          valueTag = texts.tagTypeInteger;
          if (!exists) {
            value = 0;
          }
          break;
        case "bool":
          valueTag = texts.tagTypeBool;
          if (!exists) {
            value = false;
          }
          break;
        case "float":
          valueTag = texts.tagTypeFloat;
          if (!exists) {
            value = 0.0;
          }
          break;
        case "currency":
          valueTag = texts.tagTypeCurrency;
          if (!exists) {
            value = 0.0;
          }
          break;
        default:
          valueTag = texts.tagTypeString;
          if (!exists) {
            value = "";
          }
      }
      rows.push([name, valueTag, value.toString()]);
    });
    content = (
      <Table
        color="primary"
        headers={[texts.propertyName, texts.propertyType, texts.propertyValue]}
        rows={rows}
      />
    );

    title = texts.title + schemaName + "." + docID;
    buttons = [
      <Button
        key="back"
        size="sm"
        color="info"
        round
        onClick={() => {
          const listURL = "/admin/assets/schemas/" + schemaName + "/docs/";
          window.location.assign(listURL);
          // props.history.goBack();
        }}
      >
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

DocumentDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
