import React from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { InputComponent } from "components/CustomInput/InputList";
import {
  changeManagerGroup,
  getManager,
  queryGroups,
  writeLog,
} from "backend_api.js";

const i18n = {
  en: {
    title: "Modify Manager Groups - ",
    back: "Back",
    group: "Group",
    name: "Name",
    description: "Description",
    submitButton: "Submit",
  },
  cn: {
    title: "修改管理员所属组 - ",
    back: "返回",
    group: "用户组",
    name: "名称",
    description: "描述",
    submitButton: "提交变更",
  },
};

export default function ChangeManagerGroup(props) {
  const { lang, managerID, onList, onError, onSuccess } = props;
  const texts = i18n[lang];
  const defaultValues = {
    group: '',
    availables: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const handleGroupChanged = (e) => {
    if (!mounted) {
      return;
    }
    let group = e.target.value;
    setRequest((previous) => ({
      ...previous,
      group: group,
    }));
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified groups of manager " + managerID);
      onSuccess("groups of manager " + managerID + " modified");
      onList();
    };

    if (!request.group) {
      onError("invalid application group");
      return;
    }

    changeManagerGroup(managerID, request.group, onOperateSuccess, onError);
  };

  React.useEffect(() => {
    setMounted(true);
    if (!managerID) {
      return;
    }

    let allGroups = [];

    const onGetSuccess = ({ group }) => {
      if (!mounted) {
        return;
      }
      let request = {
        group: group,
        availables: allGroups,
      };
      setRequest(request);
      setInitialized(true);
    };

    const onQuerySuccess = ({ groups }) => {
      if (!mounted) {
        return;
      }
      allGroups = groups;
      getManager(managerID, onGetSuccess, onError);
    };

    queryGroups(onQuerySuccess, onError);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, managerID, onError]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let groupOptions = request.availables.map(({ name, description }) => {
      return ({
        label: name + ' - ' + description,
        value: name,
      })
    });

    const groupComponent = (
      <InputComponent
        key="group"
        type="radio"
        label={texts.group}
        value={request.group}
        options={groupOptions}
        onChange={handleGroupChanged}
      />
    )

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Typography>{texts.group}</Typography>
        {groupComponent}
      </Stack>
    );

    title = texts.title + managerID;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

ChangeManagerGroup.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  managerID: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
