/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Contracts from "views/Contracts/Contracts";
import Assets from "views/Assets/Assets";
import Blocks from "views/Blocks/Blocks";
import Chains from "views/Chains/Chains";
import Dashboard from "views/Dashboard/Dashboard";
import OperateLogs from "views/Logs/OperateLogs";
import DigitalIdentities from "views/Identities/Routes";
import Applications from "views/Applications/Routes";
import AccessControl from "views/Access/Routes";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import WidgetsIcon from "@mui/icons-material/Widgets";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    display: {
      cn: "系统仪表盘",
      en: "Dashboard",
    },
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/assets",
    name: "assets",
    display: {
      cn: "数字资产",
      en: "Digital Assets",
    },
    icon: AssessmentIcon,
    component: Assets,
    layout: "/admin",
  },
  {
    path: "/contracts",
    name: "contracts",
    display: {
      cn: "智能合约",
      en: "Smart Contracts",
    },
    icon: PlaylistPlayIcon,
    component: Contracts,
    layout: "/admin",
  },
  {
    path: "/blocks",
    name: "blocks",
    display: {
      cn: "区块与交易",
      en: "Blocks&Transaction",
    },
    icon: LinearScaleIcon,
    component: Blocks,
    layout: "/admin",
  },
  {
    path: "/chains",
    name: "chains",
    display: {
      cn: "链集群",
      en: "Chain Cluster",
    },
    icon: DeviceHubIcon,
    component: Chains,
    layout: "/admin",
  },
  {
    path: "/identities",
    name: "identities",
    display: {
      cn: "数字身份与证书",
      en: "Digital Identities",
    },
    icon: FingerprintIcon,
    component: DigitalIdentities,
    layout: "/admin",
  },
  {
    path: "/applications",
    name: "applications",
    display: {
      cn: "链应用",
      en: "Chain Applications",
    },
    icon: WidgetsIcon,
    component: Applications,
    layout: "/admin",
  },
  {
    path: "/access",
    name: "access",
    display: {
      cn: "访问控制",
      en: "Access Control",
    },
    icon: SupervisorAccountIcon,
    component: AccessControl,
    layout: "/admin",
  },
  {
    path: "/logs",
    name: "log",
    display: {
      cn: "操作日志",
      en: "Operate Logs",
    },
    icon: ListAltIcon,
    component: OperateLogs,
    layout: "/admin",
  },
];

export default dashboardRoutes;
