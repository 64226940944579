import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// dashboard components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import AuthenticatedButtons from "components/CustomButtons/AuthenticatedButtons";
import Table from "components/Table/ObjectTable.js";
import { getDomainStatus } from "backend_api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const i18n = {
  en: {
    back: "Back",
    tableTitle: "Domain - ",
    domainWorld: "World Version",
    domainHeight: "Block Height",
    domainTransaction: "Last Transaction ID",
    domainSchema: "Schema Count",
    domainContract: "Contract Count",
    domainWorkflow: "Workflow Count",
    view: "Current View Version ",
    member: "Member",
    address: "Address",
    role: "Role",
    tagLeader: "Leader",
    tagFollower: "Follower",
    tagObserver: "Observer",
    alive: "Alive",
    tagOnline: "Online",
    tagOffline: "Offline",
    noRecord: "No committees member available",
  },
  cn: {
    back: "返回",
    tableTitle: "数据域 - ",
    domainWorld: "世界版本",
    domainHeight: "区块高度",
    domainTransaction: "最近交易ID",
    domainSchema: "资产类别",
    domainContract: "智能合约",
    domainWorkflow: "工作流",
    view: "当前视图版本 ",
    member: "委员会节点",
    address: "地址",
    role: "角色",
    tagLeader: "主节点",
    tagFollower: "从节点",
    tagObserver: "观察员节点",
    alive: "是否在线",
    tagOnline: "在线",
    tagOffline: "离线",
    noRecord: "无委员会节点",
  },
};

export default function DomainStatus(props) {
  const chainName = props.match.params.chain;
  const domainName = props.match.params.domain;
  const REFRESH_INTERVAL = 3; //3 seconds
  const { lang } = props;
  const texts = i18n[lang];
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const reloadData = React.useCallback(() => {
    if (!mounted) {
      return;
    }
    const onLoadFail = (err) => {
      if (!mounted) {
        return;
      }
      showErrorMessage(err);
    };

    const onLoadSuccess = (result) => {
      if (!mounted) {
        return;
      }
      setData(result);
      setInitialized(true);
    };

    getDomainStatus(chainName, domainName, onLoadSuccess, onLoadFail);
  }, [showErrorMessage, chainName, domainName, mounted]);

  const dataToNodes = (member) => {
    const { id, address, role, alive } = member;
    let aliveIcon;
    if (alive) {
      aliveIcon = (
        <Tooltip title={texts.tagOnline} placement="top">
          <WifiIcon color="success" />
        </Tooltip>
      );
    } else {
      aliveIcon = (
        <Tooltip title={texts.tagOffline} placement="top">
          <WifiOffIcon color="warning" />
        </Tooltip>
      );
    }
    let roleLabel;
    switch (role) {
      case "leader":
        roleLabel = texts.tagLeader;
        break;
      case "follower":
        roleLabel = texts.tagFollower;
        break;
      default:
        roleLabel = texts.tagObserver;
    }
    return [id, roleLabel, aliveIcon, address];
  };

  React.useEffect(() => {
    setMounted(true);
    reloadData();
    const updateInterval = REFRESH_INTERVAL * 1000;
    let timerID = setInterval(() => {
      if (!mounted) {
        return;
      }
      reloadData();
    }, updateInterval);
    return () => {
      setMounted(false);
      if (timerID) {
        clearInterval(timerID);
      }
    };
  }, [reloadData]);

  //begin rendering
  let content;
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else {
    const {
      world_version,
      block_height,
      last_transaction,
      schema_count,
      contract_count,
      workflow_count,
      updated_time,
      view_version,
      members,
    } = data;
    const domainHeaders = [
      texts.domainWorld,
      texts.domainHeight,
      texts.domainTransaction,
      texts.domainSchema,
      texts.domainContract,
      texts.domainWorkflow,
    ];
    const domainRows = [
      [
        world_version.toString(),
        block_height.toString(),
        last_transaction,
        schema_count.toString(),
        contract_count.toString(),
        workflow_count.toString(),
      ],
    ];
    const domainComponent = (
      <Table color="primary" headers={domainHeaders} rows={domainRows} />
    );

    let memberComponent;
    if (!members || 0 === members.length) {
      memberComponent = (
        <Box display="flex" justifyContent="center">
          <Info>{texts.noRecord}</Info>
        </Box>
      );
    } else {
      const memberHeaders = [
        texts.member,
        texts.role,
        texts.alive,
        texts.address,
      ];
      let memberRows = members.map((member) => dataToNodes(member));
      memberComponent = (
        <Table color="primary" headers={memberHeaders} rows={memberRows} />
      );
    }

    const contentCard = (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {texts.tableTitle + domainName}
          </h4>
        </CardHeader>
        <CardBody>
          {domainComponent}
          <Box sx={{ m: 2 }}>
            <Typography variant="body2">
              {texts.view +
                view_version.toString() +
                " (" +
                new Date(updated_time).toLocaleString() +
                ")"}
            </Typography>
          </Box>
          <Box sx={{ m: 2, pl: 3 }}>{memberComponent}</Box>
        </CardBody>
      </Card>
    );

    content = (
      <GridContainer>
        <GridItem xs={12}>{contentCard}</GridItem>
      </GridContainer>
    );
  }

  const buttons = [
    {
      onClick: () => {
        const listURL = "/admin/chains";
        window.location.assign(listURL);
      },
      label: texts.back,
      icon: NavigateBeforeIcon,
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <AuthenticatedButtons buttons={buttons} />
      </GridItem>
      <GridItem xs={12}>{content}</GridItem>
      <ToastContainer autoClose={3500} draggable={false} />
    </GridContainer>
  );
}

DomainStatus.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  lang: PropTypes.string.isRequired,
};
