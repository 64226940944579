import axios from "axios";
import CryptoJS from "crypto-js";
import URL from "url";
import { getLoggedSession } from "utils.js";
import { PROJECT_NAME, API_HOST } from "project.js";

const apiRoot = API_HOST + "/api/v1";
const HEADER_NAME_SESSION = PROJECT_NAME + "-Session";
const HEADER_NAME_TIMESTAMP = PROJECT_NAME + "-Timestamp";
const HEADER_NAME_SIGNATURE = PROJECT_NAME + "-Signature";
const HEADER_NAME_SIGNATURE_ALGORITHN = PROJECT_NAME + "-SignatureAlgorithm";
const DEFAULT_HASH_METHOD = "sha256";
const DEFAULT_SIGNATURE_METHOD = "hmac-256";

let npmPackage = require("../package.json");

export function getCurrentVersion() {
  return npmPackage.version;
}

export function isSystemResource(name) {
  return name.startsWith("_");
}

export function getAllMenus(lang) {
  const i18n = {
    en: {
      dashboard: "Dashboard",
      assets: "Digital Assets",
      contracts: "Smart Contracts",
      blocks: "Blocks",
      identities: "Ditigal Identities",
      applications: "Applications",
      access: "Access Control",
      user: "Users",
      log: "Logs",
    },
    cn: {
      dashboard: "仪表盘",
      assets: "数字资产",
      contracts: "智能合约",
      blocks: "区块与交易",
      chains: "链集群",
      identities: "数字身份与证书",
      applications: "链应用",
      access: "访问控制",
      user: "用户",
      log: "日志",
    },
  };
  const texts = i18n[lang];
  const menus = [
    {
      value: "dashboard",
      label: texts.dashboard,
    },
    {
      value: "assets",
      label: texts.assets,
    },
    {
      value: "contracts",
      label: texts.contracts,
    },
    {
      value: "blocks",
      label: texts.blocks,
    },
    {
      value: "chains",
      label: texts.chains,
    },
    {
      value: "identities",
      label: texts.identities,
    },
    {
      value: "applications",
      label: texts.applications,
    },
    {
      value: "access",
      label: texts.access,
    },
    {
      value: "user",
      label: texts.user,
    },
    {
      value: "log",
      label: texts.log,
    },
  ];
  return menus;
}

export function loginUser(name, password, nonce, onSuccess, onFail) {
  let hashed = CryptoJS.SHA256(password);
  let encodedSecret = CryptoJS.enc.Base64.stringify(hashed);
  let timestamp = new Date().toISOString();
  let payload = JSON.stringify({
    user: name,
    timestamp: timestamp,
    nonce: nonce,
    hash_method: DEFAULT_HASH_METHOD,
    hashed_code: encodedSecret,
    signature_algorithm: DEFAULT_SIGNATURE_METHOD,
  });
  let signature = CryptoJS.HmacSHA256(payload, nonce);
  let encodedSignature = CryptoJS.enc.Base64.stringify(signature);

  let request = {
    user: name,
    hash_method: DEFAULT_HASH_METHOD,
    encoded_secret: encodedSecret,
    nonce: nonce,
  };
  let url = "/sessions/";
  let config = {
    method: "post",
    url: apiRoot + url,
    headers: {
      [HEADER_NAME_TIMESTAMP]: timestamp,
      [HEADER_NAME_SIGNATURE]: encodedSignature,
      [HEADER_NAME_SIGNATURE_ALGORITHN]: DEFAULT_SIGNATURE_METHOD,
    },
    data: request,
  };
  axios(config)
    .then(({ data }) => {
      if (0 !== data.error_code) {
        if (onFail) {
          onFail(data.message);
        }
        return;
      }
      if (onSuccess) {
        onSuccess(data.data);
      }
      return;
    })
    .catch((e) => {
      if (onFail) {
        onFail(e.message);
      }
    });
}

export function updateSession(onFail) {
  doRequest("put", "/sessions/", "", null, onFail);
}

export function queryAllChains(onSuccess, onFail) {
  doRequest("get", "/chains/", "", onSuccess, onFail);
}

export function querySystemResources(onSuccess, onFail) {
  doRequest("get", "/resources/", "", onSuccess, onFail);
}

export function queryContracts(
  chainName,
  domainName,
  offset,
  limit,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/domains/" + domainName + "/contracts/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getContract(
  chainName,
  domainName,
  contractName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName;
  doRequest("get", url, null, onSuccess, onFail);
}

export function getContractInfo(
  chainName,
  domainName,
  contractName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName + 
    "/info/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function getContractActors(
  chainName,
  domainName,
  contractName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName +
    "/actors/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function updateContractActors(
  chainName,
  domainName,
  contractName,
  actors,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName +
    "/actors/";
  let request = {
    actors: actors,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function deployContract(
  chainName,
  domainName,
  contractName,
  content,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName;
  let request = {
    content: content,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function withdrawContract(
  chainName,
  domainName,
  contractName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function testContract(
  chainName,
  domainName,
  contractName,
  parameters,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName +
    "/test/";
  let request = {
    parameters: parameters,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function enableContractTrace(
  chainName,
  domainName,
  contractName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName + 
    "/trace/";
  const payload = {
    enable: true
  };
  doRequest("put", url, payload, onSuccess, onFail);
}

export function disableContractTrace(
  chainName,
  domainName,
  contractName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/contracts/" +
    contractName + 
    "/trace/";
  const payload = {
    enable: false
  };
  doRequest("put", url, payload, onSuccess, onFail);
}

export function querySchemas(
  chainName,
  domainName,
  offset,
  limit,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/domains/" + domainName + "/schemas/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getSchema(
  chainName,
  domainName,
  schemaName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName;
  doRequest("get", url, null, onSuccess, onFail);
}

export function createSchema(
  chainName,
  domainName,
  schemaName,
  properties,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName;
  let request = properties;
  doRequest("post", url, request, onSuccess, onFail);
}

export function updateSchema(
  chainName,
  domainName,
  schemaName,
  properties,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName;
  let request = properties;
  doRequest("put", url, request, onSuccess, onFail);
}

export function deleteSchema(
  chainName,
  domainName,
  schemaName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function getSchemaActors(
  chainName,
  domainName,
  schemaName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/actors/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function updateSchemaActors(
  chainName,
  domainName,
  schemaName,
  actors,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/actors/";
  let request = {
    actors: actors,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function getSchemaLogs(
  chainName,
  domainName,
  schemaName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/logs/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function queryDocuments(
  chainName,
  domainName,
  schemaName,
  offset,
  limit,
  conditions,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/queries/schemas/" +
    schemaName +
    "/docs/";
  let request = {
    offset: offset,
    limit: limit,
  };
  if (conditions) {
    request.filters = conditions;
  }
  doRequest("post", url, request, onSuccess, onFail);
}

export function getDocument(
  chainName,
  domainName,
  schemaName,
  docID,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID;
  doRequest("get", url, null, onSuccess, onFail);
}

export function createDocument(
  chainName,
  domainName,
  schemaName,
  docID,
  content,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/";
  let request = {
    content: content,
  };
  if (docID && "" !== docID) {
    request.id = docID;
  }
  const onOperateSuccess = ({ id }) => {
    onSuccess(id);
  };
  doRequest("post", url, request, onOperateSuccess, onFail);
}

export function updateDocument(
  chainName,
  domainName,
  schemaName,
  docID,
  content,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID;
  let request = {
    content: content,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function removeDocument(
  chainName,
  domainName,
  schemaName,
  docID,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function updateDocumentProperty(
  chainName,
  domainName,
  schemaName,
  docID,
  propertyName,
  propertyType,
  propertyValue,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID +
    "/properties/" +
    propertyName;
  let request = {
    type: propertyType,
    value: propertyValue,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function getDocumentActors(
  chainName,
  domainName,
  schemaName,
  docID,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID + 
    "/actors/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function updateDocumentActors(
  chainName,
  domainName,
  schemaName,
  docID,
  actors,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID + 
    "/actors/";
  let request = {
    actors: actors,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function getDocumentLogs(
  chainName,
  domainName,
  schemaName,
  docID,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/schemas/" +
    schemaName +
    "/docs/" +
    docID +
    "/logs/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function changeManagerPassword(
  userID,
  oldPassword,
  newPassword,
  onSuccess,
  onFail
) {
  const url = "/managers/" + userID + "/password";
  let currentCode = CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(oldPassword));
  let newCode = CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(newPassword));
  let request = {
    hash_method: DEFAULT_HASH_METHOD,
    current_code: currentCode,
    new_code: newCode,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

//system
export function getSystemStatus(onSuccess, onFail) {
  const url = "/system/";
  doRequestWithoutAuthenticate("get", url, "", onSuccess, onFail);
}

export function initialSystem(user, password, menuList, onSuccess, onFail) {
  const url = "/system/";
  let hashed = CryptoJS.SHA256(password);
  let encodedSecret = CryptoJS.enc.Base64.stringify(hashed);
  let request = {
    user: user,
    hash_method: DEFAULT_HASH_METHOD,
    encoded_secret: encodedSecret,
    tags: menuList,
  };
  doRequestWithoutAuthenticate("post", url, request, onSuccess, onFail);
}

export function getDomainStatus(chainName, domainName, onSuccess, onFail) {
  const url = "/chains/" + chainName + "/domains/" + domainName + "/status";
  doRequest("get", url, "", onSuccess, onFail);
}

export function queryBlocks(
  chainName,
  domainName,
  from,
  to,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/domains/" + domainName + "/blocks/";
  let request = {
    from: from,
    to: to,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getBlock(chainName, domainName, blockID, onSuccess, onFail) {
  let url =
    "/chains/" + chainName + "/domains/" + domainName + "/blocks/" + blockID;
  doRequest("get", url, null, onSuccess, onFail);
}

export function queryTransactions(
  chainName,
  domainName,
  blockID,
  offset,
  limit,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/blocks/" +
    blockID +
    "/transactions/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getTransaction(
  chainName,
  domainName,
  blockID,
  id,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" +
    chainName +
    "/domains/" +
    domainName +
    "/blocks/" +
    blockID +
    "/transactions/" +
    id;
  doRequest("get", url, null, onSuccess, onFail);
}

export function queryChains(onSuccess, onFail) {
  let url = "/chains/";
  doRequest("get", url, null, onSuccess, onFail);
}

export function queryPeers(chainName, offset, limit, onSuccess, onFail) {
  let url = "/chains/" + chainName + "/peers/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function addPeer(
  chainName,
  peerName,
  peerAddress,
  validityMonth,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/peers/" + peerName;
  let today = new Date();
  let expired = new Date(
    today.getFullYear(),
    today.getMonth() + validityMonth,
    today.getDay()
  ).toISOString();
  let request = {
    remote_address: peerAddress,
    expire_date: expired,
  };
  const onOperateSuccess = () => {
    onSuccess(peerName);
  };
  doRequest("post", url, request, onOperateSuccess, onFail);
}

export function removePeer(chainName, peerName, onSuccess, onFail) {
  let url = "/chains/" + chainName + "/peers/" + peerName;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function deployPeerCertificate(chainName, peerName, onSuccess, onFail) {
  let url = "/chains/" + chainName + "/peers/" + peerName + "/certificate";
  doRequest("post", url, "", onSuccess, onFail);
}

export function updatePeerCertificate(
  chainName,
  peerName,
  validityMonth,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/peers/" + peerName + "/certificate";
  let today = new Date();
  let expired = new Date(
    today.getFullYear(),
    today.getMonth() + validityMonth,
    today.getDay()
  ).toISOString();
  let request = {
    expire_date: expired,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function queryGateways(chainName, offset, limit, onSuccess, onFail) {
  let url = "/chains/" + chainName + "/gateways/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function addGateway(
  chainName,
  gatewayName,
  gatewayAddress,
  validityMonth,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/gateways/" + gatewayName;
  let today = new Date();
  let expired = new Date(
    today.getFullYear(),
    today.getMonth() + validityMonth,
    today.getDay()
  ).toISOString();
  let request = {
    remote_address: gatewayAddress,
    expire_date: expired,
  };
  const onOperateSuccess = () => {
    onSuccess(gatewayName);
  };
  doRequest("post", url, request, onOperateSuccess, onFail);
}

export function removeGateway(chainName, gatewayName, onSuccess, onFail) {
  let url = "/chains/" + chainName + "/gateways/" + gatewayName;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function deployGatewayCertificate(
  chainName,
  gatewayName,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" + chainName + "/gateways/" + gatewayName + "/certificate";
  doRequest("post", url, "", onSuccess, onFail);
}

export function updateGatewayCertificate(
  chainName,
  gatewayName,
  validityMonth,
  onSuccess,
  onFail
) {
  let url =
    "/chains/" + chainName + "/gateways/" + gatewayName + "/certificate";
  let today = new Date();
  let expired = new Date(
    today.getFullYear(),
    today.getMonth() + validityMonth,
    today.getDay()
  ).toISOString();
  let request = {
    expire_date: expired,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function queryGatewayLogs(
  chainName,
  gatewayName,
  offset,
  limit,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/gateways/" + gatewayName + "/logs/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function queryPeerLogs(
  chainName,
  peerName,
  offset,
  limit,
  onSuccess,
  onFail
) {
  let url = "/chains/" + chainName + "/peers/" + peerName + "/logs/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function queryOperateLogs(
  offset,
  limit,
  beginTime,
  endTime,
  onSuccess,
  onFail
) {
  let url = "/queries/logs/";
  let request = {
    offset: offset,
    limit: limit,
    begin: beginTime,
    end: endTime,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function writeLog(log, onSuccess, onFail) {
  let session = getLoggedSession();
  if (null === session) {
    onFail("session expired");
    return;
  }
  let content = session.user;
  if (session.address) {
    content += "(" + session.address + ") : " + log;
  } else {
    content += ": " + log;
  }

  const url = "/logs/";
  const request = {
    content: content,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function queryCertificates(offset, limit, onSuccess, onFail) {
  let url = "/certificates/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getCertificate(index, onSuccess, onFail) {
  let url = "/certificates/" + index;
  doRequest("get", url, "", onSuccess, onFail);
}

export function queryIdentities(offset, limit, onSuccess, onFail) {
  let url = "/identities/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getIdentity(index, onSuccess, onFail) {
  let url = "/identities/" + index;
  doRequest("get", url, "", onSuccess, onFail);
}

export function queryApplicationGroups(onSuccess, onFail) {
  let url = "/application_groups/";
  doRequest("get", url, '', onSuccess, onFail);
}

export function getApplicationGroup(groupName, onSuccess, onFail) {
  let url = "/application_groups/" + groupName;
  doRequest("get", url, '', onSuccess, onFail);
}

export function createApplicationGroup(groupName, description, roles,
  onSuccess, onFail) {
  let url = "/application_groups/" + groupName;
  let request = {
    description: description,
    roles: roles,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function modifyApplicationGroup(groupName, description,
  onSuccess, onFail) {
  let url = "/application_groups/" + groupName;
  let request = {
    description: description,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function deleteApplicationGroup(groupName, onSuccess, onFail) {
  let url = "/application_groups/" + groupName;
  doRequest("delete", url, '', onSuccess, onFail);
}

export function getApplicationGroupRoles(groupName, onSuccess, onFail) {
  let url = "/application_groups/" + groupName + "/roles/";
  doRequest("get", url, '', onSuccess, onFail);
}

export function modifyApplicationGroupRoles(groupName, roles, onSuccess, onFail) {
  let url = "/application_groups/" + groupName + "/roles/";
  let request = {
    roles: roles,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function queryApplicationGroupMembers(groupName, offset, limit,
  onSuccess, onFail) {
  let url = "/application_groups/" + groupName + "/applications/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function queryApplications(offset, limit, onSuccess, onFail) {
  let url = "/queries/applications/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function createApplication(name, group, onSuccess, onFail) {
  let url = "/applications/";
  let request = {
    name: name,
    group: group,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getGroupOfApplication(appID, onSuccess, onFail) {
  let url = "/applications/" + appID + '/group';
  doRequest("get", url, '', onSuccess, onFail);
}

export function changeApplicationGroup(appID, group, onSuccess, onFail) {
  let url = "/applications/" + appID + '/group';
  let request = {
    group: group,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function deleteApplication(appID, onSuccess, onFail) {
  let url = "/applications/" + appID;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function getApplication(appID, onSuccess, onFail) {
  let url = "/applications/" + appID;
  doRequest("get", url, "", onSuccess, onFail);
}

export function queryAccessTokens(appID, offset, limit, onSuccess, onFail) {
  let url = "/queries/accesses/";
  let request = {
    application: appID,
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getAccessToken(appID, accessID, onSuccess, onFail) {
  let url = "/applications/" + appID + "/accesses/" + accessID;
  doRequest("get", url, "", onSuccess, onFail);
}

export function createAccessToken(appID, name, expire_days, onSuccess, onFail) {
  let url = "/applications/" + appID + "/accesses/";
  let request = {
    name: name,
    expire_days: expire_days,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function revokeAccessToken(appID, accessID, onSuccess, onFail) {
  let url = "/applications/" + appID + "/accesses/" + accessID;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function queryManagers(offset, limit, onSuccess, onFail) {
  let url = "/managers/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function getManager(id, onSuccess, onFail) {
  let url = "/managers/" + id;
  doRequest("get", url, "", onSuccess, onFail);
}

export function createManager(user, password, group, onSuccess, onFail) {
  const url = "/managers/" + user;
  let hashed = CryptoJS.SHA256(password);
  let encodedSecret = CryptoJS.enc.Base64.stringify(hashed);
  let request = {
    hash_method: DEFAULT_HASH_METHOD,
    encoded_secret: encodedSecret,
    group:group,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function changeManagerGroup(id, group, onSuccess, onFail) {
  let url = "/managers/" + id + "/group";
  let request = {
    group: group,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function deleteManager(id, onSuccess, onFail) {
  let url = "/managers/" + id;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function queryRoles(onSuccess, onFail) {
  let url = "/roles/";
  doRequest("get", url, "", onSuccess, onFail);
}

export function getRole(roleName, onSuccess, onFail) {
  let url = "/roles/" + roleName;
  doRequest("get", url, "", onSuccess, onFail);
}

export function addRole(
  roleName,
  description,
  actions,
  domain_privileges,
  onSuccess,
  onFail
) {
  let url = "/roles/" + roleName;
  let request = {
    description: description,
    actions: actions,
    domain_privileges: domain_privileges,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function modifyRole(
  roleName,
  description,
  actions,
  domain_privileges,
  onSuccess,
  onFail
) {
  let url = "/roles/" + roleName;
  let request = {
    description: description,
    actions: actions,
    domain_privileges: domain_privileges,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function removeRole(roleName, onSuccess, onFail) {
  let url = "/roles/" + roleName;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function queryGroups(onSuccess, onFail) {
  let url = "/groups/";
  doRequest("get", url, "", onSuccess, onFail);
}

export function getGroup(groupName, onSuccess, onFail) {
  let url = "/groups/" + groupName;
  doRequest("get", url, "", onSuccess, onFail);
}

export function createGroup(groupName, description, roles, tags, onSuccess, onFail) {
  let url = "/groups/" + groupName;
  let request = {
    description: description,
    roles: roles,
    tags: tags,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function modifyGroup(groupName, description, onSuccess, onFail) {
  let url = "/groups/" + groupName;
  let request = {
    description: description,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function deleteGroup(groupName, onSuccess, onFail) {
  let url = "/groups/" + groupName;
  doRequest("delete", url, "", onSuccess, onFail);
}

export function getGroupTags(groupName, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/tags/";
  doRequest("get", url, "", onSuccess, onFail);
}

export function modifyGroupTags(groupName, tags, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/tags/";
  let request = {
    tags: tags,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function getGroupRoles(groupName, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/roles/";
  doRequest("get", url, "", onSuccess, onFail);
}

export function modifyGroupRoles(groupName, roles, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/roles/";
  let request = {
    roles: roles,
  };
  doRequest("put", url, request, onSuccess, onFail);
}

export function queryGroupMembers(groupName, offset, limit, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/members/";
  let request = {
    offset: offset,
    limit: limit,
  };
  doRequest("post", url, request, onSuccess, onFail);
}

export function addGroupMember(groupName, memberID, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/members/" + memberID;
  doRequest("post", url, "", onSuccess, onFail);
}

export function removeGroupMember(groupName, memberID, onSuccess, onFail) {
  let url = "/groups/" + groupName + "/members/" + memberID;
  doRequest("delete", url, "", onSuccess, onFail);
}

function doRequestWithoutAuthenticate(
  method,
  path,
  requestBody,
  onSuccess,
  onFail
) {
  let url = apiRoot + path;
  let config = {
    method: method,
    url: url,
    data: requestBody,
  };
  axios(config)
    .then(({ data }) => {
      if (0 !== data.error_code) {
        if (onFail) {
          onFail(data.message);
        }
        return;
      }
      if (onSuccess) {
        onSuccess(data.data);
      }
      return;
    })
    .catch((e) => {
      if (onFail) {
        onFail(e.message);
      }
    });
}

function doRequest(method, path, requestBody, onSuccess, onFail) {
  let session = getLoggedSession();
  if (null == session) {
    onFail("session expired");
    return;
  }
  let url = apiRoot + path;
  let signaturePath = URL.parse(url).pathname;
  let timestamp = new Date().toISOString();
  let sessionID = session.id;
  let nonce = session.nonce;
  let signatureData = {
    id: sessionID,
    method: method.toUpperCase(),
    url: signaturePath,
    body: "",
    user: session.user,
    timestamp: timestamp,
    nonce: nonce,
    signature_algorithm: DEFAULT_SIGNATURE_METHOD,
  };
  if (
    "post" === method ||
    "put" === method ||
    "delete" === method ||
    "patch" === method
  ) {
    let bodyPayload;
    if (null !== requestBody && "" !== requestBody) {
      bodyPayload = JSON.stringify(requestBody);
      // console.log("body length: " + bodyPayload.length);
      // console.log("body payload: " + bodyPayload);
    } else {
      bodyPayload = "";
    }
    signatureData.body = CryptoJS.enc.Base64.stringify(
      CryptoJS.SHA256(bodyPayload)
    );
  }
  let payload = JSON.stringify(signatureData);
  // console.log(payload)
  let signature = CryptoJS.HmacSHA256(payload, nonce);
  let encodedSignature = CryptoJS.enc.Base64.stringify(signature);

  let config = {
    method: method,
    url: url,
    headers: {
      [HEADER_NAME_TIMESTAMP]: timestamp,
      [HEADER_NAME_SIGNATURE]: encodedSignature,
      [HEADER_NAME_SIGNATURE_ALGORITHN]: DEFAULT_SIGNATURE_METHOD,
      [HEADER_NAME_SESSION]: sessionID,
    },
    data: requestBody,
  };
  axios(config)
    .then(({ data }) => {
      if (0 !== data.error_code) {
        if (onFail) {
          onFail(data.message);
        }
        return;
      }
      if (onSuccess) {
        onSuccess(data.data);
      }
      return;
    })
    .catch((e) => {
      if (onFail) {
        onFail(e.message);
      }
    });
}
