import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { getAllMenus, modifyGroupTags, getGroupTags, writeLog } from "backend_api.js";

const i18n = {
  en: {
    title: "Modify Group Menu - ",
    back: "Back",
    name: "Group Name",
    submitButton: "Submit",
    tags: "Menus",
  },
  cn: {
    title: "修改用户组菜单 - ",
    back: "返回",
    name: "组名",
    submitButton: "提交变更",
    tags: "功能菜单",
  },
};

export default function ModifyGroup(props) {
  const { lang, groupName, onList } = props;
  const texts = i18n[lang];
  const systemMenus = getAllMenus(lang);
  const defaultValues = {
    tags: {},
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleMenuChanged = (menuItem) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let checked = e.target.checked;
      let tags = request.tags;
      tags[menuItem] = checked;
      setRequest((previous) => ({
        ...previous,
        tags: tags,
      }));
    };
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }

    const onOperateSuccess = () => {
      writeLog("modified tags of group " + groupName);
      toast.success("tags of group " + groupName + " modified");
      onList();
    };

    let tags = [];
    systemMenus.forEach(({ value }) => {
      if (
        Object.prototype.hasOwnProperty.call(request.tags, value) &&
        request.tags[value]
      ) {
        tags.push(value);
      }
    });

    //format privileges

    modifyGroupTags(groupName, tags, onOperateSuccess, onFail);
  };

  React.useEffect(() => {
    setMounted(true);
    if (!groupName) {
      return;
    }
    const onGetSuccess = (data) => {
      if (!mounted) {
        return;
      }
      let initialTags = {};
      systemMenus.forEach(({ value }) => {
        initialTags[value] = false;
      });
      if (data) {
        data.forEach((tagValue) => {
          initialTags[tagValue] = true;
        });
      }

      let request = {
        tags: initialTags,
      };
      setRequest(request);
      setInitialized(true);
    };

    getGroupTags(groupName, onGetSuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, groupName, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    let menuComponent = (
      <FormControl
        sx={{
          m: 3,
          display: "flex",
        }}
        component="fieldset"
        variant="standard"
        key="menu"
      >
        <FormGroup row>
          <Grid container>
            {systemMenus.map(({ value, label }) => (
              <Grid key={value} xs={6} sm={4} md={3} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleMenuChanged(value)}
                      checked={request.tags[value]}
                    />
                  }
                  label={label}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    );
    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Box>
          <Typography>{texts.tags}</Typography>
          {menuComponent}
        </Box>
      </Stack>
    );

    title = texts.title + groupName;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

ModifyGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
