import React from "react";
import PropTypes from "prop-types";
import ActorList from "views/Shared/ActorList";
import { DEFAULT_CHAIN_NAME, DEFAULT_SYSTEM_NAME } from "project";
import { 
    PrivilegeRequirements, 
    DomainActionUpdateDocument, 
    DomainActionUpdateSystemDocument,
    DomainActionDeployContract,
    DomainActionDeploySystemContract,
 } from "privileges";
import { getContractActors, updateContractActors, isSystemResource } from "backend_api";

const defaultChainName = DEFAULT_CHAIN_NAME;
const defaultDomainName = DEFAULT_SYSTEM_NAME;

export default function ContractActorList(props) {
    const contractName = props.match.params.contract;
    const { lang, history } = props;
    let title;
    if ('cn' === lang){
        title = `合约权限： ${contractName}`;
    }else{
        title = `Contract Privileges: ${contractName}`;
    }
    let requiredPrivileges;
    if (isSystemResource(contractName)){
        requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([
            DomainActionUpdateSystemDocument,
            DomainActionDeploySystemContract]);
    }else{
        requiredPrivileges = new PrivilegeRequirements().defaultDomainActions([
            DomainActionUpdateDocument,
            DomainActionDeployContract]);
    }

    const onUpdateSuccess = () =>{
        const listURL = "/admin/contracts/";
        window.location.assign(listURL);
    }
    const actorLoader = (onLoadSuccess, onLoadFail) => {
        getContractActors(
            defaultChainName, 
            defaultDomainName, 
            contractName,
            onLoadSuccess, 
            onLoadFail,
        );
    };
    const actorUpdater = (actors, onUpdateFail) => {
        updateContractActors(
            defaultChainName, 
            defaultDomainName, 
            contractName,
            actors,
            onUpdateSuccess, 
            onUpdateFail,
        );
    };
    return (
        <ActorList
            lang={lang}
            title={title}
            history={history}
            loader={actorLoader}
            updater={actorUpdater}
            updatePrivileges={requiredPrivileges}
            />
    )
}

ContractActorList.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object,
    lang: PropTypes.string.isRequired,
};