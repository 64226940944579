import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// dashboard components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/ObjectTable.js";
import { InputComponent } from "components/CustomInput/InputList";
import { getAllMenus, createGroup, queryRoles, writeLog } from "backend_api.js";

const i18n = {
  en: {
    title: "Create Group",
    back: "Back",
    name: "Group Name",
    description: "Description",
    roles: "Roles",
    roleName: "Role Name",
    tags: "Menus",
    submitButton: "Submit",
  },
  cn: {
    title: "创建用户组",
    back: "返回",
    name: "用户组名",
    description: "描述",
    roles: "关联角色",
    roleName: "角色名",
    tags: "功能菜单",
    submitButton: "提交变更",
  },
};

export default function CreateGroup(props) {
  const { lang, onList } = props;
  const texts = i18n[lang];
  const systemMenus = getAllMenus(lang);
  const defaultValues = {
    name: "",
    description: "",
    roles: {},
    tags: {},
    availables: [],
  };
  const [request, setRequest] = React.useState(defaultValues);
  const [mounted, setMounted] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const showErrorMessage = React.useCallback(
    (msg) => {
      if (!mounted) {
        return;
      }
      toast.error(msg);
    },
    [mounted]
  );

  const onFail = React.useCallback(
    (msg) => {
      showErrorMessage(msg);
    },
    [showErrorMessage]
  );

  const handleRoleChanged = (roleName) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let checked = e.target.checked;
      let roles = request.roles;
      roles[roleName] = checked;
      setRequest((previous) => ({
        ...previous,
        roles: roles,
      }));
    };
  };

  const handleTextChanged = (propertyName) => {
    return (e) => {
      let value = e.target.value;
      setRequest((previous) => ({
        ...previous,
        [propertyName]: value,
      }));
    };
  };

  const handleMenuChanged = (menuItem) => {
    return (e) => {
      if (!mounted) {
        return;
      }
      let checked = e.target.checked;
      let tags = request.tags;
      tags[menuItem] = checked;
      setRequest((previous) => ({
        ...previous,
        tags: tags,
      }));
    };
  };

  const handleConfirm = () => {
    if (!mounted) {
      return;
    }
    let groupName;
    const onOperateSuccess = () => {
      writeLog("created group " + groupName);
      toast.success("group " + groupName + " created");
      onList();
    };

    let wordOnly = new RegExp("\\W");
    if (!request.name) {
      onFail("group name required");
      return;
    } else if (wordOnly.test(request.name)) {
      onFail("only words allow in group name: " + request.name);
      return;
    }
    groupName = request.name;
    //format roles
    let roles = [];
    for (const roleName in request.roles) {
      if (request.roles[roleName]) {
        roles.push(roleName);
      }
    }

    if (0 === roles.length) {
      showErrorMessage("Choose at least one role");
      return;
    }

    let tags = [];
    systemMenus.forEach(({ value }) => {
      if (
        Object.prototype.hasOwnProperty.call(request.tags, value) &&
        request.tags[value]
      ) {
        tags.push(value);
      }
    });

    createGroup(
      request.name,
      request.description,
      roles,
      tags,
      onOperateSuccess,
      onFail
    );
  };

  React.useEffect(() => {
    setMounted(true);

    const onQuerySuccess = ({ roles }) => {
      if (!mounted) {
        return;
      }
      let allRoles = roles;
      let roleValues = {};
      if (allRoles) {
        allRoles.forEach(({ name }) => {
          roleValues[name] = false;
        });
      }
      let initialTags = {};
      systemMenus.forEach(({ value }) => {
        initialTags[value] = false;
      });
      let request = {
        name: "",
        description: "",
        roles: roleValues,
        tags: initialTags,
        availables: allRoles,
      };
      setRequest(request);
      setInitialized(true);
    };

    queryRoles(onQuerySuccess, onFail);

    return () => {
      setMounted(false);
      return;
    };
  }, [mounted, onFail]);

  let content, title;
  let buttons = [];
  if (!initialized) {
    content = <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
    title = "";
  } else {
    const nameInput = (
      <InputComponent
        type="text"
        label={texts.name}
        value={request.name}
        required={true}
        xs={8}
        md={6}
        lg={4}
        xl={2}
        onChange={handleTextChanged("name")}
      />
    );
    const descriptionInput = (
      <InputComponent
        type="textarea"
        label={texts.description}
        value={request.description}
        onChange={handleTextChanged("description")}
        xs={8}
        md={6}
        lg={4}
      />
    );
    let menuComponent = (
      <FormControl
        sx={{
          m: 3,
          display: "flex",
        }}
        component="fieldset"
        variant="standard"
        key="menu"
      >
        <FormGroup row>
          <Grid container>
            {systemMenus.map(({ value, label }) => (
              <Grid key={value} xs={6} sm={4} md={3} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleMenuChanged(value)}
                      checked={request.tags[value]}
                    />
                  }
                  label={label}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
    );

    const rows = request.availables.map(({ name, description }) => [
      <GridItem key={name} xs={4} sm={3} md={2}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleRoleChanged(name)}
              checked={request.roles[name]}
            />
          }
          label={name}
        />
      </GridItem>,
      <GridItem key={name} xs={8} sm={9} md={10}>
        {description}
      </GridItem>,
    ]);

    content = (
      <Stack divider={<Divider />} spacing={2}>
        <Box>{nameInput}</Box>
        <Box>{descriptionInput}</Box>
        <Box>
          <Typography>{texts.tags}</Typography>
          {menuComponent}
        </Box>
        <Box>
          <Typography>{texts.roles}</Typography>
          <Table
            color="primary"
            headers={[texts.roleName, texts.description]}
            rows={rows}
          />
        </Box>
      </Stack>
    );

    title = texts.title;
    buttons = [
      <Button key="back" size="sm" color="info" round onClick={onList}>
        <NavigateBeforeIcon />
        {texts.back}
      </Button>,
    ];
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex">
          {buttons.map((button, key) => (
            <Box key={key} pl={2} pr={2}>
              {button}
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">{title}</CardHeader>
          <CardBody>{content}</CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Box display="block" displayPrint="none">
          <Button onClick={handleConfirm} color="info" key="create">
            {texts.submitButton}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

CreateGroup.propTypes = {
  onList: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};
