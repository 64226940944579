import React from "react";
import { Redirect } from "react-router-dom";
import { PROJECT_NAME } from "project.js";
const SessionTagName = PROJECT_NAME + "-session-data";
const LanguageTagName = PROJECT_NAME + "-language-data";

export function saveLoggedSession(session) {
  localStorage.setItem(SessionTagName, JSON.stringify(session));
}

export function getLoggedSession() {
  let sessionData = localStorage.getItem(SessionTagName);
  if (!sessionData || 0 === sessionData.length) {
    //no session available
    return null;
  }
  let s = JSON.parse(sessionData);
  if (!s || !s.id) {
    return null;
  }
  return s;
}

export function isVisible(privileges) {
  if (hasPrivileges(privileges)) {
    return "visible";
  } else {
    return "hidden";
  }
}

export function hasPrivileges(requirements) {
  let session = getLoggedSession();
  if (!session) {
    return false;
  }
  if (!session.privileges) {
    return false;
  }
  if (!requirements) {
    return true;
  }
  return requirements.meetRequirements(session.privileges);
}

export function logoutSession() {
  localStorage.setItem(SessionTagName, null);
}

export function propertyTypeName(lang, valueType){
  const i18n = {
    en: {
      tagTypeString: "String",
      tagTypeInteger: "Integer",
      tagTypeFloat: "Float",
      tagTypeBool: "Boolean",
      tagTypeCurrency: "Currency",
      tagTypeCollection: "Collection",
      tagTypeDocument: "Document",
    },
    cn: {
      tagTypeString: "字符串",
      tagTypeInteger: "整数",
      tagTypeFloat: "浮点数",
      tagTypeBool: "布尔类型",
      tagTypeCurrency: "货币",
      tagTypeCollection: "队列",
      tagTypeDocument: "嵌套文档",
    },
  };
  const texts = i18n[lang];
  let typeName;
  switch (valueType) {
    case "int":
      typeName = texts.tagTypeInteger;
      break;
    case "bool":
      typeName = texts.tagTypeBool;
      break;
    case "float":
      typeName = texts.tagTypeFloat;
      break;
    case "currency":
      typeName = texts.tagTypeCurrency;
      break;
    case "document":
      typeName = texts.tagTypeDocument;
      break;
    case "collection":
      typeName = texts.tagTypeCollection;
      break;
    default:
      typeName = texts.tagTypeString;
  }
  return typeName;
}

export function convertToPropertyValue(input, valueType){
  let value;
  switch (valueType) {
    case "bool":
    value = input.toLowerCase().trim();
    if (value === "true"|| value === "yes" || value === "1"){
      return true;
    }else if (value === "false"|| value === "no" || value === "0"){
      return false;
    }else{
      return null;
    }
    case "int":
      value = Number.parseInt(input, 10);
      if (Number.isNaN(value)) {
        return null;
      }
      return value;
    case "float":
    case "currency":
      value = Number.parseFloat(input);
      if (Number.isNaN(value)) {
        return null;
      }
      return value;
    default:
      return input;
  }
}

//KiB based
export function bandwidthToString(bandwidthInKiB) {
  const MiB = 1 << 10;
  const GiB = 1 << 20;
  if (bandwidthInKiB >= GiB) {
    return (bandwidthInKiB / GiB).toString() + " GiB/s";
  } else if (bandwidthInKiB >= MiB) {
    return (bandwidthInKiB / MiB).toString() + " MiB/s";
  } else {
    return bandwidthInKiB.toString() + " KiB/s";
  }
}

//MiB based
export function quotaToString(quotaInMiB) {
  const GiB = 1 << 10;
  if (quotaInMiB >= GiB) {
    return (quotaInMiB / GiB).toString() + " GiB";
  } else {
    return quotaInMiB.toString() + " MiB";
  }
}

export function usageToString(usedInBytes, quotaInBytes) {
  let used = usedInBytes;
  let quota = quotaInBytes;
  const divider = 1 << 10;
  if (quota < divider) {
    return usedInBytes.toFixed(2) + " / " + quotaInBytes.toString() + " Bytes";
  }
  used /= divider;
  quota /= divider;
  if (quota < divider) {
    return used.toFixed(2) + " / " + truncateToRadix(quota, 2) + " KiB";
  }
  used /= divider;
  quota /= divider;
  if (quota < divider) {
    return used.toFixed(2) + " / " + truncateToRadix(quota, 2) + " MiB";
  }
  used /= divider;
  quota /= divider;
  if (quota < divider) {
    return used.toFixed(2) + " / " + truncateToRadix(quota, 2) + " GiB";
  }
  used /= divider;
  quota /= divider;
  return used.toFixed(2) + " / " + truncateToRadix(quota, 2) + " TiB";
}

export function reduceUsage(usedInBytes, quotaInBytes) {
  let used = usedInBytes;
  let quota = quotaInBytes;
  const divider = 1 << 10;
  if (quota < divider) {
    return {
      used: used.toFixed(2),
      quota: truncateToRadix(quota, 2),
      unit: "Bytes",
    };
  }
  used /= divider;
  quota /= divider;
  if (quota < divider) {
    return {
      used: used.toFixed(2),
      quota: truncateToRadix(quota, 2),
      unit: "KiB",
    };
  }
  used /= divider;
  quota /= divider;
  if (quota < divider) {
    return {
      used: used.toFixed(2),
      quota: truncateToRadix(quota, 2),
      unit: "MiB",
    };
  }
  used /= divider;
  quota /= divider;
  if (quota < divider) {
    return {
      used: used.toFixed(2),
      quota: truncateToRadix(quota, 2),
      unit: "GiB",
    };
  }
  used /= divider;
  quota /= divider;
  return {
    used: used.toFixed(2),
    quota: truncateToRadix(quota, 2),
    unit: "TiB",
  };
}

export function splitDuration(ms) {
  let d = ms / 1000;
  let result = {};
  const day = 24 * 60 * 60;
  if (d >= day) {
    result.day = Math.floor(d / day);
    d -= result.day * day;
  } else {
    result.day = 0;
  }
  const hour = 60 * 60;
  if (d >= hour) {
    result.hour = Math.floor(d / hour);
    d -= result.hour * hour;
  } else {
    result.hour = 0;
  }
  const minute = 60;
  if (d >= minute) {
    result.minute = Math.floor(d / minute);
    d -= result.minute * minute;
  } else {
    result.minute = 0;
  }
  result.second = Math.floor(d);
  return result;
}

export function redirectToLogin() {
  // return  <Redirect to={'/login/?previous=' + encodeURIComponent(window.location.pathname)} noThrow/>;
  return (
    <Redirect
      to={
        "/login/?previous=" +
        encodeURIComponent(window.location.pathname + window.location.search)
      }
    />
  );
}

export function getLanguage() {
  const defaultLang = "cn";
  let langData = localStorage.getItem(LanguageTagName);
  if (!langData || 0 === langData.length) {
    return defaultLang;
  }
  let lang = JSON.parse(langData);
  if (!lang.lang) {
    return defaultLang;
  }
  return lang.lang;
}

export function changeLanguage(lang) {
  let configData = localStorage.getItem(LanguageTagName);
  if (!configData || 0 === configData.length) {
    return false;
  }
  let config = JSON.parse(configData);
  if (!config.lang) {
    return false;
  }
  if (config.lang === lang) {
    return true;
  }
  config.lang = lang;
  localStorage.setItem(LanguageTagName, JSON.stringify(config));
  return true;
}

export function bytesToString(bytes) {
  const KiB = 1 << 10;
  const MiB = 1 << 20;
  const GiB = 1 << 30;
  const toString = (size, radix, unit) => {
    if (0 === size % radix) {
      return (size / radix).toString() + " " + unit;
    } else {
      return (size / radix).toFixed(2) + " " + unit;
    }
  };
  if (bytes >= GiB) {
    return toString(bytes, GiB, "GB");
  } else if (bytes >= MiB) {
    return toString(bytes, MiB, "MB");
  } else if (bytes >= KiB) {
    return toString(bytes, KiB, "KB");
  } else {
    return bytes.toString() + " Bytes";
  }
}

export function bpsToString(bytes) {
  const KiB = 1 << 7;
  const MiB = 1 << 17;
  const GiB = 1 << 27;
  const toString = (size, radix, unit) => {
    if (0 === size % radix) {
      return (size / radix).toString() + " " + unit;
    } else {
      return (size / radix).toFixed(2) + " " + unit;
    }
  };
  if (bytes >= GiB) {
    return toString(bytes, GiB, "Gb/s");
  } else if (bytes >= MiB) {
    return toString(bytes, MiB, "Mb/s");
  } else if (bytes >= KiB) {
    return toString(bytes, KiB, "Kb/s");
  } else {
    return bytes.toString() + " Bits/s";
  }
}

export function truncateToRadix(number, radix) {
  const base = Math.pow(10, radix);
  return Math.round(number * base) / base;
}
