import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { getDomainStatus, queryChains } from "backend_api";

const i18n = {
  en: {
    message:
      "The default domain is uninitialized, please check the system configuration.",
    error: "Error: ",
  },
  cn: {
    message: "系统数据域尚未初始化，请先检查系统与节点配置",
    error: "错误: ",
  },
};

export default function MustInitialized(properties) {
  const DEFAULT_DOMAIN = "system";
  const domainName = DEFAULT_DOMAIN;
  const texts = i18n[properties.lang];
  const [initialized, setInitialized] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onFail = (msg) => {
    setError(msg);
    setInitialized(true);
  };

  React.useEffect(() => {
    let chainName;
    function onGetDomainSuccess(data) {
      const { world_version } = data;
      if (0 === world_version) {
        onFail("domain uninitialized");
        return;
      }
      setReady(true);
      setInitialized(true);
    }
    function onQueryChainsSuccess(chains) {
      if (!chains || 0 === chains.length) {
        onFail("no chains available");
        return;
      }
      let defaultChain = chains[0];
      if (0 === defaultChain.domains) {
        onFail("no domain available in chain " + defaultChain.name);
        return;
      }
      chainName = defaultChain.name;
      getDomainStatus(chainName, domainName, onGetDomainSuccess, onFail);
    }
    queryChains(onQueryChainsSuccess, onFail);
  }, []);

  if (!initialized) {
    return <Skeleton variant="rectangular" style={{ height: "10rem" }} />;
  } else if (!ready) {
    let messages = [];
    if (error) {
      messages.push(
        <Typography variant="body1">{texts.error + error}</Typography>
      );
    }
    messages.push(
      <Link href={"/admin/chains/"}>
        <Typography variant="body2">{texts.message}</Typography>
      </Link>
    );
    return (
      <Container maxWidth="xl">
        <Paper sx={{ m: 2 }}>
          <Stack>
            {messages.map((m, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 2,
                  p: 1,
                }}
                key={index}
              >
                {m}
              </Box>
            ))}
          </Stack>
        </Paper>
      </Container>
    );
  }
  //ready
  return properties.component;
}

MustInitialized.prototype = {
  lang: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};
